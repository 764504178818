/**
 * Takes care of validation of the forms
 * See documentation: http://bootstrapvalidator.com/examples/showing-message-custom-area/
 *
 * This class is responsible for making it as easy as possible to assemble validators.
 */
import $ from "jquery";
import "bootstrapvalidator";

function ValidatorBuilder() {

  this.requireUnicode = function (result) {
    result.regexp = {regexp: /^[\u0000-\u0fff]+$/, message: message("ERROR_ONLY_LETTERS_ALLOWED")};
  };

  var _this = this;
  var factories = [this.requireUnicode];
  var messages = window.shopConfig.jsMessages || {};

  var message = function (key) {
    return messages[key] || "###" + key + "###";
  };

  this.requireNotEmpty = function () {
    factories.push(function (result) {
      result.notEmpty = {message: message("ERROR_FIELD_IS_MANDATORY")};
    });
    return _this;
  };

  this.requireMaxLength = function (maxLength) {
    factories.push(function (result) {
      result.stringLength = {max: maxLength, message: message("ERROR_FIELD_IS_TOO_LONG")};
    });
    return _this;
  };

  this.requireNoMinusStartInput = function () {
    return this.requireRegexp(/[^-*]+$/i, "ERROR_CANNOT_START_WITH_MINUS");
  };

  this.requireRegexp = function (regexp, messageKey) {
    factories.push(function (result) {
      result.regexp = {regexp: regexp, message: message(messageKey)};
    });
    return _this;
  };

  this.requireOnlyLetters = function () {
    return this.requireRegexp(/[^0-9]+$/i, "ERROR_ONLY_LETTERS_ALLOWED");
  };

  this.requireValidEmailAddress = function () {
    factories.push(function (result) {
      result.emailAddress = {message: message("ERROR_EMAIL_INVALID")};
    });
    return _this;
  };

  this.requireIdenticalWith = function (fieldName, messageKey) {
    factories.push(function (result) {
      result.identical = {field: fieldName, message: message(messageKey)};
    });
    return _this;
  };

  this.requireZipCode = function () {
    var msgtext = message("ERROR_INVALID_ZIP_CODE");
    var country = window.shopConfig.country || "";

    factories.push(function (result) {
      // some languages are not supported by the native zipCode Validator We add them here

      if (country === "de" || country === "es" || country === "fr") {
        result.regexp = {regexp: /^[0-9]{5}$/i, message: msgtext};
      } else if (country === "at" || country === "ch" || country === "be" || country === "hu" || country === "dk") {
        result.regexp = {regexp: /^[0-9]{4}$/i, message: msgtext};
      } else if (country === "pl") {
        result.regexp = {regexp: /^[0-9]{2}-[0-9]{3}$/i, message: msgtext};
      } else if (country === "pt") {
        result.regexp = {regexp: /^[0-9]{4}\-?[0-9]{3}$/, message: msgtext};
      } else if (country === "se") {
        result.regexp = {regexp: /^[0-9]{3} ?[0-9]{2,}$/, message: msgtext};
      } else if (country === "uk") {
        result.regexp = {regexp: /^[0-9a-zA-Z ]{6,8}$/, message: msgtext};
      } else if (country === "cz" || country === "sk") {
        result.regexp = {regexp: /(^[0-9]{3}\s{1}[0-9]{2}$)|(^[0-9]{5}$)/, message: msgtext};
      } else {
        result.zipCode = {country: country, message: msgtext};
      }
    });
    return _this;
  };

  this.requireRegexpForCountry = function (forCountry, regEx, messageKey) {
    var msgtext = message(messageKey);
    var country = window.shopConfig.country || "";
    factories.push(function (result) {
      if (country === forCountry) {
        result.regexp = {regexp: regEx, message: msgtext};
      }
    });
    return _this;
  };

  this.requireNotEmptyForCountries = function (forCountries) {
    var country = window.shopConfig.country || "";
    factories.push(function (result) {
      if (forCountries.indexOf(country) !== -1) {
        result.notEmpty = {message: message("ERROR_FIELD_IS_MANDATORY")};
      }
    });
    return _this;
  };

  this.requireIsChecked = function (messageKey) {
    factories.push(function (result) {
      result.notEmpty = {message: message(messageKey)};
    });
    return _this;
  }

  this.requireNotEmptyForPrivateCustomer = function () {
    factories.push(function (result) {

      let $privateCustomerRegisterRadioBtn = $("#show-company-data-false");
      if (window.shopConfig.isPrivateCustomer || ($privateCustomerRegisterRadioBtn.length > -1 && $privateCustomerRegisterRadioBtn.is(':checked'))) {
        result.notEmpty = {message: message("ERROR_FIELD_IS_MANDATORY")};
      }
    });
    return _this;
  }

  this.build = function () {
    var result = {};
    for (var i = 0; i < factories.length; i++) {
      factories[i](result);
    }
    return result;
  };
}

/**
 * This class defines a few predefined validators
 */
function FieldValidators() {

  var builders = {};
  var EMPTY_BUILDER = new ValidatorBuilder();

  builders.maschinenringNumber = new ValidatorBuilder().requireNotEmpty();

  builders.maschinenringMemberNumber = new ValidatorBuilder().requireNotEmpty();

  builders.companyName = new ValidatorBuilder()
      .requireMaxLength(37)
      .requireNoMinusStartInput()
      .requireNotEmpty();

  builders.companyName2 = new ValidatorBuilder()
      .requireMaxLength(37)
      .requireNotEmptyForPrivateCustomer()
      .requireNoMinusStartInput();

  builders.shippingCompanyName = builders.companyName;

  builders.addressAddition = new ValidatorBuilder().requireMaxLength(37);

  builders.companyAdditions = builders.addressAddition; // Create mapping immediately. Sometimes the missing data has a different name.
  builders.shippingCompanyAdditions = builders.companyName2;

  let requireMaxLength30 = new ValidatorBuilder().requireMaxLength(30);
  builders.shippingAdditionsNumber = requireMaxLength30;
  builders.shippingAdditionsTimeFrom = requireMaxLength30;
  builders.shippingAdditionsTimeTo = requireMaxLength30;
  builders.shippingAdditionsNote = requireMaxLength30;

  builders.zipCode = new ValidatorBuilder()
      .requireMaxLength(15)
      .requireNotEmpty()
      .requireZipCode();

  builders.companyZip = builders.zipCode;
  builders.shippingCompanyZip = builders.zipCode;

  builders.city = new ValidatorBuilder()
      .requireMaxLength(37)
      .requireOnlyLetters()
      .requireNotEmpty();

  builders.companyCity = builders.city;
  builders.shippingCompanyCity = builders.city;

  builders.companyState = new ValidatorBuilder()
      .requireNotEmpty()
      .requireRegexpForCountry("it", /^[a-zA-Z]{2}$/i, "ERROR_INVALID_STATE");

  builders.streetAndHouseNo = new ValidatorBuilder()
      .requireNotEmpty()
      .requireMaxLength(37);

  builders.street = new ValidatorBuilder()
      .requireNotEmpty()
      .requireMaxLength(31);

  builders.companyStreet = builders.street;
  builders.shippingCompanyStreet = builders.street;

  builders.houseNo = new ValidatorBuilder()
      .requireMaxLength(5)
      .requireNotEmptyForCountries("hu");

  builders.companyHouseNo = builders.houseNo;
  builders.shippingCompanyHouseNo = builders.houseNo;

  builders.firstName = new ValidatorBuilder()
      .requireNotEmpty()
      .requireMaxLength(37);

  builders.contactFirstname = builders.firstName;

  builders.title = new ValidatorBuilder().requireMaxLength(37);

  builders.contactTitle = builders.title;

  builders.lastName = new ValidatorBuilder()
      .requireNotEmpty()
      .requireMaxLength(37);

  builders.contactName = new ValidatorBuilder()
      .requireNotEmpty()
      .requireMaxLength(37);

  builders.salutation = new ValidatorBuilder().requireNotEmpty();

  builders.personSalutation = builders.salutation;

  builders.phone = new ValidatorBuilder()
      .requireNotEmpty()
      .requireMaxLength(30)
      .requireRegexp(/^[0-9- /+]+$/i, "ERROR_INVALID_PHONE_NUMBER");

  builders.contactPhone = builders.phone;

  builders.email = new ValidatorBuilder()
      .requireNotEmpty()
      .requireValidEmailAddress();

  builders.userName = new ValidatorBuilder().requireNotEmpty();

  builders.newEmailAddress = builders.email;
  builders.emailAddress = builders.email;
  builders.userNamePasswordForgotten = builders.userName;

  builders.passwordVerify = new ValidatorBuilder()
    .requireNotEmpty()
    .requireIdenticalWith("password", "ERROR_PW_NOT_MATCH");

  builders.password = new ValidatorBuilder()
    .requireNotEmpty()
    .requireRegexp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, "ERROR_PW_ALL");

  builders.content = new ValidatorBuilder().requireMaxLength(1000).requireNotEmpty();
  builders.articleDescription = new ValidatorBuilder().requireMaxLength(37);
  builders.articleCount = new ValidatorBuilder().requireMaxLength(5).requireRegexp("\\d+", "ERROR_FIELD_IS_MANDATORY");

  builders.loginName = new ValidatorBuilder().requireNotEmpty();

  builders.loginPassword = new ValidatorBuilder().requireNotEmpty();

  builders.passwordCanBeEmpty = new ValidatorBuilder()
    .requireNotEmpty()
    .requireRegexp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, "ERROR_PW_ALL");

  builders.vatField = new ValidatorBuilder()
      .requireNotEmptyForCountries(["pl", "be", "es", "it", "pt", "cz", "dk", "sk"])
      .requireMaxLength(20)
      .requireRegexpForCountry("be", /^[0-9a-zA-Z- ,.]{1,14}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("es", /^[0-9a-zA-Z- ,.]{1,12}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("fr", /^[0-9]{14}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("it", /^[0-9]{11}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("pt", /^\s*(\d\s*){9,}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("pl", /^[0-9a-zA-Z- ,.]{1,15}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("cz", /^[0-9]{8}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("sk", /^[0-9]{8}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("dk", /^.{8,10}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("hu", /^(([0-9]{8}\-[0-9]{1}\-[0-9]{2})|([0-9]{11}))$/i, "ERROR_INVALID_VAT_NUMBER");

  builders.vatFieldSecond = new ValidatorBuilder()
      .requireNotEmptyForCountries(["cz"])
      .requireMaxLength(30)
      .requireRegexpForCountry("cz", /^CZ[0-9]{8,10}$/i, "ERROR_INVALID_VAT_NUMBER")
      .requireRegexpForCountry("fr", /^[0-9]{1,30}$/i, "ERROR_INVALID_VAT_NUMBER");

  builders.eInvoicing = new ValidatorBuilder().requireNotEmpty();

  builders.privacyAccepted = new ValidatorBuilder().requireIsChecked("ERROR_PRIVACY_NOT_ACCEPTED");

  builders.captcha = new ValidatorBuilder().requireNotEmpty();

  builders.privacyTermsAccepted = new ValidatorBuilder().requireIsChecked("ERROR_FIELD_IS_MANDATORY");

  this.getValidatorForName = function (name, prefix, switchOff, canBeEmpty) {
    if (canBeEmpty !== null) {
      name = name + 'CanBeEmpty';
    }

    if (prefix !== null) {
      name = name.replace(prefix + '.', '');
    }

    if (switchOff !== null && switchOff === 'true') {
      return EMPTY_BUILDER.build();
    } else if (switchOff === 'false') {
      name += 'Valid'
    }

    var builder = builders[name] || EMPTY_BUILDER;
    return builder.build();
  };
}

export default function Validator() {
}

function onValidationSuccess(e) {
  // $(e.target)  --> The field element
  // data.bv     --> The BootstrapValidator instance
  // data.field   --> The field name
  // data.element --> The field element
  var $field = $(e.target);
  var $fieldParent = $field.parent();
  $fieldParent.find("span").remove();
  var switchOff = $field.attr('data-novalidate') || '';

  // green tick only if:
  // (1) not empty,
  // (2) no select in which no green tick fits,
  // (3) no readonly field,
  // (4) validation must not be disabled
  if (($field.prop('tagName') !== "SELECT")
      && !$field.is('[readonly]')
      && ($field.val() !== "")
      && switchOff !== 'true') {
    $fieldParent.removeClass("has-error");
    $fieldParent.addClass("is-correct");
  } else {
    $fieldParent.removeClass("is-correct");
  }
}

function onValidationError(e, data) {
  // $(e.target)  --> The field element
  // data.bv     --> The BootstrapValidator instance
  // data.field   --> The field name
  // data.element --> The field element
  data.bv.disableSubmitButtons(false);

  const $fieldParent = $(e.target).parent();
  $fieldParent.removeClass("is-correct");
  $fieldParent.find("span").remove(); // Remove the field messages if they're already available

  for (const msg of data.bv.getMessages(data.element)) {
    $('<span></span>').html(msg).appendTo($fieldParent);
  }

  // Hide the default message
  // $field.data('bv.messages') returns the default element containing the messages
  data.element.data('bv.messages').find(".help-block").hide();
}

function createFieldValidationConfigForForm($form) {
  var result = {};
  var fieldValidators = new FieldValidators();
  $form.find("input,select,textarea,password").each(function () {
    var $input = $(this);
    var fieldName = $input.attr('name');
    var prefix = $input.attr('data-validation-prefix') || null;
    var switchOff = $input.attr('data-novalidate') || null;
    var canBeEmpty = $input.attr('data-can-be-empty') || null;

    result[fieldName] = {
      validators: fieldValidators.getValidatorForName(fieldName, prefix, switchOff, canBeEmpty),
      trigger: 'blur'
    };
  });
  return result;
}

var disableSubmitButtons = function (e, data) {
  data.bv.disableSubmitButtons(false);
};

Validator.prototype.init = function (formSelector) {
  var $form = $(formSelector);
  if ($form.length === 0) {
    return;
  }

  $form.bootstrapValidator({
    feedbackIcons: {
      valid: null,
      invalid: null,
      validating: null
    },
    fields: createFieldValidationConfigForForm($form)
  });

  $form.off('success.field.bv', onValidationSuccess).on('success.field.bv', onValidationSuccess);
  $form.off('error.field.bv', onValidationError).on('error.field.bv', onValidationError);
  $form.off('status.field.bv', disableSubmitButtons).on('status.field.bv', disableSubmitButtons);
};

const registerPasswordVerify = function () {
  $('.js-register-password-field, .js-password-verify').on('change', function (e) {
    $('[data-tracking-option="guest"]').each(function (i, element) {
      var pass1 = $('.js-register-password-field').val();
      var pass2 = $('.js-password-verify').val();
      if (pass1 && pass2 && pass1.length >= 6 && pass2.length >= 6) {
        $(element).attr('data-tracking-option', 'new_user');
      }
    });
  });
};

Validator.prototype.initOnDomReady = function (formSelector) {
  var _this = this;
  $(document).ready(function () {
    _this.init(formSelector);
    _this.initClientsideAdditions(formSelector);
  });
  registerPasswordVerify();
};

Validator.prototype.initOnAjaxComplete = function (formSelector) {
  var _this = this;
  $(document).ajaxComplete(function () {
    _this.init(formSelector);
    _this.initClientsideAdditions(formSelector);
    registerPasswordVerify();
  });
};

Validator.prototype.initClientsideAdditions = function (formSelector) {
  var $form = $(formSelector);
  $form.find("input[type='checkbox']").on("change", function () {
    if (this.checked) {
      var $this = $(this);
      $this.closest('.has-error').removeClass('has-error');
      $this.parent().children('span').each(function () {
        $(this).remove();
      });
    }
    $form.find("button:disabled").each(function () {
      $(this).prop("disabled", false);
    });
  });
};

