import $ from "jquery";
import '@fancyapps/fancybox';

export default function LeasingController() {
}

function toggleLeasingModal(event) {
  var leasingLayer = $(".js-leasing-layer");

  event.preventDefault();
  event.stopPropagation();

  var options = {
    baseClass: "fancybox--mobile-fullscreen",
    hash: "leasing-calculator",
  };

  $.fancybox.open(leasingLayer, options);
}

function restoreURL() {
  var hash = window.location.hash;
  var url = document.location.href.split(hash)[0];

  if (!window.location.hash) return;

  window.history.pushState({}, document.title, url);
}

function checkForPreviousExistingModals() {
  var urlWithHash = window.location.hash;
  var currentHash = urlWithHash.split("#")[1];

  //if there is no hash present, return early
  if (!urlWithHash || !currentHash) {
    // close fancybox on forward navigation
    $.fancybox.close();
    return;
  }

  var leasingLayer = $(".js-leasing-layer");
  var leasingHash = "leasing-calculator";

  //if the leasing calculator is not there or the hash is not regarding to the calculator, return early
  if (
    !leasingLayer ||
    leasingLayer.length === 0 ||
    currentHash !== leasingHash
  )
    return;

  var options = {
    baseClass: "fancybox--mobile-fullscreen",
    afterClose: function () {
      restoreURL();
    },
  };

  // if there is another fancybox open, return early otherwise there are 2 open
  if ($.fancybox.getInstance()) return;

  $.fancybox.open(leasingLayer, options);
}

LeasingController.prototype.init = function () {
  var secondaryCTAFromADS = $(".js-toggle-leasing-modal");

  secondaryCTAFromADS.on("click", toggleLeasingModal);

  //eventlistener for browser url changes, which includes reloads and back/forward browser navigation
  window.onpopstate = function () {
    checkForPreviousExistingModals();
  };

  var currentWindowOnload = window.onload;

  window.onload = function() {
    if (currentWindowOnload) {
      currentWindowOnload();
    }
    checkForPreviousExistingModals();
  };
};

