import {publish} from "../event_publisher";
import {GA4_VIEW_PROMOTION, getViewPromotionEventTemplate, NAMESPACE} from "./events";
import {mapGA4Event} from "./event_mapper";
import {getAllAttributes, getItemDataInParent, teaserWasTracked} from "../tracking_helper";
import $ from "jquery";
import {processTracking} from "../360/teaser_view_tracker";

const observerCallback = (entries) => {
  entries.forEach(entry => {
    if (entry.intersectionRatio > 0) {
      trackElement(entry.target);
    }
  });
}

const intersectionObserver = new IntersectionObserver(observerCallback);

export const trackElement = (targetElement) => {
  if (!targetElement || teaserWasTracked(targetElement, GA4_VIEW_PROMOTION)) {
    return;
  }

  const eventCopy = getViewPromotionEventTemplate();

  mapGA4Event(eventCopy, getAllAttributes(targetElement));
  eventCopy["event_params"].items = getItemDataInParent(targetElement);
  publish(eventCopy);
}

export const registerViewPromotionTracking = (swiper) => {
  $(swiper.el).closest(".js-promotion-swiper").each(function () {
    intersectionObserver.observe(this);
  });
}

export const slideEndCallback = (swiper) => {
  const index = swiper.realIndex;
  const teaser = $(swiper.el).closest(".js-promotion-swiper");

  teaser.attr("data-current-slide", index);
  teaser.attr("data-" + NAMESPACE + "-creative-slot", index + 1);
  teaser.data("currentSlide", index);
  teaser.data(NAMESPACE + "CreativeSlot", index + 1);

  trackElement(teaser[0]);
  processTracking(teaser, false, index);
}

export const ViewPromotionTracker = () => {
  document.querySelectorAll(".js-promotion-tracking").forEach(teaser => intersectionObserver.observe(teaser));
}