/**
 * Pushes tracking information to the dataLayer when a teaser is displayed
 */
import $ from 'jquery';
import 'intersection_observer';
import {PROMOTION_IMPRESSION} from './events'
import {publish} from '../event_publisher'
import {getDataLayerEvents, isInViewport} from "../tracking_helper";

export default function TeaserViewTracker() {
}

export function processTracking(teasers, skipViewportCalc, slideIndex) {
  window.dataLayer = window.dataLayer || [];
  const newPromotions = [];

  $(teasers).each(function () {
    const teaser = $(this);
    if (skipViewportCalc || isInViewport(teaser, false)) {
      trackElement(teaser, newPromotions, slideIndex);
    }
  });

  if (newPromotions.length > 0) {
    const promoView = {'promotions': newPromotions};
    const ecommerce = {'promoView': promoView};
    const entry = {'event': PROMOTION_IMPRESSION, 'ecommerce': ecommerce};
    publish(entry);
  }
}

export const trackElement = function ($element, newPromotions, slideIndex) {
  const teaserName = $element.data('teaser-name');
  let position = determineElementPosition($element, slideIndex);

  if (wasTrackedBefore($element, teaserName, position)) {
    return;
  }

  const teaserType = $element.data('teaser-type');

  if ($('.thankyou-checkout').length === 0 || (teaserType !== 'Footer Links Teaser' && teaserType !== 'Footer Links')) {
    let promotion;
    const yPos = $element.data('teaser-yposition');

    const name = typeof yPos == 'number' ? teaserType + '_' + yPos : teaserType;
    if (typeof position == 'number') {
      promotion = {'name': name, 'creative': teaserName, 'position': '' + position};
    } else {
      promotion = {'name': name, 'creative': teaserName};
    }
    newPromotions.push(promotion);
  }
}

function wasTrackedBefore($element, teaserName, position) {
  let ignoreSlidePosition = true;
  if ($element.data().hasOwnProperty('currentSlide')) {
    ignoreSlidePosition = false;
  }

  const impressionEntries = getDataLayerEvents(PROMOTION_IMPRESSION);
  for (const entry of impressionEntries) {
    const oldPromos = entry.ecommerce.promoView.promotions;
    for (const promo of oldPromos) {
      if (promo.creative === teaserName && (ignoreSlidePosition || promo.position === '' + position)) {
        return true;
      }
    }
  }

  return false;
}

function determineElementPosition($element, slideIndex) {
  let position;
  const currentSlide = $element.data('current-slide');
  const xPos = $element.data('teaser-xposition');
  if (typeof slideIndex == 'number') {
    position = slideIndex;
  } else if (typeof currentSlide == 'number') {
    position = currentSlide;
  } else if (typeof xPos == 'number') {
    position = xPos;
  }

  if (typeof position == 'number') {
    position++; // increment to make tracking value start at 1 instead of 0
  }

  return position;
}

const intersectionObserver = new IntersectionObserver(function (entries) {
  const newPromotions = [];

  $(entries).each(function () {
    if (this.intersectionRatio > 0) {
      trackElement($(this.target), newPromotions);
    }
  });

  if (newPromotions.length > 0) {
    const promoView = {'promotions': newPromotions};
    const ecommerce = {'promoView': promoView};
    const entry = {'event': PROMOTION_IMPRESSION, 'ecommerce': ecommerce};
    publish(entry);
  }
});

TeaserViewTracker.prototype = {
  initIntersectionObserver: function () {
    const impressionTrackingElements = document.querySelectorAll('.js-teasertracking');
    for (const element of impressionTrackingElements) {
      intersectionObserver.observe(element);
    }
  },

  forceTrackingOfTeaser: function (teaser) {
    processTracking(teaser, true);
  },

  getSwipeCallbackFunction: function () {
    return function (index, element) {
      let closest_teaser = $(element).closest('.js-teasertracking');
      closest_teaser.attr('data-current-slide', index);
      processTracking(closest_teaser, false, index);
    };
  },
};