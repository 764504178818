import $ from 'jquery';
import Logger from 'utils/logger.js'

var LOGGER = new Logger("google tracking");

export default function GoogleTracking() {
}

function checkNotEmpty(value) {
  return typeof value !== 'undefined' && value !== null && value !== '';
}

function formatPrice(price) {
  return price.replace(',', '.');
}

function putChecked(data, value) {
  if (checkNotEmpty(value)) {
    data.value = value;
  }
}

function joinCheckedValues($element) {
  var label_array = [];
  var $filterlist = $element.closest("[id^=filterlist]");
  if ($filterlist.length === 0) {
    return $element.attr('data-tracking-label');
  }
  $filterlist.find("input:checked").each(function () {
    label_array.push($(this).attr('data-tracking-label'));
  });
  return label_array.join("|");
}

function getLabel($element) {
  var label = $element.attr('data-tracking-label');
  if ($element.is("input:checkbox")) {
    label = joinCheckedValues($element);
  }
  if (!checkNotEmpty(label)) {
    if ($element.is("button")) {
      label = $element.hasClass("collapsed") ? 'closed' : 'opened';
    }
    if ($element.is("select")) {
      label = $element.find(":selected").val();
      var orderIndex = label.indexOf('-');
      if (orderIndex > -1) {
        label = label.substring(orderIndex + 1, label.length);
        if (label !== "csi") {
          label = label + "_desc";
        }
      } else {
        label = label + "_asc";
      }
    }
  }
  return label;
}

GoogleTracking.prototype = {

  init: function () {
    var _this = this;
    $(document).ready(function () {
      _this.addClickTracking(_this);
    }).ajaxComplete(function () {
      _this.addClickTracking(_this);
    });

    $(document).on("change", "select[data-tracking-event]", function () {
      var $element = $(this);
      _this.clickEvent($element);
    });
    $("[data-hover-tracking-event]").mouseenter(function () {
      var $element = $(this);
      _this.hoverEvent($element);
    });

    $('.jh-checkout-payment-radio').on('change', function (e) {
      var paymentType = $(this).attr("data-tracking-option-on-submit");
      $("#nextStepButton").attr('data-tracking-option', paymentType);
    });

    // Tracking. Formtracking an DataLayer übergeben (KOSMAAGILE-2576)
    $(document).ready(function () {
      function trakkForm(e) {
        if (e.originalEvent) {
          var el = e.target;
          var el_name = el.name || el.id || el.type || el.nodeName;
          var form_name = $(this).closest('form').attr('name') || $(this).closest('form').attr('id');
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'eventCategory': 'Form Tracking',
            'eventAction': 'form (' + form_name + ')',
            'eventLabel': el_name + " (" + e.type + ")",
            'event': 'event'
          });
        }
      }

      $('.google-form-tracking-field').on("change", trakkForm);
      $('form').on("submit", trakkForm);
    });

    $('#accountLogOut').on('click', function () {
      sessionStorage.setItem("LoginState", "logout");
    });

    var login_state = sessionStorage.getItem("LoginState");
    if (login_state === "logout") {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'LoginState': "logout"
      });
      sessionStorage.removeItem("LoginState");
    }

  },
  addClickTracking: function (_this) {
    $("[data-tracking-event]:not(select)").each(function () {
      var $this = $(this);
      if (!$this.data("has-google-tracking")) {
        $this.on('click', function () {
          if ($(this).is('input:checked, a, button, btn, span')) {
            var $element = $(this);
            _this.clickEvent($element);
          }
        });
        $this.data("has-google-tracking", true);
      }
    });
  },
  hoverEvent: function ($element) {
    var event = $element.data("hover-tracking-event");
    var category = $element.data("hover-tracking-category");
    var action = $element.data("hover-tracking-action");
    var label = $element.data("hover-tracking-label");
    this.event(event, category, action, label);
  },
  clickEvent: function ($element) {
    var event = $element.attr('data-tracking-event'),
      do_event = $element.attr('data-tracking-directorder'),
      voucher_event = $element.attr('data-tracking-voucher'),
      category = $element.attr('data-tracking-category'),
      action = $element.attr('data-tracking-action'),
      label = getLabel($element),
      page = $element.attr('data-tracking-page'),
      option = $element.attr('data-tracking-option'),
      step = $element.attr('data-tracking-step');

    if (checkNotEmpty(do_event)) {
      this.foldEvent(do_event);
    }

    if (checkNotEmpty(voucher_event)) {
      this.foldEvent(voucher_event);
    }

    if (checkNotEmpty(event)) {
      this.event(event, category, action, label, page, step, option);
    }

    if (action === 'direct_to_cart_open') {
      var prodid = $element.attr('data-tracking-prodid'),
        pagetype = $element.attr('data-tracking-pagetype'),
        pvalue = $element.attr('data-tracking-pvalue'),
        prodidCategory = $element.attr('data-tracking-prodid-category');

      if (checkNotEmpty(prodid) || checkNotEmpty(pagetype) || checkNotEmpty(pvalue) || checkNotEmpty(prodidCategory)) {
        this.remarketingEvent(prodid, pagetype, pvalue, prodidCategory);
      }
    }

  },
  foldEvent: function (event) {
    try {
      var foldEvent = {};
      if (checkNotEmpty(event)) {
        foldEvent.event = event;
      }
      foldEvent.eventCategory = 'cart';

      dataLayer.push(foldEvent);
    } catch (e) {
      LOGGER.logError("Google Tracking", e.message);
    }
  },
  remarketingEvent: function (prodid, pagetype, pvalue, prodidCategory, pQuantity) {
    try {
      var remarketingData = {};
      remarketingData.event = 'tag_params';
      if (checkNotEmpty(prodid)) {
        remarketingData.ecomm_prodid = prodid;
      }
      if (checkNotEmpty(pagetype)) {
        remarketingData.ecomm_pagetype = pagetype;
      }
      if (checkNotEmpty(pvalue)) {
        remarketingData.ecomm_pvalue = formatPrice(pvalue);
        remarketingData.ecomm_totalvalue = formatPrice(pvalue);
      }
      if (checkNotEmpty(prodidCategory)) {
        remarketingData.ecomm_category = prodidCategory;
      }
      if (checkNotEmpty(pQuantity)) {
        remarketingData.ecomm_pquantity = pQuantity;
      }
      dataLayer.push(remarketingData);
    } catch (e) {
      LOGGER.logError("Google Tracking", e.message);
    }
  },
  event: function (event, category, action, label, page, step, option) {
    try {
      var data = {};
      if (checkNotEmpty(event)) {
        data.event = event;
      }
      if (checkNotEmpty(category)) {
        data.eventCategory = category;
      }
      if (checkNotEmpty(action)) {
        data.eventAction = action;
      }
      if (checkNotEmpty(label)) {
        data.eventLabel = label;
      }
      if (checkNotEmpty(page)) {
        data.pageName = page;
      }
      if (checkNotEmpty(step) && checkNotEmpty(option)) {
        data.ecommerce = {"checkout_option": {"actionField": {"step": step, "option": option}}};
      }
      dataLayer.push(data);
    } catch (e) {
      LOGGER.logError("Google Tracking", e.message);
    }
  }
};
