//noinspection JSHint
/**
 * Kann bestimmte Klassen auf- und zuklappen je nachdem ob etwas geklickt wird oder nicht.
 */
import $ from "jquery";
import {replaceElementContentWithUrl} from "utils/ajax_handler_helper";

export default function FoldController() {
}

FoldController.prototype.init = function () {
  var justToggle = function ($foldTrigger, $elem) {
    $elem.toggleClass('show');
  };

  var checkboxToggle = function ($foldTrigger, $elem) {
    if ($foldTrigger.is(':checked')) {
      $elem.addClass('show')
    } else {
      $elem.removeClass('show');
    }
  };


  $(document).on('click', '.fold-trigger-replace', function () {
    updateWithAjaxReplace($(this), justToggle);

    var $foldTriggerScrollAnchor = $('.fold-trigger-scroll-anchor');
    if ($foldTriggerScrollAnchor.length) {
      $('html, body').animate({
        scrollTop: $foldTriggerScrollAnchor.offset().top
      }, 300);
    }
  });

  $(document).on('click', '.fold-trigger', function () {
    update($(this), justToggle);
  });

  const $checkoutAlternativeAddressTriggerElement = $('.js-alternative-address-trigger');
  const $checkoutAlternativeAddressFormClass = '.js-alternative-address-form';
  const $checkoutAdditionalDeliveryInfoTriggerElement = $('.js-add-delivery-info-trigger');
  const $checkoutAdditionalDeliveryInfoFormClass = '.js-add-delivery-info-form';
  const $showClass = 'show';

  $checkoutAlternativeAddressTriggerElement.on('click', function(){
    $(this).find($checkoutAlternativeAddressFormClass).val( $($checkoutAlternativeAddressFormClass).hasClass($showClass) )
  });

  $checkoutAdditionalDeliveryInfoTriggerElement.on('click', function(){
    $(this).find($checkoutAdditionalDeliveryInfoFormClass).val( $($checkoutAdditionalDeliveryInfoFormClass).hasClass($showClass) )
  })

  $(document).on("ready", function () {
    $('input[type="checkbox"].fold-trigger').each(function () {
      update($(this), checkboxToggle);
    });
  });

  function update($foldTrigger, toggleFunction) {
    var elemToFoldSelector = $foldTrigger.attr("data-elements-to-fold-selector");
    if (typeof elemToFoldSelector !== "undefined") {
      var $elem = $(elemToFoldSelector);
      toggleFunction($foldTrigger, $elem);
    }
  }


  function updateWithAjaxReplace($foldTrigger, toggleFunction) {
    var elemToFoldSelector = $foldTrigger.attr("data-elements-to-fold-selector");
    var url = $foldTrigger.attr("data-replace-url");
    if (typeof elemToFoldSelector !== "undefined") {
      var $elem = $(elemToFoldSelector);
      replaceElementContentWithUrl($elem, url);
    }

    update($foldTrigger, toggleFunction);
  }
};


