/**
 * Pushes tracking information to the dataLayer when a teaser is clicked
 */
import $ from "jquery";
import {PROMOTION_CLICK} from './events'
import {publish} from '../event_publisher'

export default function TeaserClickTracker() {
}

TeaserClickTracker.prototype = {
  init: function ($teaser) {
    const teaser = $teaser || $('.js-teasertracking');
    let lastClick = 0;
    const _this = this;

    teaser.each(function () {
      const callback = function (e) {
        const currentTime = new Date().getTime();
        if (currentTime > (lastClick + 2000) && e.which != null) {
          _this.click($(this));
          lastClick = currentTime;
        }
      };
      $(this).off('click', callback).on('click', callback);
    });
  },

  click: function (dataSrc) {
    window.dataLayer = window.dataLayer || [];
    let promotions = [];

    if (dataSrc != null) {
      const index = $(dataSrc).data('current-slide');
      const swipeItem = $(dataSrc).find('.swipe-item[data-swipe-item-id]').get(index);
      const realIndex = $(swipeItem).data('swipeItemId');

      const teaserName = dataSrc.data('teaser-name');
      const teaserType = dataSrc.data('teaser-type');
      let xPos = realIndex ? realIndex : dataSrc.data('teaser-xposition');
      const yPos = dataSrc.data('teaser-yposition');
      let promotion;

      if ($('.thankyou-checkout').length === 0 || (teaserType !== 'Footer Links Teaser' && teaserType !== 'Footer Links')) {
        if (typeof xPos == 'number' && typeof yPos == 'number') {
          xPos++; // increment to make tracking value start at 1 instead of 0
          promotion = {'name': teaserType + '_' + yPos, 'creative': teaserName, 'position': '' + xPos};
        } else {
          promotion = {'name': teaserType, 'creative': teaserName};
        }

        let clickEntry;
        for (const dlElement of window.dataLayer) {
          if (dlElement.event != null && dlElement.event === PROMOTION_CLICK) {
            clickEntry = dlElement;
          }
        }

        if (clickEntry !== undefined) {
          promotions = clickEntry.ecommerce.promoClick.promotions;
          if (promotions !== undefined) {
            for (const promo of promotions) {
              if (promo.creative === teaserName && promo.position === '' + xPos) {
                return;
              }
            }
          }
        }

        promotions.push(promotion);
      }

      if (promotions.length > 0) {
        const promoClick = {'promotions': promotions};
        const ecommerce = {'promoClick': promoClick};
        const entry = {'event': PROMOTION_CLICK, 'ecommerce': ecommerce};
        publish(entry);
      }
    }
  }
}

