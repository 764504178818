import $ from "jquery";
import EmarsysRecoTeaser from "teaser/emarsys_reco_teaser";

const factFinderProxyUrl = window.shopConfig.factfinderProxyUrl;
const factFinderTrackingActive = window.shopConfig.factfinderTrackingActive;

export default function FactFinderController() {
}

function prepareAdsTracking($container) {
  resetTrackingParameters();
  FactFinderController.data.id = $container.data('id');
  FactFinderController.data.masterId = $container.data('productid');
  FactFinderController.data.event = $container.data('event');
  FactFinderController.data.title = $container.data('title');
  FactFinderController.data.query = $container.data('query');
  FactFinderController.data.pos = $container.data('pos');
  FactFinderController.data.origPos = $container.data('origpos');
  FactFinderController.data.page = $container.data('page');
  FactFinderController.data.pageSize = $container.data('pagesize');
  FactFinderController.data.origPageSize = $container.data('origpagesize');
  FactFinderController.data.simi = $container.data('simi');
}

function resetTrackingParameters() {
  FactFinderController.data = {
    "id": null,
    "masterId": null,
    "event": null,
    "title": null,
    "query": null,
    "pos": null,
    "origPos": null,
    "page": null,
    "pageSize": null,
    "origPageSize": null,
    "simi": null
  };
}

function doRequest() {
  if (FactFinderController.data === undefined || FactFinderController.data.event === undefined) {
    return;
  }

  if (factFinderTrackingActive) {
    $.ajax({
      type: 'POST',
      url: factFinderProxyUrl,
      data: JSON.stringify(FactFinderController.data),
      contentType: 'application/json',
      cache: false
    });
  }
}

const addToCartHandler = function () {
  if ($(".emarsys-reco-teaser").length !== 0) {
    new EmarsysRecoTeaser();
  }
};

const adsLinkHandler = function () {
  prepareAdsTracking($(this));
  doRequest();
};

FactFinderController.prototype = {
  init: function () {
    const _this = this;
    _this.data = {};
    $(document).on("ready", function () {
      _this.bindFactFinderTrackingEvents();
    });

    $(document).on("ajaxComplete", function () {
      _this.bindFactFinderTrackingEvents();
    });
  },
  bindFactFinderTrackingEvents: function () {
    $('.js-ads-link').off('click.ff.tracking', adsLinkHandler).on("click.ff.tracking", adsLinkHandler);
    $('.js-add-to-cart').off('click.ff.tracking', addToCartHandler).on("click.ff.tracking", addToCartHandler);
    $(".js-trigger-standalone-salesbox").off("click.ff.tracking", adsLinkHandler).on("click.ff.tracking", adsLinkHandler);
  }
};
