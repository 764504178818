import $ from "jquery";
import header_carts_handler from 'components/header_carts_handler';

export default function MultiAddBasketController() {
  this.cart_input = header_carts_handler().shopCartHandler;
}

MultiAddBasketController.prototype.init = function () {
  var _this = this;
  $(document).on("click", ".add-list-to-cart", function (e) {
    var $form = $(this).closest('form');
    _this.submit(e, $form);
  });
};

MultiAddBasketController.prototype.submit = function (e, $form) {
  e.preventDefault();
  var _this = this;
  $.post($form.attr("action"), $form.serialize(), function (data) {
    _this.cart_input.input(data.markup, data.count, data.total);
    var redirectToUrl = data.redirectToUrl || "";
    if (redirectToUrl !== "") {
      window.location = redirectToUrl;
    }
  });
};
