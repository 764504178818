import $ from "jquery";

export default function PromotionStripe() {
}

function promoStripeClickFunction() {
  var $inner = $(".promotion-stripe-inner");
  $inner.toggleClass("expanded");
  var expanded = $inner.hasClass("expanded");
  if (expanded) {
    promoStripeTrackExpansion("maximize");
    $.ajax("/promostripeteaser/expand/");
  } else {
    promoStripeTrackExpansion("minimize");
    $.ajax("/promostripeteaser/collapse/");
  }
  promotionStripeUpdateExpandableContentHeight();
}

function promoStripeTrackExpansion(state) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    "event": "event",
    "eventCategory": "Teaser",
    "eventAction": "Campaign Stripe",
    "eventLabel": state
  });
}

function promotionStripeUpdateExpandableContentHeight() {
  var $parent = $(".promotion-stripe-inner");
  var $expandableContent = $parent.find(".promotion-stripe-content");
  var sessionAttributeExpand = sessionStorage.getItem("expandPromoStripeTeaser");
  if ($parent.hasClass("expanded") && sessionAttributeExpand !== "false"
    || sessionStorage.getItem("expandPromoStripeTeaser") === "true") {
    var contentHeight = 0;
    $expandableContent.children().each(function () {
      contentHeight += $(this).outerHeight(true);
    });
    $expandableContent.css("max-height", contentHeight);
  } else {
    $expandableContent.css("max-height", 0);
    $parent.removeClass("expanded");
  }
}

function promotionStripeUpdateCountdown() {
  var $countdownElement = $(".promotion-stripe-countdown");
  if ($countdownElement.length > 0) {
    var endDate = $countdownElement.data("countdown-end");
    var interval = setInterval(function () {
      var now = new Date().getTime();
      var difference = endDate - now;

      if (difference < 0) {
        clearInterval(interval);
        return;
      }

      var days = promotionStripePatchTimeDigits(Math.floor(difference / (86400000)));
      var hours = promotionStripePatchTimeDigits(Math.floor((difference % (86400000)) / (3600000)));
      var minutes = promotionStripePatchTimeDigits(Math.floor((difference % (3600000)) / (60000)));
      var seconds = promotionStripePatchTimeDigits(Math.floor((difference % (60000)) / 1000));

      var pattern = $countdownElement.data("countdown-pattern");
      pattern = pattern.replace(/\[dd]/, days);
      pattern = pattern.replace(/\[hh]/, hours);
      pattern = pattern.replace(/\[mm]/, minutes);
      pattern = pattern.replace(/\[ss]/, seconds);
      $countdownElement.html(pattern);
    }, 1000);
  }
}

function promotionStripePatchTimeDigits(digits) {
  return (digits < 10 ? "0" : "") + digits;
}

function promotionStripeCreateSlide() {
  var $text = $(".promotion-stripe-text");
  var $countdown = $(".promotion-stripe-countdown");
  var $slides = $(".promotion-stripe-slide");
  if ($text.length > 0 && $countdown.length > 0) {
    var textRect = $text[0].getBoundingClientRect();
    var countdownRect = $countdown[0].getBoundingClientRect();
    if (textRect.top !== countdownRect.top) {
      var textHeight = textRect.height;
      var countdownHeight = countdownRect.height;
      var maxHeight = Math.max(textHeight, countdownHeight);
      //set slider height to biggest child-height
      $(".promotion-stripe-slider").css("height", maxHeight);
      if (textHeight !== countdownHeight) {
        //set all slides to equal height
        $slides.css("height", maxHeight);
      }
      var keyframes = findKeyframesRule("promoStripeSlide");
      if (keyframes !== null) {
        try {
          keyframes.deleteRule("50%");
          keyframes.deleteRule("93.75%");
        } catch (e) {
          //fix for IE
          keyframes.deleteRule(0.5);
          keyframes.deleteRule(0.9375);
        }
        try {
          keyframes.appendRule("50% {top: -" + maxHeight + "px;}");
          keyframes.appendRule("93.75% {top: -" + maxHeight + "px;}");
        } catch (e) {
          //fix for older IE
          keyframes.insertRule("50% {top: -" + maxHeight + "px;}", keyframes.rules.length);
          keyframes.insertRule("93.75% {top: -" + maxHeight + "px;}", keyframes.rules.length);
        }
        $slides.addClass("animate");
      }
    } else {
      //text and countdown fit within one line
      $slides.removeClass("animate");
      $text.css("margin-right", "5px");
    }
  } else {
    //text and/or countdown does not exist -> no animation needed
    $slides.removeClass("animate");
  }
}

function findKeyframesRule(rule) {
  // gather all stylesheets into an array
  var sheets = document.styleSheets;

  // loop through the stylesheets
  for (var i = 0; i < sheets.length; ++i) {
    // loop through all the rules
    for (var j = 0; j < sheets[i].cssRules.length; ++j) {
      // find the keyframe rule whose name matches our passed over parameter and return that rule
      if (sheets[i].cssRules[j].type === window.CSSRule.KEYFRAMES_RULE && sheets[i].cssRules[j].name === rule)
        return sheets[i].cssRules[j];
    }
  }
  // rule not found
  return null;
}

PromotionStripe.prototype.init = function () {
  $(".promotion-stripe-controls").off("click", promoStripeClickFunction).on("click", promoStripeClickFunction);
  promotionStripeUpdateCountdown();
  setTimeout(promotionStripeCreateSlide, 1000);
};

PromotionStripe.prototype.responsive = function (msg, onoff) {
  if (onoff) {
    promotionStripeCreateSlide();
    promotionStripeUpdateExpandableContentHeight();
  }
};

