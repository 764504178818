import $ from "jquery";
import {NAMESPACE} from "./ga4/events";
import {mapGA4Items} from "./ga4/event_mapper";

export const getDataLayerEvents = function (eventType) {
  const oldImpressions = [];

  if (!window.dataLayer) {
    return oldImpressions;
  }

  for (const element of window.dataLayer) {
    if (eventType === element.event) {
      oldImpressions.push(element);
    }
  }

  return oldImpressions;
}

export const isInViewport = function (object, includeHorizontalPosition) {
  const docViewTop = $(window).scrollTop();
  const docViewBottom = docViewTop + $(window).height();

  const elemTop = $(object).offset().top;
  const elemBottom = elemTop + $(object).height();

  const productTopInViewport = ((elemTop + 50 <= docViewBottom) && (elemTop >= docViewTop));
  const productBottomInViewport = ((elemBottom <= docViewBottom) && (elemBottom - 50 >= docViewTop));

  const verticallyInViewport = productTopInViewport || productBottomInViewport;
  const horizontallyInViewport = !includeHorizontalPosition || ($(object).offset().left < $(document).width());

  return horizontallyInViewport && verticallyInViewport;
}

export const getAllAttributes = (element) => {
  return Object.fromEntries(Array.from(element.attributes).map((a) => [a.nodeName.replace("data-", ""), a.value]));
}

export const teaserWasTracked = (teaser, eventKey) => {
  const viewPromotionEntries = getDataLayerEvents(eventKey);
  if (viewPromotionEntries.length < 1) {
    return false;
  }

  const creativeName = $(teaser).data(NAMESPACE + "CreativeName");
  let creativeSlot = $(teaser).data(NAMESPACE + "CreativeSlot");
  creativeSlot = creativeSlot === undefined ? undefined : creativeSlot.toString();

  for (const entry of viewPromotionEntries) {
    const eventBody = entry["event_params"];
    if (eventBody.creative_name === creativeName && eventBody.creative_slot === creativeSlot) {
      return true;
    }
  }

  return false;
}

export const itemIsVisible = ($product, $parent) => {
  if (!$product.data(NAMESPACE + "ProductId")) {
    return false;
  }

  const parentIsSwiper = $parent.hasClass("swipe");
  const parentContainsSwiper = $parent.find(".swipe").length > 0;

  if (parentIsSwiper || parentContainsSwiper) {
    return $product.hasClass("swiper-slide-visible");
  } else {
    return $product.height() > 0 && isInViewport($product, true);
  }
}

export const getItemDataInParent = (parentElement) => {
  const items = [];

  $(parentElement).find(".js-eecproduct").each(function () {
    if (itemIsVisible($(this), $(parentElement))) {
      items.push(mapGA4Items(getAllAttributes(this)));
    }
  });

  return items;
}