import $ from 'jquery';
import {performRedirect, reloadCurrentUrl, shouldRedirect, shouldReloadCurrentUrl} from "utils/ajax_handler_helper";
import 'bootstrap';
import 'jquery-form';
import header_carts_handler from 'components/header_carts_handler';

var createSuccessfullAjaxResponseHandlerForTrigger = function ($trigger, focusedElementId, callback) {
  return function (data) {
    if (shouldReloadCurrentUrl(data)) {
      reloadCurrentUrl();
      return;
    }
    if (shouldRedirect(data)) {
      performRedirect(data);
      return;
    }

    var name = $trigger.attr('data-ajax-replace-div');
    // get the last containerElement (e.g. #leasing-calculator) shown on page to be replaced with newHtml
    var $replace = $($('div#' + name)).last();
    var newHtml = $(data).html();
    $replace.html(newHtml);

    var $focusedElement = $('#' + focusedElementId);
    $focusedElement.on("change", false);
    $focusedElement.trigger("focus");
    $focusedElement.off("change", false);

    typeof callback === 'function' && callback();

    let $cartCount = $('.js-service-count');
    if ($cartCount[0]) {
      let cartCount = Number($cartCount[0].textContent.trim());
      header_carts_handler().shopCartHandler.input(cartCount, cartCount);
    }
  };
};

function isIE() {
  var ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  return ua.indexOf("MSIE ") > -1 || ua.indexOf("Trident/") > -1;
}


export const installOnTrigger = function ($trigger, callback) {
  var $form = $trigger.closest('form');
  var focusedElementId = $(document.activeElement).attr('id');

  var currentUrlElement = document.createElement('input');
  currentUrlElement.setAttribute('type', 'hidden');
  currentUrlElement.setAttribute('name', 'currentUrl');
  currentUrlElement.setAttribute('value', document.URL);
  $form.append(currentUrlElement);

  var params = {
    cache: false,
    contentType: "application/x-www-form-urlencoded; charset=UTF-8",
    data: doIEWorkaroundForLeasingCalculatorForm($trigger, $form) || {},
    dataType: "html",
    success: createSuccessfullAjaxResponseHandlerForTrigger($trigger, focusedElementId, callback)
  };

  // Durch einen Bug im IE8 und jQuery gibt es Probleme mit trailing Slashes in Dialogen.
  // Dieses betrifft vor allem Formulare, die per AJAX nachgeladen werden.
  // Daher bieten wir die Möglichkeit an, die URL Des Formulars an dem Button zu setzen.
  // Für den Bug siehe hier: http://bugs.jquery.com/ticket/6236
  var dataAjaxHtmlService = $trigger.attr('data-ajax-html-service') || null;
  if (dataAjaxHtmlService !== null) {
    params.url = dataAjaxHtmlService;
  }

  $form.ajaxSubmit(params);
};

function doIEWorkaroundForLeasingCalculatorForm($trigger, $form) {
  if (isIE() && $form.attr("id") === "leasing-form") {
    $form.find("input[type='radio']").attr("name", "term");
    $form.find("input[type='radio']").prop("disabled", false);

    if ($trigger.hasClass("term")) {
      $form.find("input[type='radio']").prop("disabled", true);
      return {term: $trigger.find("input").val()};
    }
  }
}

var installOnClick = function ($rootElem) {
  $rootElem.on('click', '.form-trigger', function (e) {
    var $trigger = $(e.currentTarget);
    if ($trigger.hasClass('form-trigger-on-change')) {
      return false;
    }
    if ($trigger.prop('type') === 'checkbox') {
      // ändert man eine Checkbox sollte das Form noch nicht abgesendet werden
      e.preventDefault();
    }

    if (!($trigger.prop('tagName') === 'INPUT' && $trigger.prop('type') === 'checkbox')) {
      e.preventDefault();
    }
    installOnTrigger($trigger);
  });

  $rootElem.on('keyup', '.form-trigger', function (e) {
    var $trigger = $(e.currentTarget);
    if (e.which === 13) {
      e.preventDefault();
      installOnTrigger($trigger);
    }
  });

};

var installOnChange = function ($rootElem) {
  $rootElem.on('change', '.form-trigger-on-change', function (e, callback) {
    var $trigger = $(e.currentTarget);
    const isBasket = $trigger.hasClass('js-basket-quantity-field');
    const inputValue = Number($trigger.val());
    const basketMinAmount = Number($trigger.attr("min"));

    if (isBasket && inputValue < basketMinAmount) {
      $('html, body').animate({
        scrollTop: $trigger.offset().top - 250
      }, 50);
      $trigger.val(basketMinAmount).focus();
      $('.js-to-cart-btn').on("click", function(e) {
        e.preventDefault();
      });
    }

    installOnTrigger($trigger, callback);
  });
};

installOnClick($(document));
installOnChange($(document));
