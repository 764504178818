import $ from "jquery";

const config = {
  selectors: {
    switch: '.js-tax-switch'
  },
  parameter: 'Shop'
};

const taxSwitch = () => {
  $(config.selectors.switch).on('click', () => {
    const newMode = $(config.selectors.switch).data('toggleMode');
    const url = new URL(window.location);
    url.searchParams.set(config.parameter, newMode);

    window.location = url.href;
  });
};

export default taxSwitch;
