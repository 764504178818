import $ from "jquery";

var pageEndClass = "at-page-end";
var pageNearEndClass = "near-page-end";

export default function ScrollPosition() {
}

function getPosition() {
  $(window).on("scroll", function () {
    var body = $("body");

    var scrollPositionBottom = $(window).scrollTop() + $(window).height();
    var pageEnd = $(document).height();

    if (scrollPositionBottom === pageEnd) {
      body.removeClass(pageNearEndClass);
      body.addClass(pageEndClass);
    } else if (scrollPositionBottom >= pageEnd - 50) {
      body.removeClass(pageEndClass);
      body.addClass(pageNearEndClass);
    } else {
      body.removeClass(pageEndClass);
      body.removeClass(pageNearEndClass);
    }
  });
}

$(document).ready(function () {
  getPosition();
});


