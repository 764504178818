import {getViewItemListEventTemplate} from "./events";
import {mapGA4Event, mapGA4Items} from "./event_mapper";
import {getAllAttributes} from "../tracking_helper";
import {publish} from "../event_publisher";

function getItemsData(targetElement) {
  let ga4Items = []
  targetElement.find(".js-eecproduct").each(function () {
    ga4Items.push(mapGA4Items(getAllAttributes(this)));
  });
  return ga4Items;
}

export const ViewItemListListener = (target) => {
  if (target && target[0] !== undefined) {
    const eventCopy = getViewItemListEventTemplate();
    mapGA4Event(eventCopy, getAllAttributes(target[0]));
    eventCopy["event_params"].items = getItemsData(target);
    publish(eventCopy);
  }
}