//noinspection JSHint

import $ from 'jquery';
import TeaserViewTracker from '../tracking/google/360/teaser_view_tracker';
import 'utils/sliderknobs.js';
import 'utils/slidercontrols.js';
import 'utils/sliderlazyload.js';
import 'swipe'

export default function ThemenTeaser() {
  this.callbackFunction = function (index, element) {
    var teaserImpressionCallback = new TeaserViewTracker().getSwipeCallbackFunction();
    teaserImpressionCallback(index, element);
    var parent = $(element).closest(".js-teasertracking");
    if (parent) {
      $(parent).attr("data-current-slide", index);
    }
  };
}

ThemenTeaser.prototype.removeSwipes = function () {
  $(".service-teaser-row .swipe").each(function () {
    if ($(this).data("Swipe")) {
      $(this).data("Swipe").kill();
    }
  });
};

ThemenTeaser.prototype.responsive = function (msg, on_off) {
  switch (msg) {
    case 'res.xs':
      if (on_off) { // entered state xs
        this.removeSwipes();
        $(".service-teaser-row .swipe").Swipe({pair: 4, callback: this.callbackFunction}).knobs().lazy();
      }
      break;
    case 'res.sm':
      if (on_off) { // entered state sm
        this.removeSwipes();
        $(".service-teaser-row .swipe").Swipe({pair: 4, callback: this.callbackFunction}).knobs().lazy();
      }
      break;
    default:
      if (on_off) {
        this.removeSwipes();

        $(".service-teaser-row .swipe").Swipe({
          pair: 4,
          continuous: false,
          callback: this.callbackFunction
        }).knobs().controls().lazy();
      }
  }
};

