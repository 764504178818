import $ from 'jquery';
import TeaserViewTracker from '../tracking/google/360/teaser_view_tracker'

var defaults = {
  classes: [""]
}

function Controls($el, options) {
  this.slider = $el.data("Swipe");
  this.$el = $el;
  this.options = $.extend(defaults, options);
  this.init();
  this.$el.on("swipe.kill", this.kill.bind(this));
}

Controls.prototype = {
  init: function () {
    if ($('[data-index="0"]').hasClass("jh-has-zoom-image")) {
      $('.zoom-icon.on-ads').show();
    }
    if (!this.slider) return;

    this.numSlides = this.slider.getNumSlides();
    this.indicatorId = this.slider.indicatorId;


    if (this.options.selector) {
      this.controls = $(this.options.selector);
    } else this.controls = $(".carousel-control-js", this.$el);

    if (this.controls.length === 0) this.controls = $(".carousel-control-js", this.$el.parent());


    if (this.controls.length === 0) {
      // No Slider controls found
      return;
    }

    this.addOptionalClasses();

    if (this.numSlides === 1) {
      this.controls.addClass("inactive").hide();
      this.controls.remove();

    } else {
      this.controls.show();
      this.checkActive();
    }

    this.originalCallback = this.slider.options.callback;

    this.slider.options.callback = this.callback.bind(this);

    var _this = this;
    this.controls.on("click", function (e) {
      e.preventDefault();

      if ($(this).attr("data-slide") === "prev") {
        _this.slider.prev();
        _this.checkActive();
      } else {
        _this.slider.next();
        _this.checkActive();
      }
    })
  },
  checkActive: function () {
    if (this.slider.options.continuous) return;

    this.controls.removeClass("inactive");

    if (!this.slider.options.continous && this.slider.getPos() === 0) {
      this.controls.filter('[data-slide="prev"]').addClass("inactive");
    }
    if (!this.slider.options.continous && this.slider.getPos() === this.numSlides - 1) {
      this.controls.filter('[data-slide="next"]').addClass("inactive");
    }
  },
  addOptionalClasses: function () {
    for (var i = 0; i < this.controls.length; i++) {
      var element = this.controls[i];
      var classes = this.options.classes.join(" ");
      $(element).addClass(classes);
    }
  },
  kill: function () {
    this.controls.off("click");
    this.controls.hide();
    this.$el.off("swipe.kill");
  },
  callback: function (pos, element, containerId) {
    if ($(element).find('[data-index="' + pos + '"]').hasClass("jh-has-zoom-image")) {
      $('.zoom-icon.on-ads').show();
    } else {
      $('.zoom-icon.on-ads').hide();
    }
    if (this.originalCallback) {
      this.originalCallback.call(null, pos, element, containerId);
    }
    this.checkActive();

    var teaserImpressionCallback = new TeaserViewTracker().getSwipeCallbackFunction();
    teaserImpressionCallback(pos, $(element).closest(".js-teasertracking"));
  }
}

$.fn.controls = function (options) {
  var defaults = {selector: null};
  options = $.extend(defaults, options);

  var matched = $(this);

  matched.each(function () {
    new Controls($(this), options)
  });
  return matched;
}
