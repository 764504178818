import $ from "jquery";
import EmarsysRecoTeaser from "teaser/emarsys_reco_teaser";

const teaserConfig = {
  selectors: {
    productItem: '.js-product-item',
    showAddToWishlistTeaser: 'show-add-to-wishlist-teaser',
    showAddToCartTeaser: 'show-add-to-cart-teaser',
    addToWishlistId: "#wishlist-teaser-",
    addToCartId: "#add-to-cart-teaser-",
  },
  modifiers: {
    showModal: 'show',
    closeModal: 'hidden.bs.modal'
  }
}

export const addToWishlistTeaserHandler = (trigger) => {
  const showAddToWishlistTeaser = trigger.data(teaserConfig.selectors.showAddToWishlistTeaser);
  showAddToWishlistTeaser && setupAddToWishlistTeaser(trigger);
}

export const addToCartTeaserHandler = (trigger) => {
  const showAddToCartTeaser = trigger.data(teaserConfig.selectors.showAddToCartTeaser);
  showAddToCartTeaser ? setupAddToCartTeaser(trigger) : redirectToCart();
}

export const removeTeaserOnClosedModal = (teaserId) => {
  $(teaserId).on(teaserConfig.modifiers.closeModal, function () {
    $(teaserId).remove();
  });
}

export const setupAddToWishlistTeaser = (trigger) => {
  const wishlistTeaserId = teaserConfig.selectors.addToWishlistId + trigger.data("productid");
  $(wishlistTeaserId).modal(teaserConfig.modifiers.showModal);
};

const setupAddToCartTeaser = (trigger) => {
  const cartTeaserId = teaserConfig.selectors.addToCartId + trigger.data("productid");
  $(cartTeaserId).modal(teaserConfig.modifiers.showModal);
  if ($(".js-add-to-cart-emarsys-reco").length !== 0) {
    window.ScarabQueue.push(['view', trigger.data("itemId")]);
    new EmarsysRecoTeaser().initAddToCartRecoTeaser();
  }
};

const redirectToCart = () => {
  window.location = window.shopConfig.basketPageUrl;
}
