import {getSelectItemEventTemplate, NAMESPACE} from "./events";
import {mapGA4Event, mapGA4Items} from "./event_mapper";
import {getAllAttributes} from "../tracking_helper";
import {publish} from "../event_publisher";

const addClickListener = (itemElement) => {
  const callback = (e) => {
    trackElement(e.target);
  };

  $(itemElement).find("a, button")
      .off("click." + NAMESPACE + ".item.select")
      .on("click." + NAMESPACE + ".item.select", callback);
}

const getItemData = ($target) => {
  return [mapGA4Items(getAllAttributes($target[0]))];
}

const trackElement = (targetElement) => {
  const $itemElement = $(targetElement).closest(".js-eecproduct");
  const eventCopy = getSelectItemEventTemplate();
  mapGA4Event(eventCopy, getAllAttributes($itemElement[0]));
  eventCopy["event_params"].items = getItemData($itemElement);
  publish(eventCopy);
}

export const SelectItemTracker = ($itemList) => {
  $itemList.find(".js-eecproduct").each(function () {
    addClickListener(this);
  });
}