import $ from "jquery";
import PubSub from 'pubsub-js'
import header_carts_handler from 'components/header_carts_handler';
import AdsProductImagesGalleryController from "components/product_detail/product_images_gallery_controller";
import AdsZoomController from "components/product_detail/zoom_controller";
import VariationController from "components/product_detail/variation_controller";
import AdsQuantityChange from "components/product_detail/ads_quantity_change";
import AdsAccordionController from "components/product_detail/ads_accordion_controller";
import SalesBoxCartButtonController from "components/sales_box/sales_box_cart_button_controller";
import MultiAddBasketController from "components/basket/multi_add_basket_controller";
import ScrollToTopHelper from "utils/scroll_to_top_helper";
import CollapseScroll from "utils/collapse_scroll";
import NumberPlusMinusButtons from "utils/form_elements/number_plus_minus_buttons";
import BrowserDetect from "utils/browserdetect";
import ResponsiveHelper from "utils/responsive_helper";
import ProductListingKeepPositionHelper from "utils/product_listing_keep_position_helper";
import LeasingController from "components/leasing_calculator";
import {WishlistController} from 'components/wishlist_controller';
import {ViewItemListener} from "../tracking/google/ga4/view_item_listener";

export default function Ads() {
  PubSub.subscribe("core.attach", this.attach.bind(this));
  PubSub.subscribe("res", this.responsive.bind(this));

  this.cart_input = header_carts_handler().shopCartHandler;
  new ProductListingKeepPositionHelper().initClearHandler();
  // ZoomController sollte vor Gallery initialisiert werden, da dieser auf Events der Gallery reagiert
  new AdsZoomController()
      .init()
      .reinitAfterAjaxEvent("ads-article-content-replaced-by-ajax-result");
  this.adsProductImagesGalleryController = new AdsProductImagesGalleryController()
      .initOnDomReady()
      .reinitAfterAjaxEvent("ads-article-content-replaced-by-ajax-result");
  new VariationController().init();
  new AdsQuantityChange().init();
  new AdsAccordionController().init();
  this.browserDetect = new BrowserDetect();
  this.browserDetect.init();
  this.initEventHandler();
  new SalesBoxCartButtonController().init();
  new MultiAddBasketController().init();
  new ScrollToTopHelper().init();
  new CollapseScroll().init();
  new NumberPlusMinusButtons().init();
  new LeasingController().init();
  WishlistController();
  ViewItemListener();

  if ($(".video-play-button").length !== 0) import('../../libs/youtube.js').then(YoutubePlayer => {
    let ytPlayer = new YoutubePlayer.default();
    let adsGalleryProductVideo = document.getElementsByClassName('ads-image-gallery-product-video');
    if (adsGalleryProductVideo.length > 0 && new URLSearchParams(window.location.search).get('play_video') === 'true') {
      ytPlayer.playVideo(Object.fromEntries(Array.from(adsGalleryProductVideo[0].attributes).map((a) => [a.nodeName, a.value])))
    }
  });

  // this is to resolve "add to cart button" which didn't work after changing article variation on ADP
  import("../components/product_list/responsive_product_box").then(
      ResponsiveProductBoxController => new ResponsiveProductBoxController.default().init());

  const _this = this;
  _this.attach();

  setTimeout(function () {
    $(".inception-teaser").each(function () {
      const inceptionTabs = $(this).find(".nav-tabs a");
      if (inceptionTabs.length > 1) {
        $(inceptionTabs[1]).trigger("click");
        $(inceptionTabs[0]).trigger("click");
      }
    });
  }, 1500);

  this.responsiveHelper = new ResponsiveHelper();
  const $addToCartTeaser = $(".add-to-cart-teaser");
  $addToCartTeaser.on("shown.bs.modal", function () {
    _this.responsiveHelper.republishResizeEvent();
    const $close = $(this).find("button.close");
    const $modal = $(".modal-backdrop");
    copyDataAttribute("tracking-event", $close, $modal);
    copyDataAttribute("tracking-category", $close, $modal);
    copyDataAttribute("tracking-action", $close, $modal);
    copyDataAttribute("tracking-label", $close, $modal);
  });
}

Ads.prototype.submitLeasing = function (e) {
  e.preventDefault();
  const _this = this;
  const $articleForm = $("form#leasing-form").last();

  fixNonpositiveInput($("#leasing_quantity"));

  $.post($articleForm.attr("action"), $articleForm.serialize(), function (data) {
    _this.cart_input.input(data.markup, data.count, data.total);

    if (data.redirectToUrl !== undefined) {
      window.location = data.redirectToUrl;
    }
  });
};

Ads.prototype.attach = function (e) {
  const mouseEnterFunc = function () {
    $(this).closest(".table-row")
        .find(".js-quantity-select>div")
        .css("text-decoration", "underline");
  };
  const mouseLeaveFunc = function () {
    $(this).closest(".table-row")
        .find(".js-quantity-select>div")
        .css("text-decoration", "");
  };

  $(".js-quantity-select")
      .on("mouseenter", mouseEnterFunc)
      .on("mouseleave", mouseLeaveFunc)
      .on('click', function () {
        // remove yellow selected marker and add it to the one clicked
        $('.js-ads-sales-box-price-table .js-quantity-select').removeClass('selected');
        $(this).addClass('selected');

        // change border color of quantity selector to yellow
        const quantityField = $(this).parent().next().find("#quantity");
        quantityField.addClass("success-border-bold").val($(this).data("min-amount"));

        // change prices and savings on salesbox above
        const prevParent = $(this).parent().prev();
        prevParent.find('.js-ads-sales-box-base-price').text(' ' + $(this).data("formatted-base-price"));
        prevParent.find('.js-ads-sales-box-unit-price').text($(this).data("formatted-price"));
        prevParent.find('.js-ads-sales-box-savings-text').text($(this).data("savings-text"));
        prevParent.find('.js-ads-sales-box-piece-price').text($(this).data("formatted-piece-price"));

        // showing custom error interaction for minimum order amount
        const currentInputFieldValue = Number(quantityField.val());
        const minAmount = Number(quantityField.attr("min"));
        const errorMsgEl = $(".js-sibling-error-message");
        const errorMsg = quantityField.attr("data-bv-greaterthan-message");
        if (currentInputFieldValue < minAmount) {
          quantityField.val(minAmount);
          errorMsgEl.html(errorMsg);
        } else {
          errorMsgEl.empty();
        }
      });

  $(".js-change-tabs").on('click', function () {
    $($(this).attr("href")).trigger("click");
  });
};

Ads.prototype.submitSubscription = function (e) {
  e.preventDefault();
  const _this = this;
  const $articleForm = $("#subscription-form");

  fixNonpositiveInput($("#subscripton_quantity"));

  // Aktuelle URL an Form anfügen, falls im Fehlerfall redirect zurück nötig wird
  const currentUrlElement = document.createElement("input");
  currentUrlElement.setAttribute("type", "hidden");
  currentUrlElement.setAttribute("name", "sourceUrl");
  currentUrlElement.setAttribute("value", document.URL);
  $articleForm.append(currentUrlElement);

  $.post($articleForm.attr("action"), $articleForm.serialize(), function (data) {
    if (data.redirectToErrorUrl !== undefined) {
      window.location = data.redirectToErrorUrl;
    }

    _this.cart_input.input(data.markup, data.count, data.total);

    if (data.redirectToUrl !== undefined) {
      window.location = data.redirectToUrl;
    }
  });
};

Ads.prototype.responsive = function (msg, on_off) {
  fixInceptionTeaser();
  this.adsProductImagesGalleryController.responsive(msg, on_off);
};

Ads.prototype.initEventHandler = function () {
  const _this = this;

  // if this is initiated by an AJAX reload, remove the existing handler first
  $(document).off("click", ".add-to-cart-lease");
  $(document).on("click", ".add-to-cart-lease", function (e) {
    e.preventDefault();
    const $leasingQuantityInput = $(this).closest('#leasing-form').find('.js-quantity-field');
    const $leasingQuantityInputValue = Number($leasingQuantityInput.val());
    const $minAmount = Number($leasingQuantityInput.attr("min"));

    $leasingQuantityInputValue < $minAmount ? $leasingQuantityInput.val($minAmount).focus() : _this.submitLeasing(e);
  });

  $(document).off("click", ".add-to-cart-subscribe");
  $(document).on("click", ".add-to-cart-subscribe", function (e) {
    _this.submitSubscription(e);
  });

  if (this.browserDetect.mobile) {
    $(".js-subscription-link")
        .off("click")
        .on('click', function () {
          $("#accordion-subscription-trigger").trigger("click");
        });
  }
};

function fixNonpositiveInput($input) {
  if (parseInt($input.val()) < 1) {
    $input.val(1);
  }
}

function copyDataAttribute(name, $from, $to) {
  $to.data(name, $from.data(name)).attr(name, $from.data(name));
}

function fixInceptionTeaser() {
  $(".inception-teaser > .tab-content > .tab-pane:not(.active)").each(function () {
    $(this).addClass("active");
  });
}
