import $ from "jquery";
import {replaceElementContentWithUrl} from "utils/ajax_handler_helper";
import ResponsiveHelper from "utils/responsive_helper";
import Ads from "../../views/ads";

function createDoneFunction(_this) {
  return function () {
    $(document).trigger("ads-article-content-replaced-by-ajax-result");
    if (true === _this.allVariantsAreShownInBox) {
      _this.showAllVariantsInBox(); // Wenn es schon aufgeklappt ist, so soll es auch aufgeklappt bleiben
    }
    _this.responsiveHelper.republishResizeEvent();
    highlightAttributeDifferences();
    reenableJavascriptAfterAJAX();
  };
}

/**
 * Erzeugt eine Funktion die die Daten neu lädt wenn sich die History ändert.
 * Die History ändert sich bspw. wenn man die Variationsauswahl ändert.
 */
function loadDataOnHistoryChangeFunction(_this, url) {
  const _url = new URL(url);
  _url.searchParams.append("ajax", "true");
  replaceElementContentWithUrl(
      $("#art-detail-page"),
      _url.href,
      createDoneFunction(_this)
  );
}

export default function VariationController() {
  this.allVariantsAreShownInBox = false;
}

function createVariationChangeHandler(_this, articleForm, replaceId) {
  return function (e) {
    var $element = $(e.target);

    // sync select value to selected dropdown value
    if ($element.data("update-value")) {
      $element
          .closest(".js-variant-item")
          .find(".js-variant-select")
          .val($element.data("update-value"));
    }

    var $articleForm = $element.closest(articleForm);
    var variationChangePath = $articleForm.attr("data-variation-change-path");
    var changed = $element.data("variation-change-source");
    var url =
        variationChangePath +
        "?" +
        $articleForm.serialize() +
        "&changed=" +
        changed;

    if ($element.data("hide-scaling-prices")) {
      url += "&" + $element.data("scaling-prices-quantity");
    }

    var updateBrowserUrlWithAjaxResultFunction = function (data) {
      var itemUrl = $(data).data("item-url");
      if (itemUrl !== undefined) {
        updateBrowserUrlWithoutReload(itemUrl);
        changeLanguageUrl(itemUrl);
      }

      reenableJavascriptAfterAJAX();

      createDoneFunction(_this);
    };

    replaceElementContentWithUrl(
        $(replaceId),
        url,
        updateBrowserUrlWithAjaxResultFunction
    );
  };
}

function reenableJavascriptAfterAJAX() {
  new Ads();
}

function changeLanguageUrl(url) {
  $(".language-selection a").each(function () {
    var indexOfSourceMinus = url.lastIndexOf("-");
    var languageHref = $(this).attr("href") || "";
    var indexOfTargetMinus = languageHref.lastIndexOf("-");
    $(this).attr(
        "href",
        languageHref.substring(0, indexOfTargetMinus + 1) +
        url.substring(indexOfSourceMinus + 1, url.length)
    );
  });
}

function updateBrowserUrlWithoutReload(url) {
  window.history.pushState({executeAjaxCall: false}, null, url);
}

function reloadUrl(_this, url) {
  window.history.pushState({executeAjaxCall: true}, null, url);
  loadDataOnHistoryChangeFunction(_this, url)
}

VariationController.prototype.showAllVariantsInBox = function () {
  // Weitere Items anzeigen und Link ausblenden
  $(".jh-variation-box-entry").each(function () {
    var $variantEntry = $(this);
    if ($variantEntry.css("display") === "none") {
      $variantEntry.show();
      // Daten Attribute hinzufügen damit wird den Eintrag später wieder, sofern der Benutzer den
      // more Button erneut drückt, wieder ausblenden können
      $variantEntry.attr("data-hideable-variant", "true");
    }
  });

  this.allVariantsAreShownInBox = true;
  this.updateMoreButtonText();
};

VariationController.prototype.updateMoreButtonText = function () {
  var $button = $("#variant-box-more-link-box").find(".yellow-button-more");
  if (this.allVariantsAreShownInBox) {
    $button.addClass("all-shown");
  } else {
    $button.removeClass("all-shown");
  }
};

VariationController.prototype.hideVariantsInBox = function () {
  // Weitere Items anzeigen und Link ausblenden
  $(".jh-variation-box-entry[data-hideable-variant]").hide();
  this.allVariantsAreShownInBox = false;
  this.updateMoreButtonText();
};

VariationController.prototype.toggleVariantsInBox = function () {
  if (this.allVariantsAreShownInBox) {
    this.hideVariantsInBox();
  } else {
    this.showAllVariantsInBox();
  }
};

VariationController.prototype.init = function () {
  var _this = this;
  this.responsiveHelper = new ResponsiveHelper();

  if ($(window).width() < 767) {
    $(".jh-variation-box-entry").each(function () {
      $(this).hide();
    });
  }

  $(document).on(
      "change",
      "#article-form .article-form-update",
      createVariationChangeHandler(_this, "#article-form", "#art-detail-page")
  );

  $(document).on(
      "click",
      "#article-form .article-form-update .js-variant-list-item",
      createVariationChangeHandler(_this, "#article-form", "#art-detail-page")
  );

  $(document).on(
      "change click",
      "#listing-ads-salesbox-article-form .article-form-update",
      createVariationChangeHandler(
          _this,
          "#listing-ads-salesbox-article-form",
          "#listing-ads-wrapper"
      )
  );

  $(document).on("change", ".jh-variation-select", function () {
    reloadUrl(_this, this.value);
  });

  $(document).on("click", ".jh-variation-box-entry", function (e) {
    var $element = $(this);
    var url = $element.data("url");
    $element.addClass("jh-loading");
    setTimeout(function () {
      $.scrollTo("#art-detail-page");
      reloadUrl(_this, url);
    }, 500);
  });

  $(document).on("click", "#article-form .jh-select-dropdown", function () {
    var url = $(this).attr("data-href");
    reloadUrl(_this, url);
  });

  $(document).on("change", "#article-form .js-variation-select", function (e) {
    var url = $(e.currentTarget).find(":selected").data("href");
    reloadUrl(_this, url);
  });

  $(document).on("change", "#listing-ads-salesbox-article-form .js-variation-select", function (e) {
    var url = $(e.currentTarget).find(":selected").data("href");
    reloadUrl(_this, url);
  });

  $(document).on("click", "#listing-ads-salesbox-article-form .jh-select-dropdown", function (e) {
    var url = $(e.currentTarget).data("href");
    reloadUrl(_this, url);
  });

  $(document).on(
      "click",
      ".jh-variation-box-entry a.loading-anker",
      function (e) {
        e.preventDefault();
      }
  );

  $(document).on("click", "#variant-box-more-link-box", function () {
    _this.toggleVariantsInBox();
  });

  $(document).on("click", ".ads-show-all-variants", function () {
    _this.showAllVariantsInBox();
  });

  highlightAttributeDifferences();
};

function highlightAttributeDifferences() {
  var seperator = ", ";
  $(".ads-variation-dropdown").each(function () {
    var selected = $(this).find(".dropdown-select");
    if ($(this).length === 0 || selected.length === 0) {
      return;
    }
    var attributes = selected.html().split(seperator.trim());
    $(this)
        .find(".jh-select-dropdown")
        .each(function () {
          if (!$(this).hasClass("dropdown-select")) {
            var newHtml = "";
            var itemAttributes = $(this).html().split(seperator.trim());
            var minArrayLength = Math.min(
                attributes.length,
                itemAttributes.length
            );
            for (var i = 0; i < minArrayLength; i++) {
              if (attributes[i].trim() === itemAttributes[i].trim()) {
                newHtml += itemAttributes[i].trim();
              } else {
                newHtml += "<strong>" + itemAttributes[i].trim() + "</strong>";
              }
              if (i < itemAttributes.length - 1) {
                newHtml += seperator;
              }
            }
            if (itemAttributes.length > minArrayLength) {
              newHtml +=
                  "<strong>" +
                  itemAttributes[itemAttributes.length - 1].trim() +
                  "</strong>";
            }
            $(this).html(newHtml);
          }
        });
  });
}

