import $ from "jquery";
import {getAddToCartEventTemplate, getRemoveFromCartEventTemplate, NAMESPACE} from "./events";
import {mapGA4EventWithItem} from "./event_mapper"
import * as TrackingEventPublisher from '../event_publisher'
import {getAllAttributes} from "../tracking_helper";

export const CartListener = () => {

  $(document).off('click.ga4.tracking.add').on('click.ga4.tracking.add', '.js-add-to-cart, .js-reco-submit-btn, .js-move-to-basket', function (e) {
    let $input = $(this).parents('form').find('.js-quantity-field');
    $input = $input.length > 0 ? $input : $(this).parents('.cart-product').find('.js-quantity-field');
    mapAndPublishEvent(getAddToCartEventTemplate(), e.currentTarget, {[NAMESPACE + '-' + 'quantity']: $input.val() ? $input.val() : 1});
  })

  $(document).off('click.ga4.tracking.remove').on('click.ga4.tracking.remove', '.js-product-item-delete, .js-add-to-wishlist', function (e) {
    const $currentTarget = $(e.currentTarget);
    if ($currentTarget.hasClass("js-add-to-wishlist") && !$currentTarget.data("delete-from-basket")) {
      return;
    }
    const $input = $currentTarget.closest(".cart-product-interaction").siblings(".cart-product-amount").find(".js-quantity-field");
    mapAndPublishEvent(
        getRemoveFromCartEventTemplate(),
        e.currentTarget,
        {[NAMESPACE + '-' + 'quantity']: $input ? $input.val() : 1});
  })

  $(document).off('change.ga4.tracking.quantity.input').on('change.ga4.tracking.quantity.input', '.js-quantity-field', function (e) {
    const isBasket = $(this).hasClass('js-basket-quantity-field');

    const actualValue = $(this).val();
    if (actualValue && isBasket) {
      let eventCopy;
      let quantity;
      const previewValue = $(this)[0].dataset.quantity;
      if (actualValue < previewValue) {
        quantity = previewValue - actualValue;
        eventCopy = getRemoveFromCartEventTemplate();
      } else {
        quantity = actualValue - previewValue;
        eventCopy = getAddToCartEventTemplate();
      }

      mapAndPublishEvent(eventCopy, e.currentTarget, {[NAMESPACE + '-' + 'quantity']: quantity});
    }
  });
}

const mapAndPublishEvent = (eventCopy, targetElement, preferentialData = {}) => {
  mapGA4EventWithItem(eventCopy, getAllAttributes(targetElement), preferentialData);
  TrackingEventPublisher.publish(eventCopy);
}