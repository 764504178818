import $ from "jquery";

export default function BrandCategoryTeaser() {
}

const categoryItems = ".categories-container .category-item";
const showMoreBtn = "#show-more-brand-categories";
const showLessBtn = "#show-less-brand-categories";
const sizeCategoryItems = $(categoryItems).length;
var maxItemsToShow = 5;

BrandCategoryTeaser.prototype.init = function () {
  init();

  $(showMoreBtn).on("click", handleShowMore);

  $(showLessBtn).on("click", handleShowLess);

  $(window).on("resize", init);
};

function init() {
  if ($(window).width() < 768) {
    $(categoryItems).not(':lt(' + 5 + ')').hide();
    $(categoryItems + ':lt(' + maxItemsToShow + ')').show();
    if (sizeCategoryItems === $(categoryItems + ':visible').length) {
      $(showMoreBtn).hide();
      $(showLessBtn).show();
    } else {
      $(showMoreBtn).show();
      $(showLessBtn).hide();
    }
  } else {
    $(categoryItems).show();
    $(showMoreBtn).hide();
    $(showLessBtn).hide();
  }
}

function handleShowMore() {
  maxItemsToShow = (maxItemsToShow + 5 <= sizeCategoryItems) ? maxItemsToShow + 5 : sizeCategoryItems;
  $(categoryItems + ':lt(' + maxItemsToShow + ')').show(500);
  if (sizeCategoryItems === $(categoryItems + ':visible').length) {
    $(showMoreBtn).hide();
    $(showLessBtn).show();
  }
}

function handleShowLess() {
  $(categoryItems).not(':lt(' + 5 + ')').hide(500);
  $(showMoreBtn).show();
  $(showLessBtn).hide();
  maxItemsToShow = 5 // reset to initial value
}

