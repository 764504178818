export const NAMESPACE = 'ga4';

export const GA4_VIEW_PROMOTION = 'GA4EC_PromotionView';
export const GA4_VIEW_PROMOTION_NAME = 'view_promotion';
export const GA4_SELECT_PROMOTION = "GA4EC_PromotionClick";
export const GA4_SELECT_PROMOTION_NAME = "select_promotion";

export const GA4_ADD_TO_CART = 'add_to_cart';
export const GA4_REMOVE_FROM_CART = 'remove_from_cart';
export const GA4_ADD_TO_WISHLIST = 'add_to_wishlist';
export const GA4_REMOVE_FROM_WISHLIST = 'remove_from_wishlist';

export const GA4_VIEW_ITEM = 'view_item';
export const GA4_VIEW_ITEM_LIST = 'view_item_list';

export const GA4_SELECT_ITEM = 'GA4EC_ItemListClick';
export const GA4_SELECT_ITEM_NAME = 'select_item';

const cartCommonParams = {
  currency: 'currency',
  value: 'price-numeric',
  accessory: 'is-accessory',
  equipment: 'is-equipment'
};

const itemViewCommonParams = {
  currency: 'currency',
  value: 'price-numeric',
  accessory: 'is-accessory',
  equipment: 'is-equipment'
}

const itemListViewCommonParams = {
  item_list_id: 'item-list-id',
  item_list_name: 'item-list-name'
}

const ADD_TO_CART_EVENT = {
  event: 'GA4EC_AddToCart',
  event_name: GA4_ADD_TO_CART,
  event_params: {
    currency: 'currency',
    value: 'price-numeric',
    accessory: 'is-accessory',
    equipment: 'is-equipment'
  }
}

const REMOVE_FROM_CART_EVENT = {
  event: 'GA4EC_RemoveFromCart',
  event_name: GA4_REMOVE_FROM_CART,
  event_params: cartCommonParams
}

const ADD_TO_WISHLIST_EVENT = {
  event: 'GA4EC_AddFromWishlist',
  event_name: GA4_ADD_TO_WISHLIST,
  event_params: cartCommonParams
}

const REMOVE_FROM_WISHLIST_EVENT = {
  event: 'GA4EC_RemoveFromWishlist',
  event_name: GA4_REMOVE_FROM_WISHLIST,
  event_params: cartCommonParams
}

const VIEW_ITEM_EVENT = {
  event: 'GA4EC_ItemView',
  event_name: GA4_VIEW_ITEM,
  event_params: itemViewCommonParams
}

const VIEW_ITEM_LIST_EVENT = {
  event: 'GA4EC_ItemListView',
  event_name: GA4_VIEW_ITEM_LIST,
  event_params: itemListViewCommonParams
}

const SELECT_ITEM_EVENT = {
  event: GA4_SELECT_ITEM,
  event_name: GA4_SELECT_ITEM_NAME,
  event_params: itemListViewCommonParams
}

const VIEW_PROMOTION_EVENT = {
  event: GA4_VIEW_PROMOTION,
  event_name: GA4_VIEW_PROMOTION_NAME,
  event_params: {
    creative_name: "creative-name",
    creative_slot: "creative-slot",
    promotion_id: "promotion-id",
    promotion_name: "promotion-name"
  }
}

const SELECT_PROMOTION_EVENT = {
  event: GA4_SELECT_PROMOTION,
  event_name: GA4_SELECT_PROMOTION_NAME,
  event_params: {
    creative_name: "creative-name",
    creative_slot: "creative-slot",
    promotion_id: "promotion-id",
    promotion_name: "promotion-name"
  }
}

export const getAddToCartEventTemplate = () => copyTemplate(ADD_TO_CART_EVENT);
export const getRemoveFromCartEventTemplate = () => copyTemplate(REMOVE_FROM_CART_EVENT);
export const getAddToWishlistEventTemplate = () => copyTemplate(ADD_TO_WISHLIST_EVENT);
export const getRemoveFromWishlistTemplate = () => copyTemplate(REMOVE_FROM_WISHLIST_EVENT);
export const getViewItemEventTemplate = () => copyTemplate(VIEW_ITEM_EVENT);
export const getViewItemListEventTemplate = () => copyTemplate(VIEW_ITEM_LIST_EVENT);
export const getSelectItemEventTemplate = () => copyTemplate(SELECT_ITEM_EVENT);
export const getViewPromotionEventTemplate = () => copyTemplate(VIEW_PROMOTION_EVENT);
export const getSelectPromotionEventTemplate = () => copyTemplate(SELECT_PROMOTION_EVENT);


const copyTemplate = (obj) => {
  return JSON.parse(JSON.stringify(obj))
}