import $ from "jquery";
import {PRODUCT_IMPRESSION} from './events';
import {publish} from '../event_publisher';
import {getDataLayerEvents, isInViewport} from "../tracking_helper";

export default function ProductViewTracker() {
}

function trackImpressions() {
  window.dataLayer = window.dataLayer || [];
  const newImpressions = [];
  let currencyCode;

  const oldProductImpressions = getDataLayerEvents(PRODUCT_IMPRESSION);

  $(".js-productimpression").each(function () {
    const $list = $(this);
    let listName = getListName($list)

    $list.find(".js-eecproduct").each(function () {
      const $product = $(this);
      if (!isVisible($product)) {
        return;
      }

      const id = $product.data("productid");
      if (id == null || id.length === 0) {
        return;
      }

      if (wasTrackedBefore(oldProductImpressions, listName, id)) {
        return;
      }

      let index = $product.data("index");
      if (typeof index == "number") {
        index++; // increment to make tracking value start at 1 instead of 0
      }

      const productImpression = {
        "name": $product.data("productname"),
        "id": "" + id,
        "price": "" + $product.data("price"),
        "brand": $product.data("brand"),
        "category": $product.data("category"),
        "list": listName,
        "position": index
      };

      currencyCode = $product.data("currency");
      newImpressions.push(productImpression);
    });
  });

  if (newImpressions.length > 0) {
    const entry = {
      "event": 'EECproductImpression',
      "ecommerce": {
        "currencyCode": currencyCode,
        "impressions": newImpressions
      }
    };
    publish(entry);
  }
}

function trackImpressionsForGA4() {

}

function wasTrackedBefore(dataLayerEntries, listName, productId) {
  for (const entry of dataLayerEntries) {
    const oldImpressions = entry.ecommerce.impressions;
    for (const impression of oldImpressions) {
      if (impression.id != null && impression.id === "" + productId
          && impression.list != null && impression.list === listName) {
        return true;
      }
    }
  }

  return false;
}

function getListName($list) {
  let listName = $list.data("list");
  const obj = window.dataLayer[0];
  if (obj !== undefined) {
    const breadcrumbs = obj.breadcrump;
    if (breadcrumbs !== undefined
        && (listName === "StageTeaser"
            || listName === "TripleProductTeaser"
            || listName === "Productlisting")) {
      listName = listName + breadcrumbs.replace(/\//g, "_");
      if (listName.slice(-1, listName.length) === "_") {
        listName = listName.slice(0, -1);
      }
    }
  }

  return listName;
}

function isVisible($product) {
  let isActive = true;
  if ($product.parent().hasClass("slide")) {
    const index = $product.parent().data("index");
    const indicator = $product.closest(".swipe").find(".carousel-indicators li")[index];
    if (indicator !== undefined) {
      isActive = $(indicator).hasClass("active");
    }
  } else if ($product.parent().hasClass("slide-wrap")) { // für den Fall, dass der Slider noch nicht initialisiert ist
    isActive = false;
  }

  const productVisible = $product.height() > 0;
  const inViewport = isInViewport($product, true);
  return productVisible && inViewport && isActive;
}

ProductViewTracker.prototype.init = function () {
  setInterval(trackImpressions, 1000);
  setInterval(trackImpressionsForGA4, 1000);
}
