import pushToRecommenderQueue from "utils/emarsys_reco_queue";
import SalesBoxCartButtonController from "components/sales_box/sales_box_cart_button_controller";
import YoutubePlayer from "libs/youtube";


export default class EmarsysRecoTeaser {

  initPageRecoTeaser = () => {
    pushToRecommenderQueue(document.querySelectorAll('.js-page-teaser-emarsys-reco'), this.showTeaser)
  }

  initAddToCartRecoTeaser = () => {
    pushToRecommenderQueue(document.querySelectorAll('.js-add-to-cart-emarsys-reco'), this.showTeaser)
  }

  showTeaser = (result) => {
    if (result.html && result.html.length > 0) {
      let teaserParentContainer = document.getElementById(result.teaserId);
      teaserParentContainer.innerHTML = result.html;
      teaserParentContainer.parentNode.classList.remove("hide-container");
      new SalesBoxCartButtonController().init();

      if (teaserParentContainer.getElementsByClassName("video-play-button").length !== 0) {
        new YoutubePlayer();
      }
    }
  }
}