/**
 * Kümmert sich um die SalesBox wenn sie Standalone (also nicht auf der ADS) eingesetzt wird.
 *
 * Auf der ADS darf dieses Javascript NICHT ausgeführt werden, da dort andere Anforderungen bestehen.
 * Beispielsweise muss dort ein ändern der Variationsauswahl zu einer Änderung der URL führen.
 */
import $ from "jquery";
import SalesBoxCartButtonController from "components/sales_box/sales_box_cart_button_controller";
import {replaceElementContentWithUrl} from "utils/ajax_handler_helper";
import Ads from "../../views/ads";

function createVariationChangeHandler() {
  return function (e) {
    var $element = $(e.target);
    // sync select value to selected dropdown value
    if ($element.data("update-value")) {
      $element
        .closest(".js-variant-item")
        .find(".js-variant-select")
        .val($element.data("update-value"));
    }

    e.preventDefault();
    var $articleForm = $element.closest("#standalone-salesbox-article-form");
    var variationChangePath = $articleForm.attr("data-variation-change-path");
    var dropdown = $(this).parents("jh-select-dropdown");
    if (dropdown !== undefined) {
      var itemId = $(this).attr("data-itemId");
      if (typeof itemId != "undefined") {
        var $articleIdInput = $articleForm.find("input[name='itemId']");
        $articleIdInput.val(itemId);
        $articleForm.append(
          '<input type="hidden" name="itemChange" value="true" />'
        );
      }
    }
    var url = variationChangePath + "?" + $articleForm.serialize();

    var $boxcontainer = $(".fancybox-slide--current");
    replaceElementContentWithUrl(
      $boxcontainer,
      url,
      function () {
        $(document).trigger("jungheinrich-standalone-sales-box-was-replaced");
        enableJSFunctionalityAfterAJAX();
      }
    );
  };
}

function enableJSFunctionalityAfterAJAX() {
  new Ads();
}

function changeQuantityAmount() {
  return function () {
    // showing custom error interaction for minimum order amount
    const inputEl = $(this).parent().next().find("#quantity");
    const errorMsgEl = $(this).parent().next().find(".js-sibling-error-message");
    const selectedGraduatedPrice = Number($(this).data("min-amount"));
    const minAmount = Number(inputEl.attr("min"));
    const errorMsg = inputEl.attr("data-bv-greaterthan-message");

    if (selectedGraduatedPrice < minAmount) {
      inputEl.val(minAmount);
      errorMsgEl.html(errorMsg);
    } else {
      inputEl.val($(this).data("min-amount"));
      errorMsgEl.empty();
    }

    inputEl.addClass("success-border-bold");
  };
}

export default function StandaloneSalesBoxController() {
}

StandaloneSalesBoxController.prototype.init = function () {
  new SalesBoxCartButtonController().init();
  $(document).on(
    "change",
    "#standalone-salesbox-article-form .article-form-update",
    createVariationChangeHandler(this)
  );
  $(document).on(
    "click touchend",
    "#standalone-salesbox-article-form .jh-select-dropdown",
    createVariationChangeHandler(this)
  );
  $(document).on(
    "click",
    "#standalone-salesbox-article-form .js-variant-list-item",
    createVariationChangeHandler(this)
  );

  $(document).on("click", ".js-quantity-select", changeQuantityAmount());
};
