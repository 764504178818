/**
 * Responsive Zoom Plugin
 */

import $ from "jquery";

const defaults = {}

export default function Zoom(el, options) {
  this.$el = el;
  this.options = options;
  this.container = null;
  this.$zoomcontainer = this.options.zoomcontainer ? this.options.zoomcontainer : this.$el;
  this.init();
}

Zoom.prototype = {
  init: function () {
    $("img", this.$el).off("mouseover.zoom").on("mouseover.zoom", this.hover.bind(this));
  },
  kill: function () {
    this.out({pageX: -1, pageY: -1});
    $("img", this.$el).off("mouseover");
  },
  move: function (e) {
    var localPoint = [e.pageX - this.current_img.offset().left, e.pageY - this.current_img.offset().top];
    var bigPoint = [localPoint[0] * this.ratio, localPoint[1] * this.ratio];
    var centered_left = -bigPoint[0] + this.$zoomcontainer.width() / 2;

    if (centered_left > 0) centered_left = 0;
    else if (centered_left < this.$zoomcontainer.width() - this.current_big_img_size.width - 30) centered_left = this.$zoomcontainer.width() - this.current_big_img_size.width - 30;

    var centered_top = -bigPoint[1] + this.current_img_size.height / 2;
    if (centered_top > 0) centered_top = 0;
    else if (centered_top < this.current_img_size.height - this.current_big_img_size.height + 50) centered_top = this.current_img_size.height - this.current_big_img_size.height + 50;

    var rect = $(".zoom-rect", this.$el);
    var rect_x = localPoint[0] - rect.width() / 2;
    var rect_y = localPoint[1] - rect.height() / 2;
    rect_x = Math.max(rect_x, 0);
    rect_x = Math.min(rect_x, this.current_img_size.width - rect.outerWidth());
    rect_y = Math.max(rect_y, 0);
    rect_y = Math.min(rect_y, this.current_img_size.height - rect.outerHeight());

    rect.css({left: rect_x, top: rect_y});

    this.current_big_img.css({left: centered_left + "px", top: centered_top + "px"})
  },
  out: function (e) {
    if (!this.container) return;
    $('.zoom-icon.on-ads').show();

    if (!this.checkBounds(e.pageX, e.pageY)) {
      this.container.find("img").remove();

      $(".zoom-container", this.$zoomcontainer).remove();
      $(".zoom-rect, .pad", this.$el).remove();

      $(".pad", this.$el).off("mousemove");
      $(".pad", this.$el).off("mouseout");

      this.$el.trigger("zoom.remove");
      this.inside = false;
    }
  },
  hover: function (e) {
    if (this.inside || this.$el.hasClass("too-small")) return;
    $('.zoom-icon.on-ads').hide();

    this.hoverE = e;
    this.inside = true;
    this.current_img = $(e.target);
    this.current_img_size = {width: this.current_img.width(), height: this.current_img.height()}

    var fill_css = {
      position: "absolute",
      top: 0,
      left: 0,
      width: this.$el.width(),
      height: this.$el.height(),
      zIndex: 199
    };
    var pad = $('<div class="pad">').css(fill_css);
    this.$el.append(pad);

    fill_css.zIndex = 197;
    fill_css.width = this.$zoomcontainer.width();
    this.container = $('<div class="zoom-container">').css(fill_css);
    this.$zoomcontainer.append(this.container);

    this.$el.trigger("zoom.add");

    this.loadImg(this.current_img.attr("data-bigimage"), this.imgLoaded);

    const _this = this;
    $(".pad", this.$el).on("mouseout", this.out.bind(this)).on("mousemove", function (e) {
      _this.hoverE = e;
    });
  },
  loadImg: function (url, callback) {
    this.container.addClass("loading");

    var img = document.createElement("img");
    const _this = this;
    $(img).on("load", function () {
      callback.call(_this, $(img));
    });
    img.src = url;
  },
  imgLoaded: function ($img) {
    this.container.removeClass("loading");
    if (!this.inside) return;

    this.container.find("img").remove();
    $img.css("position", "absolute");

    this.current_big_img = $img;
    this.container.append($img);

    this.current_big_img_size = {width: $img.width(), height: $img.height()};
    this.ratio = this.current_big_img_size.width / this.current_img_size.width;

    var zoom_rect = $('<div class="zoom-rect">').css({
      width: this.container.width() / this.ratio,
      height: this.container.height() / this.ratio,
      position: "absolute",
      zIndex: 198
    });

    if (this.$el.find(".zoom-rect").length === 0) this.$el.append(zoom_rect);

    $(".pad", this.$el).off("mousemove").on("mousemove", this.move.bind(this));
    this.move(this.hoverE);

    this.$el.trigger("zoom.loaded");
  },
  // check if point is inside the zoomed image
  checkBounds: function (x, y) {
    if (!this.current_img) return false;

    return (x > this.current_img.offset().left && x < this.current_img.offset().left + this.current_img.width() && y > this.current_img.offset().top && y < this.current_img.offset().top + this.current_img.height());
  }
}

$.fn.zoom = function (options) {
  options = $.extend(defaults, options);

  var matched = $(this);

  matched.each(function () {
    var zoom = new Zoom($(this), options);
    $(this).data("Zoom", zoom);
  });

  return matched;
}
