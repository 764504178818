import $ from "jquery";

const emarsysRecoConfig = {
  EMARSYS_LIBRARY_URL: window.shopConfig.emarsysRecoLibraryUrl,
  EMARSYS_SHOP_RECO_TEASER_URL: window.shopConfig.emarsysShopRecoTeaserUrl,
  EMARSYS_SCRIPT_ID: 'scarab-js-api',
  EMARSYS_SCRIPT_GO_COMMAND_ID: 'emarsys-scarab-queue-go-command',
  EMARSYS_QUEUE_LOGIC: 'recommend'
}

export default function pushToRecommenderQueue(recoContainers, successRecoCallback) {
  return _loadEmarsysLibrary()
      .then(() => _waitForQueueLoaded())
      .then(() => _pushToQueue(recoContainers, successRecoCallback))
      .then(() => window.ScarabQueue.push(["go"]));
}

const _loadEmarsysLibrary = () => {
  return new Promise((resolve) => {
    if (!document.getElementById(emarsysRecoConfig.EMARSYS_SCRIPT_ID)) {
      const scriptTag = document.createElement('script');
      scriptTag.id = emarsysRecoConfig.EMARSYS_SCRIPT_ID;
      scriptTag.src = emarsysRecoConfig.EMARSYS_LIBRARY_URL;
      document.querySelector('script').before(scriptTag);
    }
    resolve();
  })
}

const _waitForQueueLoaded = (timeout = 10000) => {
  return new Promise((resolve, reject) => {
    if (window.ScarabQueue) {
      resolve();
    } else {
      let scarabQueueObjectObserver = new MutationObserver(function () {
        if (window.ScarabQueue) {
          scarabQueueObjectObserver.disconnect();
          resolve();
        }
      });
      scarabQueueObjectObserver.observe(document, {childList: true, subtree: true});
      setTimeout(() => {
        scarabQueueObjectObserver.disconnect();
        reject(new Error("Emarsys ScarabQueue failed to initialize within the specified timeout."));
      }, timeout);
    }
  })
}

const _pushToQueue = (recoContainers, successRecoCallback) => {
  window.ScarabQueue = window.ScarabQueue || [];
  recoContainers.forEach((container) => {
    let params = {
      'logic': container.dataset.emarsysRecoLogic,
      'maxNumberOfProducts': container.dataset.emarsysMaxProducts,
      'teaserId': container.id
    }
    window.ScarabQueue.push([emarsysRecoConfig.EMARSYS_QUEUE_LOGIC, {
      logic: params.logic,
      limit: params.maxNumberOfProducts,
      containerId: params.teaserId,
      success: function (SC) {
        _getShopHtml(SC, params, successRecoCallback);
      },
      error: function () {
        new Error("could not get recommendation");
      }
    }]);
  })
}

const _getShopHtml = (emarsysData, params, successRecoCallback) => {
  let payload = {
    'recommendedArticleIds': emarsysData.page.products.map(product => product.id),
    'teaserId': params.teaserId
  };

  $.ajax({
    url: emarsysRecoConfig.EMARSYS_SHOP_RECO_TEASER_URL,
    type: 'POST',
    data: JSON.stringify(payload),
    contentType: 'application/json; charset=utf-8',
    dataType: 'html',
    success: function (data) {
      successRecoCallback({
        ...params,
        'html': data
      })
    },
    error: function () {
      new Error("could not load html recommendation");
    }
  })
}