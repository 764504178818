import $ from "jquery";

export default function NavigationHeaderController() {
}

NavigationHeaderController.prototype.init = function () {

  function pushEvent(element) {
    window.dataLayer.push({
      'event': 'event',
      'eventCategory': 'navigation',
      'eventAction': 'header',
      'eventLabel': $(element).data("event-label")
    });
  }

  $('#jh-header .logo').on('click', function () {
    pushEvent('#jh-header .logo');
  });

  $('#jh-header .logo-rabatt').on('click', function () {
    pushEvent('#jh-header .logo-rabatt');
  });

  $('#jh-header .jh-consult-phone').on('click', function () {
    pushEvent('#jh-header .jh-consult-phone');
  });

  $('#jh-header .jh-shipping-info').on('click', function () {
    pushEvent('#jh-header .jh-shipping-info');
  });

  $('#jh-header .safety-buy-icon').on('click', function () {
    pushEvent('#jh-header .safety-buy-icon');
  });

  $(".header-menu-element-flyout").mouseenter(function () {
    $(".header-menu-element-category").removeClass("hovered");
    $($(this).data("menu-parent")).addClass("hovered");

  }).mouseleave(function () {
    $(".header-menu-element-category").removeClass("hovered");
  });

  const config = {
    selectors: {
      assortmentArrow: '.js-nav-assortment-arrow',
      assortmentFlyout: '.js-nav-assortment-flyout',

      assortmentCategory: '.js-nav-assortment-category',
      assortmentCategoryWrapper: '.js-nav-assortment-category-wrapper'
    },
    modifiers: {
      arrowSelected: 'header-nav__menu-assortment-arrow--selected',
      flyoutSelected: 'header-menu-element-flyout--selected'
    }
  }

  $(config.selectors.assortmentCategory).on({
    mouseover: function () {
      $(this).find(config.selectors.assortmentArrow).addClass(config.modifiers.arrowSelected);
      const navElement = $(this).data('navigationElement');
      $(this).parent().siblings(`${config.selectors.assortmentFlyout}[data-navigation-element="${navElement}"]`).addClass(config.modifiers.flyoutSelected);
    },

    mouseleave: function () {
      const navElement = $(this).data('navigationElement');
      $(this).parent().siblings(`${config.selectors.assortmentFlyout}[data-navigation-element="${navElement}"]`).removeClass(config.modifiers.flyoutSelected);

      let selectedFlyout = $(this).parent().siblings(`${config.selectors.assortmentFlyout}[data-navigation-element="${navElement}"]`).hasClass(config.modifiers.flyoutSelected);

      if (!selectedFlyout) {
        $(this).find(config.selectors.assortmentArrow).removeClass(config.modifiers.arrowSelected)
      }
    }
  });

  $(config.selectors.assortmentFlyout).on({
    mouseover: function () {
      $(this).addClass(config.modifiers.flyoutSelected);
      const catWrapper = $(this).siblings(config.selectors.assortmentCategoryWrapper);
      const navElement = $(this).data('navigationElement');
      const targetEl = catWrapper.find(`[data-navigation-element="${navElement}"] > ${config.selectors.assortmentArrow}`);
      targetEl.addClass(config.modifiers.arrowSelected);
    },

    mouseleave: function () {
      $(this).removeClass(config.modifiers.flyoutSelected);
      const catWrapper = $(this).siblings(config.selectors.assortmentCategoryWrapper);
      const navElement = $(this).data('navigationElement');
      const targetEl = catWrapper.find(`[data-navigation-element="${navElement}"] > ${config.selectors.assortmentArrow}`);
      targetEl.removeClass(config.modifiers.arrowSelected);
    }
  });
};
