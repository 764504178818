import $ from 'jquery';
import '@fancyapps/fancybox';

export default function AdsAccordionController() {
}

function animateOut(currentTabBody, duration) {
  const openClass = "accordion--open";

  currentTabBody.fadeOut(duration);

  //toggle the open class
  $(currentTabBody).removeClass(openClass);
  $(currentTabBody)
      .siblings(".accordion__label." + openClass)
      .removeClass(openClass);

  //remove style changes after animation is done
  setTimeout(function () {
    currentTabBody.css({
      position: "",
      display: "",
      top: "",
      transitionDuration: "",
      transform: "",
    });
  }, duration);
}

function animateIn(targetTabLabel, targetTabBody, duration) {
  const openClass = "accordion--open";

  targetTabBody.fadeIn(duration);

  //toggle classes to activate
  $(targetTabBody).addClass(openClass);
  $(targetTabLabel).addClass(openClass);

  //wait one Frame to change the styles so the frame moves in view again
  requestAnimationFrame(function () {
    targetTabBody.css({
      transitionDuration: duration + "ms",
      transform: "translateX(0)",
    });
  });

  //remove style changes after animation is done
  setTimeout(function () {
    targetTabBody.css({
      transitionDuration: "",
      transform: "",
    });
  }, duration);
}

//prevent a second animation if there is still one in progress
let animationInProgress = false;

function toggleActiveTabOnDesktop(event) {
  const targetTabLabel = $(event.target);
  const accordionLbl = targetTabLabel.attr("data-accordion-label-for")
  const targetTabBody = $(
      ".accordion__tab-content[data-accordion-id=" + accordionLbl + "]"
  );
  const currentTabBody = $(".accordion__tab-content.accordion--open");
  const container = $(".js-prevent-scrollbar");

  if (targetTabBody[0] === currentTabBody[0]) {
    return;
  }

  if (animationInProgress) return;

  //check direction
  const tabAnimationDuration = 50;

  //start animation
  animationInProgress = true;

  //prevents showing a scoll bar while animating
  container.css("overflow-y", "hidden");

  animateOut(currentTabBody, tabAnimationDuration);

  animateIn(targetTabLabel, targetTabBody, tabAnimationDuration);

  //end animation
  setTimeout(function () {
    container.css("overflow-y", "");
    animationInProgress = false;
  }, tabAnimationDuration);
}

function toggleActiveTabOnMobile(event) {
  const targetTabLabel = $(event.target);
  const accordionLbl = targetTabLabel.attr("data-accordion-label-for")
  const targetTabBody = $(
      ".accordion__tab-content[data-accordion-id=" + accordionLbl + "]"
  );

  const openClass = "accordion--open";

  //check for animation direction => open or close
  //if it has the open class, its going to get closed
  const accordionIsClosing = targetTabBody.hasClass(openClass);
  const currentHeight = targetTabBody.height();
  //open
  if (accordionIsClosing) {
    //animatate the height to 0
    targetTabBody.animate({height: 0}, 200, function () {
      //and hide the element afterwards
      $(targetTabBody).removeClass(openClass);
      $(targetTabLabel).removeClass(openClass);

      //remove element style traces
      targetTabBody.css("height", "");
      targetTabBody.height("");
    });
  } else {
    $(targetTabBody).addClass(openClass);
    $(targetTabLabel).addClass(openClass);
    //set height to 0 after adding open class
    targetTabBody.css("height", "0");

    //animate Height
    targetTabBody.animate({height: currentHeight}, 200, function () {
      //remove element style traces
      targetTabBody.css("height", "");
      targetTabBody.height("");
    });
  }
}

function removeIosScrollbars() {
  if (navigator.userAgent.match(/(iPhone|iPod|iPad)/i)) {
    $('html, body').toggleClass('ios-prevent-scrolling');
  }
}

function toggleFancyboxOnMobile(event) {
  const mobileFancyboxHandler = $(event.target).closest(".js-trigger-mobile-ads-content")
  const inlineTrigger = mobileFancyboxHandler.attr("data-mobile-ads-source")
  let scrollToCurrentPosition;

  const options = {
    baseClass: "fancybox--mobile-fullscreen",
    touch: false,
    clickOutside: false,
    clickSlide: false,
    afterShow: function() {
      $.fancybox.defaults.animationEffect = true;
      scrollToCurrentPosition = window.pageYOffset;
      removeIosScrollbars();
      setTimeout(() => {
        $("#ads__technical-details").scrollTop(0);
      }, 50);
    },
    afterClose: function() {
      removeIosScrollbars();
      if (scrollToCurrentPosition != null) {
        window.scrollTo(0, scrollToCurrentPosition);
      }
    }
  }

  $.fancybox.defaults.animationEffect = false;
  $.fancybox.open({
    src: inlineTrigger,
    type: "inline",
    opts: options,
  });
}

function fixTabsOnResize() {
  const isMobile = window.innerWidth < 991;
  //if the screen size changes from mobile-tablet to desktop, there might be the case of a) multiple open classes or none
  if (isMobile) return;

  const currentTabBody = $(".accordion__tab-content.accordion--open");
  const currentTabLabel = $(".accordion__label.accordion--open");
  const allTabBody = $(".js-get-accordion-content");
  const allTabLabel = $(".js-get-accordion-label");
  const openClass = "accordion--open";

  if (currentTabLabel.length > 1) {
    //multiple open classes, every tab but the first one gets closed
    currentTabLabel.each(function (index) {
      if (index >= 1) {
        $(this).removeClass(openClass);
      }
    });
    currentTabBody.each(function (index) {
      if (index >= 1) {
        $(this).removeClass(openClass);
      }
    });
  } else if (allTabBody && currentTabLabel.length < 1) {
    //no tab is open, so the first gets opened
    allTabBody[0].addClass(openClass);
    allTabLabel[0].addClass(openClass);
  }
}

function lazyLoadProductCatalogue() {
  const image = $(".js-load-catalogue-lazy");
  image.attr("src", image.data("image-src"));
}

function calculateIfShowMoreTechBtnShouldBeDisplayed(isMobile) {
  let $accordionTable = $(".accordion__container .technical-details .tech-table");
  if (!isMobile) {
    let maxTechTableHeight = parseInt($accordionTable.height());
    if (maxTechTableHeight > 260) {
      $accordionTable.addClass('mh-260');
      $('.technical-details .panel-fade-wrap').show();
    }
  } else {
    $("panel-fade-wrap").hide();
  }
}

const initAdsAccordion = (openClass) => {
  $(".js-get-accordion-label").first().addClass(openClass);
  $(".js-get-accordion-content").first().addClass(openClass);
}

AdsAccordionController.prototype.init = function () {
  const tabLabel = $(".js-toggle-accordion-from-label");
  const mobileFancyboxTrigger = $(".js-trigger-mobile-ads-content")
  const isMobile = window.innerWidth < 991;

  initAdsAccordion("accordion--open");

  mobileFancyboxTrigger.off("click", toggleFancyboxOnMobile).on("click", toggleFancyboxOnMobile);
  tabLabel.off("click").on("click", function () {

    isMobile
        ? toggleActiveTabOnMobile(event)
        : toggleActiveTabOnDesktop(event);
  });

  calculateIfShowMoreTechBtnShouldBeDisplayed(isMobile);

  let debounce;
  $(window).on("resize", function () {
    clearTimeout(debounce);
    debounce = setTimeout(fixTabsOnResize, 300);
  });

  lazyLoadProductCatalogue();
  $(document).ajaxComplete(function () {
    lazyLoadProductCatalogue();
  });
};
