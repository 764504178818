/*jslint unused: false*/
//noinspection JSHint
import Cookies from 'js-cookie';

export default function LocalStorageHelper() {
}

LocalStorageHelper.prototype.getValue = function (key) {
  if (typeof (Storage) !== "undefined") {
    try {
      return localStorage.getItem(key);
    } catch (e) {
    }
  }
  var cookie = Cookies.get(key);
  return cookie === undefined ? null : cookie;
};

LocalStorageHelper.prototype.setValue = function (key, value) {
  if (typeof (Storage) !== "undefined") {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      Cookies.set(key, value);
    }
  } else {
    Cookies.set(key, value);
  }
};

LocalStorageHelper.prototype.removeValue = function (key) {
  if (typeof (Storage) !== "undefined") {
    try {
      return localStorage.removeItem(key);
    } catch (e) {
      Cookies.remove(key);
    }
  } else {
    Cookies.remove(key);
  }
};

