/*jslint unused: false*/
//noinspection JSHint
import $ from "jquery";

/**
 * Kann in AJAX Handler benutzt werden um sich davor zu schützen, dass man die Fehlerseite irgendwo reinpastet. Dafür
 * muss man prüfen, ob es sich bei dem HTML Daten die zurückkommen wirklich um AJAX Daten handeln kann.
 */
export function dataIsNotAjaxResult(data) {
  var hasIndexOfFunction = typeof data.indexOf === "function";
  return hasIndexOfFunction && data.indexOf("<html>") >= 0;
}

/**
 * True wenn das AJAX Ergebnis aussagt, dass die aktuelle Seite neu geladen werden soll
 */
export function shouldReloadCurrentUrl(data) {
  return data.trim() === "";
}

export function reloadCurrentUrl() {
  // trigger ist asynchron. Alle Handler sollen aber reagiert haben bevor wir die Seite neu laden.
  // Daher führen wir hier die trigger Methode synchron aus
  $.when($(document).trigger({type: "jungheinrich-before-reload"}))
    .then(function () {
      window.location.reload();
    });
}

/**
 * True wenn das AJAX Ergebnis aussagt, dass ein redirect stattfinden soll
 */
export function shouldRedirect(data) {
  var redirect = $(data).attr('data-redirect-url');
  if (redirect) {
    return true;
  }
  return false;
}

export function performRedirect(data) {
  var redirectUrl = $(data).attr('data-redirect-url');
  window.location.replace(redirectUrl);
}

/**
 * Lädt Daten per AJAX und ersetzt eine DIV auf der Seite mit dem Ergebnis des AJAX Calls. Liefert der AJAX Call
 * nichts zurück, so wird die Seite neu geladen.
 *
 * @param $container der Container der ersetzt werden soll
 * @param url Die Url die geladen werden soll
 * @param ajaxFinishedFunction Diese Funktion wird aufgerufen, wenn der AJAX Call behandelt wurde (egal ob
 *                             erfolgreich oder nicht). Diese bekommt als ersten Parameter die erhalten Daten übergeben.
 */
export function replaceElementContentWithUrl($container, url, ajaxFinishedFunction) {
  ajaxFinishedFunction = ajaxFinishedFunction || function () {
  };

  if (typeof url === "undefined") {
    return;
  }
  $.ajax({
    cache: false,
    url: url,
    data: {},
    dataType: "html"
  }).done(function (data) {
    if (shouldReloadCurrentUrl(data)) {
      reloadCurrentUrl();
      return;
    }
    if (shouldRedirect(data)) {
      performRedirect(data);
      return;
    }
    if (dataIsNotAjaxResult(data)) {
      // Kommt bspw. die Fehlerseite zurück und kein richtiges AJAX Ergebnis
      // so löschen wir lieber den Warenkorb Flyout. Er zeigt dann ja kein
      // gültiges Ergebnis mehr an
      $container.html("");
    } else {
      // see http://www.joezimjs.com/javascript/jquery-html-youre-doing-it-wrong/
      var html = $.parseHTML(data, true);
      $container.empty().append(html);
    }
    ajaxFinishedFunction(data);
  }).fail(function (error) {
    // Kommt bspw. die Fehlerseite zurück und kein richtiges AJAX Ergebnis
    // so löschen wir lieber den Warenkorb Flyout. Er zeigt dann ja kein
    // gültiges Ergebnis mehr an
    $container.html("");
    ajaxFinishedFunction("");
  });
}

