/*jslint unused: false*/
import $ from 'jquery';
import Cookies from 'js-cookie'

var setupLanguageCookieListeners = function ($rootElem) {
  $rootElem.on('click', '.cookie-language-link', function () {
    var language = $(this).attr('data-language');
    if (language !== undefined) {
      Cookies.set('shop-language', language, {
        expires: 100,
        path: '/'
      });
    }
  });
}
setupLanguageCookieListeners($(document));
