import PubSub from 'pubsub-js';

var lastMessage;
var lastOnOff;

export default function ResponsiveHelper() {
  PubSub.subscribe('res', function (message, on_off) {
    lastMessage = message;
    lastOnOff = on_off;
  });
}

ResponsiveHelper.prototype.republishResizeEvent = function () {
  PubSub.publish(lastMessage, lastOnOff);
};


