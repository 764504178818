/**
 * Scrollt mobile zu dem aufgeklappten vertikal aufklappbaren Akkordion auf der ADS.
 */

import $ from "jquery";
import {isCurrentResolutionLowerOrEqual} from 'components/resolutions.js';

export default function CollapseScroll() {
}

CollapseScroll.prototype.init = function () {
  var initOnReady = function () {
    $(document).on('shown.bs.collapse', function (e) {
      if (isCurrentResolutionLowerOrEqual("res.sm")) {
        $('html,body').animate({
          scrollTop: $('.panel-collapse.in').offset().top - 40
        }, 250);
      }
    });
  };
  $(document).ready(initOnReady);
};


