import $ from "jquery";
import BrowserDetect from "../../utils/browserdetect";
import {initLazyLoad} from '../lazyload'

const errorValidationClasses = "custom-has-error has-error has-error--yellow";
const successValidationClasses = "is-correct has-success";
const $wishlistInputField = $(".js-update-wishlist-field");
const $quantityField = $('.js-quantity-field');
const browserDetect = new BrowserDetect();
browserDetect.init();
const isIE = browserDetect === "Explorer";


const initListeners = () => {
  initLazyLoad();

  $(document).off("click.plus.minus");
  $(document).off("keyup.plus.minus");
  $(document).off("blur.plus.minus");

  $(document).on("click.plus.minus", '.number-input-extra-button.label-minus-icon', function (e) {
    const input = $(this).parent().children('.js-quantity-field');
    const isManualClick = e.originalEvent.detail !== 0;
    const isManualClickOrIe = isManualClick || isIE;
    input.removeClass("success-border-bold");

    if (input.val() > 1 && isManualClickOrIe) {
      const newNumber = Number(input.val()) - 1;
      const minAmount = Number(input.attr("min"));

      if (newNumber < minAmount) {
        input.val(minAmount);
        errorMessageHandler(input, true);
        input.parent().addClass(errorValidationClasses).removeClass(successValidationClasses);
      } else {
        input.val(newNumber);
        input.parent().addClass(successValidationClasses).removeClass(errorValidationClasses);
        if (minAmount > 1) {
          errorMessageHandler(input, false);
        }
      }

      input.trigger("change", initListeners);
      ie8ButtonDisabledFix();
      selectQuantityAmount(newNumber, input);
    }
  });

  $(document).on("click.plus.minus", '.number-input-extra-button.label-plus-icon', function () {
    const input = $(this).parent().children('.js-quantity-field');
    const newNumber = Number(input.val()) + 1;
    const minAmount = Number(input.attr("min"));
    const adjustedNewNumber = newNumber < minAmount ? minAmount : newNumber;
    input.removeClass("success-border-bold");
    input.val(adjustedNewNumber);
    ie8ButtonDisabledFix();
    selectQuantityAmount(adjustedNewNumber, input);

    const parentEl = input.parent();
    const isLeasing = parentEl.hasClass("leasing-form__input-wrapper");

    if (!isLeasing) {
      if (adjustedNewNumber === minAmount) {
        parentEl.addClass(errorValidationClasses).removeClass(successValidationClasses);
        errorMessageHandler(input, true);
      }

      if (adjustedNewNumber > minAmount) {
        parentEl.addClass(successValidationClasses).removeClass(errorValidationClasses);
        errorMessageHandler(input, false);
      }
    }

    input.trigger("change", initListeners);
  });

  $(document).on("blur.plus.minus", $quantityField, function (e) {
    const currentInputValue = Number($(this).val());
    const minAmount = Number($(this).attr("min"));
    $(this).removeClass("success-border-bold");

    if (minAmount > 1 && currentInputValue < minAmount) {
      errorMessageHandler($(this), true);
    }

    if (currentInputValue > minAmount) {
      errorMessageHandler($(this), false);
    }

    const isBasket = $(this).hasClass('js-basket-quantity-field');
    if (!isBasket) {
      correctMinAmount($(this), e);
      selectQuantityAmount($(this).val(), $(this));
    }

  });

  $(document).on("keyup.plus.minus", $quantityField, function (e) {
    const enteredAmount = Number($(this).val());
    const minAmount = Number($(this).attr("min"));

    if (enteredAmount > minAmount) {
      errorMessageHandler($(this), false);
      correctMinAmount($(this), e);
    }

    if (e.which === 13) {
      const input = $(this);

      if (minAmount > 1 && enteredAmount < minAmount) {
        errorMessageHandler($(this), true);
      }

      correctMinAmount($(this), e);
      selectQuantityAmount(input.val(), input);
      input.trigger("change", initListeners);

    }
  });

  $(document).on("blur.plus.minus", $quantityField, function () {
    $(this).trigger("change", initListeners);
  });
}


const selectQuantityAmount = (number, el) => {

  // check if it is Staffelpreise / graduated prices
  const isGraduatedPrices = $(el).closest('.row').find('.js-ads-sales-box-price-table')
  if (isGraduatedPrices) {

    const amountArray = $('.js-quantity-select').map(function () {
      return $(this).data('min-amount');
    }).get();
    let selectedAmount = 1;
    amountArray.forEach(i => {

      if (number >= i) {
        selectedAmount = i;
        // remove yellow selected marker and add it to the one clicked

        $('.js-ads-sales-box-price-table .js-quantity-select').removeClass('selected');
        const $selectedAmount = $('.js-quantity-select-' + selectedAmount);

        $selectedAmount.addClass('selected');
        // change prices and savings on salesbox above

        const basePrice = $selectedAmount.data('formatted-base-price');
        const price = $selectedAmount.data('formatted-price');
        const savingsText = $selectedAmount.data('savings-text');
        const piecePrice = $selectedAmount.data('formatted-piece-price');

        $('.js-ads-sales-box-base-price').text(' ' + basePrice);
        $('.js-ads-sales-box-unit-price').text(price);
        $('.js-ads-sales-box-savings-text').text(savingsText);
        $('.js-ads-sales-box-piece-price').text(piecePrice);
      }
    });
  }
}

const ie8ButtonDisabledFix = () => {
  setTimeout(function () {
    $('.number-input-extra-button.label-minus-icon, .number-input-extra-button.label-plus-icon, button.js-add-to-cart').prop("disabled", false);
  }, 50);
}

const initMinimumGraduatedPrice = (el) => {
  const isGraduatedPrices = $(el).closest('.art-detail-page').find('.js-ads-sales-box-price-table');
  const minAmount = $(el).find(".js-quantity-field").attr("min");
  const amountArray = $('.js-quantity-select').map(function () {
    return $(this).data('min-amount');
  }).get();

  let selectedNum;
  amountArray.forEach(i => {
    (minAmount >= i) && (selectedNum = i)
  });

  if (isGraduatedPrices) {
    $('.js-ads-sales-box-price-table .js-quantity-select').removeClass('selected');
    $('.js-quantity-select-' + selectedNum).addClass('selected');
  }
}

const correctMinAmount = (el, e) => {
  e.preventDefault();
  const minAmount = Number(el.attr("min"));
  const inputValue = Number(el.val());

  if (inputValue < minAmount) {
    el.val(minAmount);
    $('html, body').animate({
      scrollTop: el.offset().top - 100
    }, 50);
    el.parent().addClass(errorValidationClasses).removeClass(successValidationClasses);
  }

  if (inputValue > minAmount) {
    el.parent().addClass(successValidationClasses).removeClass(errorValidationClasses)
  }
}
const errorMessageHandler = (el, showErrorMessage) => {
  const errorMessage = el.attr("data-bv-greaterthan-message");
  const salesBoxErrorEl = el.parent().siblings(".js-sibling-error-message");
  const wishlistErrorEl = el.parents(".js-product-item").find(".js-wishlist-error-message");

  if (showErrorMessage) {
    $wishlistInputField.length ? wishlistErrorEl.html(errorMessage) : salesBoxErrorEl.html(errorMessage);
  } else {
    $wishlistInputField.length ? wishlistErrorEl.empty() : salesBoxErrorEl.empty();
  }
}

NumberPlusMinusButtons.prototype.init = function () {
  initMinimumGraduatedPrice($('.quantity-wrapper'));
  initListeners();
};

export default function NumberPlusMinusButtons() {
}
