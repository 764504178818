/**
 * Kümmert sich um die Einbindung der Verkaufs- bzw. Multimediabox auf der Produktliste.
 */
//noinspection JSHint
import $ from "jquery";
import AdsProductImagesGalleryController from "components/product_detail/product_images_gallery_controller";
import FactFinderController from "utils/factfinderController";
import StandaloneSalesBoxController from "components/sales_box/standalone_sales_box_controller";
import Ads from "views/ads";
import "@fancyapps/fancybox";


export default function ResponsiveProductBoxController() {
}

function reenableJSBindings() {
  new FactFinderController().init();
  new StandaloneSalesBoxController().init();
  new Ads();
}

function closeExistingVideo() {
  if ($(".modal-backdrop.in").length) {
    $('html, body').removeClass('modal-open');
    $(".modal-backdrop").remove();
  }
}

function closeExistingBootstrapModal() {
  if ($(".modal.add-to-cart-teaser").length) {
    $('html, body').removeClass('modal-open');
    $(".modal.add-to-cart-teaser").modal('hide')
    $(".modal-backdrop").remove();
  }
}

function toggleStandaloneSalesbox(event) {
  var salesboxHandler = $(event.target).closest(
      ".js-trigger-standalone-salesbox"
  );

  var ajaxUrl = salesboxHandler.attr("data-responsive-product-box-url");
  var productName = salesboxHandler.attr("data-tracking-label");

  event.preventDefault();
  event.stopPropagation();

  var options = {
    baseClass: "fancybox--mobile-fullscreen",
    hash: productName,
    beforeShow: function () {
      closeExistingBootstrapModal();
    },
    afterShow: function () {
      closeExistingBootstrapModal();
      addModalToggleToHeadlines();
      reenableJSBindings();
    },
    beforeLoad: function () {
      closeExistingVideo();
    },
    afterClose: function () {
      closeExistingVideo();
      if ($(".ads-gallery-swiper-container").length !== 0) import('../product_detail/product_images_gallery_controller').then(AdsProductImagesGalleryController => (new AdsProductImagesGalleryController.default()).init());
    }
  };

  // if there is another fancybox open, return early otherwise there are 2 open
  if ($.fancybox.getInstance()) return;

  $.fancybox.open({
    src: ajaxUrl,
    type: "ajax",
    opts: options,
  });
}

function restoreURL() {
  var url = document.location.href.split(window.location.hash)[0];
  window.history.pushState({}, document.title, url);
}

function checkForPreviousExistingModals() {
  var urlWithHash = window.location.hash;
  var currentHash = urlWithHash.split("#")[1];

  //if there is no hash present, return early
  if (!urlWithHash || !currentHash) return;

  var currentSalesboxHandler = $(
      "a[data-tracking-label='" + currentHash + "']"
  );

  //if there is no element with that name, restore URL and return early
  if (!currentSalesboxHandler || currentSalesboxHandler.length === 0) {
    restoreURL();
    return;
  }

  var ajaxUrl = currentSalesboxHandler.attr(
      "data-responsive-product-box-url"
  );

  var options = {
    baseClass: "fancybox--mobile-fullscreen",
    beforeShow: function () {
      reenableJSBindings();
    },
    afterShow: function () {
      addModalToggleToHeadlines();
    },
    afterClose: function () {
      restoreURL();
    },
  };

  // if there is another fancybox open, return early otherwise there are 2 open
  if ($.fancybox.getInstance()) return;

  $.fancybox.open({
    src: ajaxUrl,
    type: "ajax",
    opts: options,
  });
}

function addModalToggleToHeadlines() {
  var getSalesBoxTabs = $(".js-standalone-sales-box_tab");
  getSalesBoxTabs.off("click").on("click", toggleHeadlines);
}

function toggleHeadlines(event) {
  if (!event) return;

  var currentTab = $(event.target).attr("data-salesbox-tab");
  var tabContainer = $(".js-standalone-sales-box_tab-container");
  var salesBoxTabs = $(".js-standalone-sales-box_tab");

  //toggle active headline
  salesBoxTabs.removeClass("active");
  $(event.target).addClass("active");

  //update active Tab
  tabContainer.attr("data-salesbox-tab-active", currentTab);
}

/**
 * Init Funktion des Controller, sollte nur einmal beim Laden ausgeführt werden.
 */
ResponsiveProductBoxController.prototype.init = function () {
  const $standaloneSalesboxEl = $(".js-trigger-standalone-salesbox");
  const $continueShoppingBtn = $(".js-continue-shopping-btn");

  $standaloneSalesboxEl.off("click", toggleStandaloneSalesbox).on("click", toggleStandaloneSalesbox);
  // re-enables headline click listener for a partial ajax reload
  addModalToggleToHeadlines();

  //eventlistener for browser url changes, which includes reloads and back/forward browser navigation
  window.onpopstate = function () {
    checkForPreviousExistingModals();
  };

  var currentWindowOnload = window.onload;

  window.onload = function () {
    if (currentWindowOnload) {
      currentWindowOnload();
    }
    checkForPreviousExistingModals();
  };

  $continueShoppingBtn.on("click", function () {
    $.fancybox.close();
    setTimeout(function () {
      $(window).scrollTo(0, 0);
    }, 100);
  });

  const hideSalesboxClass = "standalone-sales-box-content--hide";
  const salesBoxTeaserTriggersClasses = ".js-add-to-cart, .js-add-to-wishlist-salesbox";
  const $leasingSalesboxEl = $(".js-leasing-salesbox-modal");
  const $standaloneSalesboxContentEl = $(".fancybox-container").find(".standalone-sales-box-content");
  const $standaloneSalesBoxTeaserTriggers = $(".standalone-sales-box").find(salesBoxTeaserTriggersClasses);
  const $addToCartTeaser = $(".add-to-cart-teaser");
  const $standaloneAddToCart = $(".standalone-sales-box").find(".add-to-cart-teaser");

  $addToCartTeaser.on("hide.bs.modal", function() {
    $standaloneSalesboxContentEl.removeClass(hideSalesboxClass);
  }).on("show.bs.modal", function() {
    $standaloneSalesboxContentEl.addClass(hideSalesboxClass);
  });

  $standaloneSalesboxContentEl.on("click", $standaloneSalesBoxTeaserTriggers, function(){
    $standaloneAddToCart.insertBefore($standaloneSalesboxContentEl);
  });

  if($leasingSalesboxEl.length){
    const $salesboxEl = $(".js-standalone-sales-box_tab-container").find(".add-to-cart-teaser");
    const $salesBoxContent = ".standalone-sales-box-content";

    $($salesBoxContent).on("click", salesBoxTeaserTriggersClasses, function(){
      $leasingSalesboxEl.append($salesboxEl);
    });
  }
};

ResponsiveProductBoxController.prototype.responsive = function () {
  this.adsProductImagesGalleryController = new AdsProductImagesGalleryController().initOnDomReady();
};


