import $ from "jquery";
import Swiper, {Navigation, Pagination} from 'swiper';
import "jquery.scrollto";
import "utils/lazyload.js";
import {fixAjaxSwiperImages} from "utils/lazyload";
import {ViewItemListListener} from "../../tracking/google/ga4/view_item_list_listener";
import {SelectItemTracker} from "../../tracking/google/ga4/select_item_tracker";

Swiper.use([Navigation, Pagination]);

export default function ProductListPaging() {
}

// finden der Divs
const BTN_ID = "pagingButtonId";
const PRODUCTLIST_ID = "products-part";
const PRODUCT_NOT_FOUND_DISRUPTOR = "productNotFound-row";

// Ajax-Url
const PAGING_URL_ATTRIBUTE = "data-paging-url";

// Attribute des ProductListResults
const PAGING_RESULT_COUNT = "data-result-count";
const DEFAULT_PAGESIZE = "data-objectsperpage";

// Counter für die aktuelle Seite
let PAGE_COUNT = "1";

// Responsive Box Index - damit die Box angehängt werden kann muss dieser stimmen
const RESPONSIVE_INDEX = "data-productlist-product-index";
const FF_RESPONSIVE_INDEX = "data-pos";

const JH_LOADING = "jh-loading";
const JH_GRADIENT_BTN = "jh-gardient-btn";

const optionallyHideMoreButton = function (plBtn, btn) {
  if (plBtn.length === 0) {
    btn.hide();
    $('#' + PRODUCT_NOT_FOUND_DISRUPTOR).show();
  }
};

const updateButton = function (btn, plBtn) {
  btn.html(plBtn.html());
  btn.attr(PAGING_URL_ATTRIBUTE, plBtn.attr(PAGING_URL_ATTRIBUTE));
  btn.attr(PAGING_RESULT_COUNT, plBtn.attr(PAGING_RESULT_COUNT));
  btn.attr("href", plBtn.attr("href"));
};

const updateDataIndexForResponsiveBoxes = function ($newPlPart, plBtn) {
  const $items = $newPlPart.find(".product-grid-item");
  $items.each(function () {
    const oldIndex = parseInt($(this).attr(RESPONSIVE_INDEX));
    const x = parseInt(plBtn.attr(DEFAULT_PAGESIZE)) * PAGE_COUNT;
    $(this).attr(RESPONSIVE_INDEX, oldIndex + x);
    $(this).find('a[' + FF_RESPONSIVE_INDEX + ']').each(function () {
      $(this).attr(FF_RESPONSIVE_INDEX, oldIndex + x);
    });
  });
};

const appendSeparator = function ($productList) {
  const items = $productList.find(".product-grid-item");
  const last = items.last();
  $('<div class="cart-border-top"></div>').appendTo(last);
};

const createSuccessfulAjaxResponseHandlerForTrigger = function () {
  return function (data) {
    const tempScrollTop = $(window).scrollTop();
    const $productList = $('#' + PRODUCTLIST_ID);
    const $newPlPart = $(data).find("#" + PRODUCTLIST_ID);
    const btn = $('#' + BTN_ID);
    const plBtn = $(data).find("#" + BTN_ID);

    if ($newPlPart !== null) {
      updateButton(btn, plBtn);
      updateDataIndexForResponsiveBoxes($newPlPart, plBtn);
      appendSeparator($productList);
      $productList.append($newPlPart.html());
      ViewItemListListener($newPlPart);
      SelectItemTracker($productList);
      fixAjaxSwiperImages($productList.find("img[data-src]"));
      const $swipe = $productList.find(".gallery-productlist-swipe.swipe");
      new Swiper($swipe, {
        watchOverflow: true,
        speed: 600,
        watchSlidesVisibility: true,
        pagination: {
          el: '.productlist-gallery-swiper-element.carousel-indicators',
          clickable: true,
          bulletClass: "carousel-indicators-bullet",
          bulletActiveClass: "active",
          renderBullet: function (index, className) {
            return '<li class="' + className + '"><span></span></li>';
          }
        },
        navigation: {
          nextEl: '.productlist-gallery-swiper-element.swiper-button-next',
          prevEl: '.productlist-gallery-swiper-element.swiper-button-prev'
        }
      });
      enableNewlyLoadedProductBoxes();
      PAGE_COUNT = parseInt(PAGE_COUNT) + 1;
      btn.attr("data-page", PAGE_COUNT);
      btn.data("page", PAGE_COUNT);
      optionallyHideMoreButton(plBtn, btn);
      removeLoader(btn, 0);
      addEllipsis();
    }
    $(window).scrollTop(tempScrollTop);
    $(document).trigger("pl:newProductsLoaded");
  };
};

const enableNewlyLoadedProductBoxes = function () {
  import("components/product_list/responsive_product_box").then(ResponsiveProductBoxController => (new ResponsiveProductBoxController.default()).init());
}

const addEllipsis = function () {
  const width = $(window).width();
  if (width < 1024) {
    $('.product-grid-style .item-name a').each(function () {
      const itemName = $(this);
      const max = 65;
      if (itemName.html().length > max) {
        const newHtml = itemName.html().substring(0, max - 4) + ' ...';
        itemName.html(newHtml);
      }
    });
  } else {
    $('.product-grid-style .item-name a').each(function () {
      const itemName = $(this);
      const max = 90;
      if (itemName.html().length > max) {
        const newHtml = itemName.html().substring(0, max - 4) + ' ...';
        itemName.html(newHtml);
      }
    });
  }
};

const removeLoader = function ($link, timeout) {
  setTimeout(function () {
    $link.removeClass(JH_LOADING);
    $link.addClass(JH_GRADIENT_BTN);
  }, timeout);
};

const addLoader = function ($link) {
  $link.addClass(JH_LOADING);
  $link.removeClass(JH_GRADIENT_BTN);
};

const appendNewProductList = function ($link) {
  addLoader($link);

  $.ajax({
    cache: false,
    data: {},
    dataType: "html",
    url: $link.attr(PAGING_URL_ATTRIBUTE),
    success: createSuccessfulAjaxResponseHandlerForTrigger($link),
    done: removeLoader($link, 5000)
  });
};

ProductListPaging.prototype.init = function () {
  $("#" + BTN_ID).off("click").on("click", function (e) {
    e.preventDefault();
    return appendNewProductList($(this));
  });
};
