import $ from "jquery";
import header_carts_handler from 'components/header_carts_handler';
import TeaserViewTracker from '../../tracking/google/360/teaser_view_tracker.js';
import TeaserClickTracker from '../../tracking/google/360/teaser_click_tracker.js';
import {TrackingListener} from '../../tracking/google/360/tracking_listener';
import {CartListener} from '../../tracking/google/ga4/cart_listener';
import EmarsysRecoTeaser from "teaser/emarsys_reco_teaser";

const CLICK_EVENT_NAME = "click.sales_box.sales_box_cart_button_controller";
const $continueShoppingBtn = $('.js-continue-shopping-btn');

const submit = function (e, $button) {
  const $articleForm = $button.parents("form");
  const showAddToCartTeaser = $button.data("show-add-to-cart-teaser");
  const isAribaLevel2Punchout = $button.data("ariba-level2-punchout");
  const teaserId = "#add-to-cart-teaser-" + $button.data("productid");
  const quantity = parseInt($articleForm.find("#quantity").val());

  if (isNaN(quantity) || quantity <= 0) {
    return;
  }

  $.post($articleForm.data("ajax-action-url"), $articleForm.serialize(), function (data) {
    header_carts_handler().shopCartHandler.input(data.markup, data.count, data.total);
    if (isAribaLevel2Punchout) {
      window.location = data.redirectToUrl;
    } else if (showAddToCartTeaser) {
      var isCommaNotDot = false;
      var addToCartTeaser = $(teaserId);
      var $priceBlock = $(".js-price-block");
      var displayPrice = $priceBlock.data("displayed-price");
      var displayPriceSaved = $priceBlock.data("displayed-price-saved");
      if (displayPrice && displayPrice.toString().indexOf(",") !== -1) {
        isCommaNotDot = true;
        displayPrice = displayPrice.replace(",", ".");
        displayPriceSaved = displayPriceSaved.replace(",", ".");
      }
      var priceScale = {pricePerUnit: displayPrice, savedPerUnit: displayPriceSaved};
      $(".js-price-scale").each(function () {
        var $this = $(this);
        var minAmount = parseInt($this.data("min-amount"));
        var dataAmountPrice = $this.data("amount-price");
        var dataAmountPriceSaved = $this.data("amount-price-saved");
        if (dataAmountPrice && dataAmountPrice.toString().indexOf(",") !== -1) {
          isCommaNotDot = true;
          dataAmountPrice = dataAmountPrice.replace(",", ".");
          dataAmountPriceSaved = dataAmountPriceSaved.replace(",", ".");
        }
        var amountPrice = parseFloat(dataAmountPrice);
        var amountPriceSaved = parseFloat(dataAmountPriceSaved);
        if (minAmount <= quantity) {
          priceScale = {pricePerUnit: amountPrice, savedPerUnit: amountPriceSaved};
        }
      });
      var sum = priceScale.pricePerUnit * quantity;
      var saved = priceScale.savedPerUnit * quantity;
      sum = sum.toFixed(2);
      saved = saved.toFixed(2);
      if (isCommaNotDot) {
        sum = ("" + sum).replace(".", ","); // 1300.00 -> 1300,00
        sum = sum.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); // 1300,00 -> 1.300,00
        saved = ("" + saved).replace(".", ","); // 1300.00 -> 1300,00
        saved = saved.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1."); // 1300,00 -> 1.300,00
      } else {
        sum = sum.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"); // 1300.00 -> 1,300.00
        saved = saved.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,"); // 1300.00 -> 1,300.00
      }
      var $price = addToCartTeaser.find(".js-price");
      if ($price.length > 0) {
        var sumHtml = $price.html();
        sumHtml = convertPriceFormat(sum, sumHtml, isCommaNotDot);
        $price.html(sumHtml);
      }

      var isShowSavedPrice = true;
      if (saved.localeCompare("0,00") === 0 || saved.localeCompare("0.00") === 0) {
        isShowSavedPrice = false;
      }

      var $saved = addToCartTeaser.find(".js-saved");
      if ($saved.length > 0) {
        if (isShowSavedPrice) {
          var savedHtml = $saved.html();
          savedHtml = convertPriceFormat(saved, savedHtml, isCommaNotDot);
          $saved.html(savedHtml);
        } else {
          $saved.remove();
        }
      }

      let isEmarsys = addToCartTeaser.find(".js-add-to-cart-emarsys-reco").length > 0;
      if (isEmarsys) {
        window.ScarabQueue = window.ScarabQueue || [];
        window.ScarabQueue.push(["cart", data.emarsysBasket]);
        window.ScarabQueue.push(['view', $button.data('id')]);
        new EmarsysRecoTeaser().initAddToCartRecoTeaser();
      }

      addToCartTeaser.modal('show')
      new TeaserViewTracker().forceTrackingOfTeaser(addToCartTeaser.find(".js-teasertracking-delayed"));
      new TeaserClickTracker().init(addToCartTeaser.find(".js-teasertracking"));
    } else {
      var redirectToUrl = data.redirectToUrl || "";
      if (redirectToUrl !== "") {
        window.location = redirectToUrl;
        window.scrollTo(0, 0);
      }
    }
  });
};

function convertPriceFormat(newPrice, priceHtml, isCommaNotDot) {
  var price;
  if (isCommaNotDot) {
    price = priceHtml.replace(".", ""); // old Price 1.200,50 -> 1200,50
    priceHtml = price.replace(/\d+[,]\d{2}/g, newPrice); // 1200,50 -> new Price 1.300,00
  } else {
    price = priceHtml.replace(",", ""); // old Price 1,200.50 -> 1200.50
    priceHtml = price.replace(/\d+[.]\d{2}/g, newPrice); // 1200.50 -> new Price 1,300.00
  }
  return priceHtml;
}

function recoSubmitToBasket($articleForm) {
  $.post($articleForm.data("ajax-action-url"), $articleForm.serialize(), function (data) {
    if (data.redirectToUrl !== "" && typeof data.redirectToUrl !== undefined) {
      window.location = data.redirectToUrl;
      window.scrollTo(0, 0);
    }
  })
}

export default function SalesBoxCartButtonController() {
}

$continueShoppingBtn.on("click", function () {
  $.fancybox.close();
  setTimeout(function () {
    $(window).scrollTo(0, 0);
  }, 100);
});

SalesBoxCartButtonController.prototype.init = function () {

  $(document).off(CLICK_EVENT_NAME).on(CLICK_EVENT_NAME, ".js-add-to-cart", function (e) {
    e.preventDefault();
    const $adsQuantityInput = $(this).closest('.js-ads-form').find('.js-quantity-field');
    const $adsQuantityInputValue = Number($adsQuantityInput.val());
    const $minAmount = Number($adsQuantityInput.attr("min"));
    $adsQuantityInputValue < $minAmount ? $adsQuantityInput.val($minAmount) : submit(e, $(this));
  });

  $(document).on(CLICK_EVENT_NAME, ".js-reco-submit-btn", function (e) {
    e.preventDefault();
    const $button = $(this);
    const $articleForm = $button.parents(".js-reco-form");

    $articleForm.find('input.twerked').prop('disabled', false);
    recoSubmitToBasket($articleForm);
  })

  TrackingListener();
  CartListener();
};
