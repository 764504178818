import $ from "jquery";

var defaults = {
  classes: [""]
}

export default function Knobs($el, options) {
  this.options = $.extend(defaults, options);
  this.slider = $el.data("Swipe");
  this.$el = $el;

  if (this.options.knobWrapper) this.container = this.options.knobWrapper;
  else this.container = this.$el;

  this.init();
  this.$el.on("swipe.kill", this.kill.bind(this));
}

Knobs.prototype = {
  init: function () {
    if (!this.slider) return;

    this.numSlides = this.slider.getNumSlides();

    if (this.numSlides === 1) {
      this.kill();
      return;
    }

    // Sonderregel für 2 Slides.
    // Swipe.js dupliziert die Slides wenn nur zwei existieren
    // um einen fließenden Wechsel zwischen dem letzten und den ersten zu gewähren.
    // Siehe: https://github.com/thebird/Swipe/pull/330 und https://github.com/thebird/Swipe/issues/349
    // Deswegen wird hier verglichen ob der erste und dritte Slide die gleiche ID haben.
    // Ist bis jetzt nur für Stage Teaser und ProductList implementiert.
    if (this.numSlides === 4) {
      var swipeItems = this.container.find(".swipe-wrap .swipe-item");
      var gridSwipeItems = this.container.find(".swipe-wrap .grid-item-wrap");
      if ($(swipeItems[0]).data("swipe-item-id") === $(swipeItems[2]).data("swipe-item-id")
        && $(swipeItems[0]).data("swipe-item-id") !== undefined) {
        this.container.data("duplicated-swipe-items", true);
      } else if (gridSwipeItems.length === 4) {
        var src0 = $(gridSwipeItems[0]).find("img").data("src");
        var src2 = $(gridSwipeItems[2]).find("img").data("src");
        if (src0 === src2 && src0 !== undefined) {
          this.container.data("duplicated-swipe-items", true);
        }
      }
    }

    this.pos = this.slider.getPos();

    if (this.container.data("duplicated-swipe-items")) {
      this.numSlides = 2;
      this.pos %= 2;
    }

    var sliderId = this.$el[0].id;
    var indicatorId = 'indicator-' + sliderId;
    this.indicatorId = indicatorId;

    this.originalCallback = this.slider.options.callback;

    this.slider.options.callback = $.proxy(this.callback, this);


    this.markup = $('<ol id="indicator-' + this.$el[0].id + '" class="carousel-indicators ' + this.options.classes.join(" ") + '"></ol>');

    for (var i = 0; i < this.numSlides; i++) {
      this.markup.append($('<li id="link-' + indicatorId + '" class="' + (this.pos === i ? "active" : "") + '"><span></span></li>'));
    }

    if (this.container.find(".carousel-indicators").length === 0) {
      this.container.append(this.markup);
      this.$el.data("Knobs", this);

      var _this = this;

      // der Container ist bereits das richtige Element
      this.container.find('.carousel-indicators#' + this.indicatorId + ' li').on('click', {_this: _this}, function (e) {
        e.preventDefault();
        var newPos = _this.container.find('.carousel-indicators#' + _this.indicatorId + ' li').index(this);
        _this.slider.slide(newPos);
      });
    }
  },
  kill: function () {
    this.container.find(".carousel-indicators li").off('click');
    this.container.find(".carousel-indicators").remove();
    this.$el.off("swipe.kill");
  },
  callback: function (pos, element, containerId) {
    if (this.originalCallback) {
      this.originalCallback.call(null, pos, element, containerId);
    }

    if (this.container.data("duplicated-swipe-items")) {
      pos %= 2;
    }

    var elId = element.id;
    var conId = this.container[0].id;
    if (elId && (elId === conId)) {
      this.container.find('.carousel-indicators#' + this.indicatorId + ' li').removeClass("active").eq(pos).addClass("active");
    }
  }
}

$.fn.knobs = function (options) {
  var matched = $(this);

  matched.each(function () {
    new Knobs($(this), options)
  });
  return matched;
}

