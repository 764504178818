import $ from 'jquery';
import Swiper, {Autoplay, Navigation} from 'swiper';
import {registerViewPromotionTracking, slideEndCallback} from "../tracking/google/ga4/view_promotion_tracker";

Swiper.use([Navigation, Autoplay]);

const STAGE_TEASER_SWIPE_SELECTOR_V1 = '.action-stage .swipe';
const STAGE_TEASER_SWIPE_SELECTOR_V2 = '.stage-teaser-v2.swipe';
const STAGE_TEASER_SWIPE_SELECTOR = STAGE_TEASER_SWIPE_SELECTOR_V1 + ', ' + STAGE_TEASER_SWIPE_SELECTOR_V2;
const STAGE_TEASER_CONTAINER = '.stage-swiper-container';
const STAGE_TEASER_NAV_NEXT_ELEMENT = '.stage-swiper-element.swiper-button-next';
const STAGE_TEASER_NAV_PREV_ELEMENT = '.stage-swiper-element.swiper-button-prev';

export default function StageTeaser() {
}

StageTeaser.prototype.removeSwipes = function () {
  $(STAGE_TEASER_SWIPE_SELECTOR).each(function () {
    if ($(this).params) {
      $(this).destroy(true, true);
    }
  });
};

StageTeaser.prototype.responsive = function (msg, on_off) {
  if (on_off) {
    this.removeSwipes();
    $(STAGE_TEASER_CONTAINER).each(function () {
      const $swiperEl = $(this);
      const $parentEl = $swiperEl.parent();
      const $navNextEl = $parentEl.find(STAGE_TEASER_NAV_NEXT_ELEMENT);
      const $navPrevEl = $parentEl.find(STAGE_TEASER_NAV_PREV_ELEMENT);

      new Swiper($swiperEl.get(), {
        watchOverflow: true,
        speed: 600,
        autoplay: window.shopConfig.autoSwipeStageTeaser ? {delay: 6000} : false,
        watchSlidesVisibility: true,
        navigation: {
          nextEl: $navNextEl.get(),
          prevEl: $navPrevEl.get()
        },
        on: {
          afterInit: registerViewPromotionTracking,
          slideChangeTransitionEnd: slideEndCallback
        },
      });
    });
  }

  if (msg === "res.xs") {
    const $stageTeaserV2Swiper = $(STAGE_TEASER_SWIPE_SELECTOR_V2);
    if ($stageTeaserV2Swiper.length > 0) {
      $stageTeaserV2Swiper.find('.stage-teaser-v2-tab').each(function () {
        const $tab = $(this);
        const $link = $tab.find('.jh-button-a');
        if ($link.length > 0) {
          const href = $link.attr('href');
          $tab.find('picture, .stage-teaser-v2-tab-info').on('click', function () {
            location.href = href;
          });
        }
      });
    }
  }

  calculateButtonWidth();
};

function calculateButtonWidth() {
  $('.action-stage .swipe-item').each(function () {
    var button = $(this).find('.stage-teaser-info-container .stage-teaser-info .button-row a.jh-button-a.jh-gardient-btn.jh-button-big');
    var hasButton = button.length > 0;
    var discountPrice = $(this).find('.stage-teaser-info-container .stage-teaser-info .prices.discounted');
    var hasDiscountPrice = discountPrice.length > 0;

    //Wenn es einen Streichpreis gibt und nur einen Button soll dieser mindestens so Breit sein wie das Preis-Feld
    if (hasDiscountPrice) {
      // 20 = 2x 10px padding
      var discountPriceWidth = discountPrice.width() + 20;
      if (hasButton) {
        button.css('min-width', discountPriceWidth);
      }
    }
  })
}