//noinspection JSHint
/**
 * Steuert das zoomen des Produktbildes auf der ADS
 */
import $ from "jquery";
import 'utils/zoom.js'

export default function ZoomController() {
}


/**
 * Ausblenden der Lupe wenn es kein Zoombild gibt
 */
ZoomController.prototype.toggleMagnifierOnSlideEventHandler = function (event) {
  var $container = $(".main-swipe");
  var $slided = $container.find("div.jh-has-zoom-image[data-index='" + event.index + "']");
  var hasZoomImage = $slided.length === 1;
  var $magnifier = $container.find(".magnifier");
  $magnifier.toggle(hasZoomImage);
};

function createZoomInitializer(_this) {
  return function () {
    _this.zoom = $(".main-image div.jh-has-zoom-image").zoom().data('Zoom');
  };
}

/**
 * Diese Methode sollte aufgerufen werden, um den ZoomController beim pageLoad zu initialisieren
 */
ZoomController.prototype.init = function () {
  $(document).ready(createZoomInitializer(this));
  $(document).on("jungheinrich-ads-gallery-slide-event", this.toggleMagnifierOnSlideEventHandler);
  return this;
};

/**
 * Diese Methode kann aufgerufen werden um den ZoomController neu zu intialisieren, wenn alle Bilder per
 * AJAX ausgetauscht worden sind.
 */
ZoomController.prototype.reinitAfterAjaxEvent = function (ajaxEventName) {
  $(document).on(ajaxEventName, createZoomInitializer(this));
  return this;
};

