import $ from 'jquery';
import Swiper, {Pagination} from 'swiper';
import ProductClickTracker from '../tracking/google/360/product_click_tracker';
import GoogleTracking from 'utils/google_tracking';
import {registerViewPromotionTracking, slideEndCallback} from "../tracking/google/ga4/view_promotion_tracker";

Swiper.use([Pagination]);

export default function TripleProductTeaser() {
  this.productlistClickTracking = new ProductClickTracker();
  this.googleTracking = new GoogleTracking();
}

TripleProductTeaser.prototype.removeSwipes = function () {
  $('.tripple-product-teaser.swipe').each(function () {
    if ($(this).params) {
      $(this).destroy(true, true);
    }
  });
};

TripleProductTeaser.prototype.responsive = function (msg, on_off) {
  if (on_off) {
    this.removeSwipes();
    let slidesPerView = 3;
    let slidesPerGroup = 3;

    if (msg === 'res.xs') {
      slidesPerView = 2;
      slidesPerGroup = 2;
    }

    new Swiper('.triple-product-swiper-container', {
      watchOverflow: true,
      speed: 600,
      slidesPerView: slidesPerView,
      slidesPerGroup: slidesPerGroup,
      watchSlidesVisibility: true,
      spaceBetween: 10,
      on: {
        afterInit: registerViewPromotionTracking,
        slideChangeTransitionEnd: slideEndCallback
      },
      pagination: {
        el: '.triple-product-swiper-element.carousel-indicators',
        clickable: true,
        bulletClass: 'carousel-indicators-bullet',
        bulletActiveClass: 'active',
        renderBullet: function (index, className) {
          return '<li class="' + className + '"><span></span></li>';
        }
      }
    });
  }

  this.productlistClickTracking.init();
  this.googleTracking.addClickTracking(this.googleTracking);
};
