import {Events, PRODUCT_CLICK} from "./360/events";

let googleAnalyticsAllowed = false;

export const handleAndPublishTrackingEvent = (productParams, event) => {
  const currency = productParams['currency'];
  delete productParams['currency'];

  fixValueTypes(productParams);

  const type = Events[event]['type'];

  let entry = {
    'event': event,
    'ecommerce': {
      'currencyCode': currency,
      [type]: {
        'products': [productParams]
      }
    }
  };

  if (event === PRODUCT_CLICK) {
    entry['ecommerce']['actionField'] = {
      'action': type,
      'list': productParams['list']
    }
  }

  publish(entry)
}

function fixValueTypes(params) {
  const idParam = params["id"];
  if (idParam && typeof idParam === "number") {
    params["id"] = idParam.toString();
  }

  const productIdParam = params["productid"];
  if (productIdParam && typeof productIdParam === "number") {
    params["productid"] = productIdParam.toString();
  }

  const priceParam = params["price"];
  if (priceParam && typeof priceParam === "string") {
    const parsedPrice = parseFloat(priceParam);
    if (!isNaN(parsedPrice)) {
      params["price"] = parsedPrice;
    }
  }
}

export const publish = (eventArgs) => {
  if (googleAnalyticsAllowed) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(eventArgs);
  } else {
    window.eventQueue = window.eventQueue || []
    window.eventQueue.push(eventArgs);
  }
}

window.addEventListener('ucEvent', function (e) {
  if (e.detail && e.detail.event === 'consent_status' && e.detail['Google Analytics'] === true) {
    googleAnalyticsAllowed = true;
    window.dataLayer = window.dataLayer || [];
    window.eventQueue = window.eventQueue || [];
    window.eventQueue.forEach((event) => window.dataLayer.push(event));
    window.eventQueue = [];
  }
});
