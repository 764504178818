export default function Logger() {

  const consoleExists = !(typeof window.console === 'undefined' || typeof window.console.log === 'undefined' || typeof window.console.log.apply === 'undefined');
  if (consoleExists) {
    return ConsoleLogger;
  } else {
    return NothingLogger;
  }
}

function ConsoleLogger(loggerName) {

  var log = function (level, message) {
    window.console.log(level + " (" + loggerName + ") > " + message);
  };

  var logInfo = function (message) {
    log("INFO", message);
  };

  var logWarn = function (message) {
    log("WARN", message);
  };

  var logError = function (message) {
    log("ERROR", message);
  };

  return {
    logInfo: logInfo,
    logWarn: logWarn,
    logError: logError
  };
}

function NothingLogger() {

  var doNothing = function () {
  };

  return {
    logInfo: doNothing,
    logWarn: doNothing,
    logError: doNothing
  };
}

