import $ from 'jquery';
import Swiper, {Navigation, Pagination} from 'swiper';
import ProductClickTracker from '../tracking/google/360/product_click_tracker';
import GoogleTracking from 'utils/google_tracking';
import {registerViewPromotionTracking, slideEndCallback} from "../tracking/google/ga4/view_promotion_tracker";

Swiper.use([Navigation, Pagination]);

export default function EquipmentTeaser() {
  this.productlistclicktracking = new ProductClickTracker();
  this.googleTracking = new GoogleTracking();
}

EquipmentTeaser.prototype.removeSwipes = function () {
  $('.equipment-teaser .swipe').each(function () {
    if ($(this).params) {
      $(this).find('.swipe-clone').remove();
      $(this).destroy(true, true);
    }
  });
};

EquipmentTeaser.prototype.responsive = function (msg, on_off) {
  if (on_off) {
    this.removeSwipes();
    const $equipmentTeaser = $('.equipment-teaser .swipe');

    switch (msg) {
      case 'res.xs':
        $equipmentTeaser.each(function () {
          const $swiperEl = $(this);
          const $parentEl = $swiperEl.parent();
          const $navNextEl = $parentEl.find('.product-swiper-element.swiper-button-next');
          const $navPrevEl = $parentEl.find('.product-swiper-element.swiper-button-prev');

          const numberOfCols = numberOfColumnsSmallBreakpoint($parentEl);
          fillLastSlide($swiperEl.find(".grid-element"), numberOfCols);
          const $slideEl = $swiperEl.find('.swiper-slide');
          ($slideEl.length > 1 ? $navNextEl.show() : $navNextEl.hide());
          ($slideEl.length > 1 ? $navPrevEl.show() : $navPrevEl.hide());

          new Swiper($swiperEl.get(), {
            watchOverflow: true,
            speed: 600,
            slidesPerView: 1.1,
            watchSlidesVisibility: true,
            spaceBetween: 150,
            breakpoints: {
              320: {
                spaceBetween: 150
              },
              640: {
                spaceBetween: 170
              },
              720: {
                spaceBetween: 180
              },
            },
            centeredSlides: true,
            navigation: {
              nextEl: $navNextEl.get(),
              prevEl: $navPrevEl.get()
            },
            on: {
              afterInit: registerViewPromotionTracking,
              slideChangeTransitionEnd: slideEndCallback
            },
          });
        });
        break;
      case 'res.sm':
        $equipmentTeaser.each(function () {
          const $swiperEl = $(this);
          const $parentEl = $swiperEl.parent();
          const $navNextEl = $parentEl.find('.product-swiper-element.swiper-button-next');
          const $navPrevEl = $parentEl.find('.product-swiper-element.swiper-button-prev');
          const numberOfCols = numberOfColumnsSmallBreakpoint($parentEl);
          fillLastSlide($swiperEl.find(".grid-element"), numberOfCols);
          const $slideEl = $swiperEl.find('.swiper-slide');
          ($slideEl.length > 2 ? $navNextEl.show() : $navNextEl.hide());
          ($slideEl.length > 2 ? $navPrevEl.show() : $navPrevEl.hide());

          new Swiper($swiperEl.get(), {
            watchOverflow: true,
            speed: 600,
            slidesPerView: 2,
            slidesPerGroup: 2,
            watchSlidesVisibility: true,
            navigation: {
              nextEl: $navNextEl.get(),
              prevEl: $navPrevEl.get()
            },
            on: {
              afterInit: registerViewPromotionTracking,
              slideChangeTransitionEnd: slideEndCallback
            },
          });
        });
        break;
      default:
        $equipmentTeaser.each(function () {
          const $swiperEl = $(this);
          const $parentEl = $swiperEl.parent();
          const $navNextEl = $parentEl.find('.product-swiper-element.swiper-button-next');
          const $navPrevEl = $parentEl.find('.product-swiper-element.swiper-button-prev');
          const $slideEl = $swiperEl.find('.swiper-slide')

          if ($slideEl.length > 4) {
            $navNextEl.show();
            $navPrevEl.show();
          }

          const numberOfCols = numberOfColumns($parentEl);
          fillLastSlide($swiperEl.find(".grid-element"), numberOfCols);

          new Swiper($swiperEl.get(), {
            watchOverflow: true,
            speed: 600,
            slidesPerView: 4,
            slidesPerGroup: 4,
            watchSlidesVisibility: true,
            navigation: {
              nextEl: $navNextEl.get(),
              prevEl: $navPrevEl.get()
            },
            on: {
              afterInit: registerViewPromotionTracking,
              slideChangeTransitionEnd: slideEndCallback
            },
          });
        });
    }
  }

  this.productlistclicktracking.init();
  this.googleTracking.addClickTracking(this.googleTracking);
};

function numberOfColumns(swipeParent) {
  if ($('.page-product-list').length !== 0 || swipeParent.find('.equipment-teaser-item-left').length !== 0 || swipeParent.data('columns') === 3) {
    return 3;
  }
  return 4;
}

function numberOfColumnsSmallBreakpoint(swipeParent) {
  if (swipeParent.find('.equipment-teaser-item-left').length !== 0 && swipeParent.find('.equipment-teaser-item-left').is(':visible')) {
    return 1;
  }
  return 2;
}

function fillLastSlide(swipeItems, numberOfCols) {
  const numberOfItems = swipeItems.length;
  const numberOfSurplusItems = numberOfItems % numberOfCols;
  if (numberOfItems > numberOfCols && numberOfSurplusItems !== 0) {
    const idOfInsertBefore = numberOfItems - numberOfSurplusItems;
    let insertBefore = $(swipeItems[idOfInsertBefore]);
    for (let id = (idOfInsertBefore - 1); id >= (idOfInsertBefore - (numberOfCols - numberOfSurplusItems)); id--) {
      const clone = $(swipeItems[id]).clone().addClass('swipe-clone');
      insertBefore.before(clone);
      insertBefore = clone;
    }
  }
}
