import $ from "jquery";
import 'suggest';

export default function SearchSuggest() {
  this.catHoverTimer = null;
  this.clearHoverTimer = null;
}

SearchSuggest.prototype = {
  init: function () {
    var _this = this;
    createSearchSuggest(_this);
    $(window).on("resize", function () {
      updateSearchBoxPosition();
    });
  },
  responsive: function (message, onoff) {
    updateSearchBoxPosition();
  }
}

function updateSearchBoxPosition() {
  if ($(window).width() >= 767) {
    $("#default-product-suggestions").css('min-height', $(".autocomplete-group-wrapper-left").height() + 8);
  } else {
    $("#default-product-suggestions").css('min-height', "");
  }

  var inputField = getActiveInputField();
  var searchBox = $(".well.search-suggest");
  if (inputField != undefined && searchBox != undefined && searchBox.is(":visible")) {
    if ($(window).width() >= 992) {
      adjustSuggestionPosition(inputField, searchBox);
    } else {
      adjustSuggestionPositionMobile(inputField, searchBox);
    }
  }
}

function adjustSuggestionPositionMobile(inputField, searchBox) {
  var searchForm = inputField.closest(".js-header-search");
  var top = searchForm.offset().top + searchForm.outerHeight();
  searchBox.css({"top": top + "px", "left": "10px"});
}

function adjustSuggestionPosition(inputField, searchBox) {
  var searchForm = inputField.closest(".js-header-search");
  var top = searchForm.offset().top + searchForm.outerHeight();
  var left = searchForm.offset().left;
  searchBox.css({"top": top + "px", "left": left + "px"});
}

function getActiveInputField() {
  var activeInputField;
  $('input[name=q]').each(function () {
    var active = $(this).data("active");
    var value = $(this).val();
    if (active && value !== undefined && value.length > 1) {
      activeInputField = $(this);
    }
  });
  return activeInputField;
}

function swapActiveInputField(newActiveField) {
  $('input[name=q]').each(function () {
    $(this).data("active", 0);
  });
  newActiveField.data("active", 1);
}

function trackSuggestClick() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'eventCategory': 'Form Tracking',
    'eventAction': 'form (search_form)',
    'eventLabel': 'suggest_click (search_form)',
    'event': 'event'
  });
}

function createSearchSuggest(_this) {
  var container = "<div class='well search-suggest'></div>";
  $("body").append(container);
  container = $(".well.search-suggest");
  container.css({"position": "absolute", "z-index": 10, "width": "600px"});
  container.hide();

  var handleAjaxResponseFunction = function (response) {
    container.html(response);

    // define number of columns
    var leftGroup = container.find(".autocomplete-group-wrapper-left")[0];
    if (leftGroup === undefined || $(leftGroup).children().length === 0) {
      container.removeClass("twoColumns");
    } else {
      $(container).addClass("twoColumns");
    }

    var showDefaultProductSuggestionsFunction = function () {
      $(".autocomplete-group-wrapper-right").each(function () {
        $(this).hide();
      });
      $("#default-product-suggestions").show();
    }

    // add hover functionality to category entries
    $("[data-search-suggest-category]").each(function () {

      // shows category top products in right column on mouseenter
      var mouseenterFunction = function () {
        var _that = $(this);
        if (_this.catHoverTimer) clearTimeout(_this.catHoverTimer);
        if (_this.clearHoverTimer) clearTimeout(_this.clearHoverTimer);
        _this.catHoverTimer = setTimeout(function () {
          if ($(window).width() >= 992) {
            $(".autocomplete-group-wrapper-right").each(function () {
              $(this).hide();
            });

            var id = '#' + _that.data("search-suggest-category");
            var newElement = $(id);
            var height = $(".autocomplete-group-wrapper-left").height() + 8;
            newElement.css('min-height', height);
            newElement.show();
          }
        }, 300);
      };
      $(this).off("mouseenter", mouseenterFunction).on("mouseenter", mouseenterFunction);

      // shows default search results in right column when mouseleave to non-related target
      var mouseleaveFunction = function (e) {
        if (_this.catHoverTimer) clearTimeout(_this.catHoverTimer);
        var oldId = $(this).data("search-suggest-category");
        var newTarget = e.toElement || e.relatedTarget;
        var rightWrapper = $(newTarget).closest(".autocomplete-group-wrapper-right");
        var newId = $(rightWrapper).attr("id");

        if (oldId !== newId) {
          if (_this.clearHoverTimer) clearTimeout(_this.clearHoverTimer);
          _this.clearHoverTimer = setTimeout(showDefaultProductSuggestionsFunction, 100);
        }
      };

      $(this).off("mouseleave", mouseleaveFunction).on("mouseleave", mouseleaveFunction);
    });

    // function to hide search box when clicking outside the box
    var dismissFunction = function (e) {
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        container.hide();
      }
    };
    $(document).off("mouseup", dismissFunction).on("mouseup", dismissFunction);

    container.show();
    if ($(window).width() >= 767) {
      $("#default-product-suggestions").css('min-height', $(".autocomplete-group-wrapper-left").height() + 8);
    }

    container.off("mouseleave", showDefaultProductSuggestionsFunction).on("mouseleave", showDefaultProductSuggestionsFunction);
    container.off("click", ".autocomplete-suggestion", trackSuggestClick).on("click", ".autocomplete-suggestion", trackSuggestClick);
  };

  function createSearchSuggest(inputField, searchTerm) {
    var serviceUrl = $(inputField).data('js-suggest');
    if (serviceUrl.indexOf("?") === -1) {
      serviceUrl += "?q=" + searchTerm;
    } else {
      serviceUrl += "&q=" + searchTerm;
    }
    $.get(serviceUrl, function (response) {
      handleAjaxResponseFunction(response);
    });
  }

  $('input[name=q]').each(function () {
    $(this).on("click", function (e) {
      var inputField = e.currentTarget;
      var searchTerm = encodeURIComponent($(inputField).val());
      if (searchTerm.length > 1) {
        adjustSuggestionPosition($(inputField), container);

        if ($(inputField).data("active") === 0) {
          swapActiveInputField($(inputField));
          createSearchSuggest(inputField, searchTerm);
        } else {
          container.show();
        }
      }
    });
    $(this).keyup(function (e) {
      var inputField = e.currentTarget;
      var searchTerm = encodeURIComponent($(inputField).val());
      if (searchTerm.length > 1) {
        swapActiveInputField($(inputField));
        adjustSuggestionPosition($(inputField), container);
        createSearchSuggest(inputField, searchTerm);
      } else {
        container.hide();
      }
    });
  });
}

