import $ from 'jquery';
import 'utils/polyfill.js';
import General from './views/general.js';
import TrackingHelper from './utils/trackinghelper.js';
import TeaserValidator from './utils/teaservalidator.js';
import {initialize} from 'components/resolutions';
import PubSub from 'pubsub-js';
import Ads from './views/ads';
import Register from './views/register';
import {ViewPromotionTracker} from "./tracking/google/ga4/view_promotion_tracker";
import {SelectPromotionTracker} from "./tracking/google/ga4/select_promotion_tracker";

if ($(".home").length !== 0) {
  import('./views/home.js').then(Home => new Home.default());
} else if ($("#art-detail-page").length !== 0) new Ads();
else if ($(".cart-page").length !== 0) import('./views/cart.js').then(Cart => new Cart.default());
else if ($(".checkout, .thankyou-checkout").length !== 0) import('./views/checkout.js').then(Checkout => new Checkout.default());
else if ($(".account").length !== 0) import('./views/account.js').then(Account => new Account.default());
else if ($(".contact").length !== 0) import('./views/contact.js').then(Contact => new Contact.default());
else if ($(".page-product-list").length !== 0 || $(".no-search-result-boxes").length !== 0 || $(".js-activate-filter-layer").length !== 0) import('./views/product').then(Product => (new Product.default()));
else if ($(".category-boxes").length !== 0) import('./views/category.js').then(Category => new Category.default());
else if ($("#vzk-page").length !== 0) import('./views/vzk.js').then(Vzk => new Vzk.default());

if ($(".js-vue-searchsuggest").length !== 0) import('./vue/vueApp').then(vueApp => new vueApp.initSearchSuggest());
if ($("div[data-teaser-type='Newsletter Teaser']").length !== 0
  || $(".newsletter, #newsletter-layer").length !== 0
  || $(".newsletter, #newsletter-layer-no-form").length !== 0
  || $(".js-newsletter-register").length !== 0
  || $(".js-newsletter-validate").length !== 0
  || $("#newsletter-doi-layer").length !== 0) {
  import('./views/newsletter.js').then(Newsletter => new Newsletter.default());
}
if ($(".ms-dynamics-html-template").length !== 0) import('views/ms-dynamics-html-template').then(MsDynamicsHtmlTemplate => new MsDynamicsHtmlTemplate.default());
if ($(".wishlist-page").length !== 0) import('./views/wishlist.js').then(Wishlist => new Wishlist.default());
if ($(".service").length !== 0) import('./views/service.js').then(Service => new Service.default());
if ($("#createAccountModel").length !== 0) new Register();
if ($(".product-compare-list").length !== 0) import('./views/productcompare.js').then(ProductCompare => new ProductCompare.default());
if ($(".video-play-button").length !== 0) import('../libs/youtube.js').then(YoutubePlayer => (new YoutubePlayer.default()));
if ($(".video-teaser").length !== 0) import('teaser/video_teaser').then(VideoTeaser => (new VideoTeaser.default()).init());
if ($(".mobile-service-cta").length !== 0) import('utils/sticky_mobile_service_helper').then(StickyMobileServiceHelper => (new StickyMobileServiceHelper.default()).init());
if ($(".header-nav__menu-item").length !== 0) import('./components/navigation_header').then(NavigationHeader => new NavigationHeader.default());
if ($(".js-header-account-flyout-trigger").length !== 0) import('./components/header_login_handler').then(HeaderLoginHandler => new HeaderLoginHandler.default());
if ($(".js-accordion").length !== 0) import('./components/accordion').then(Accordion => new Accordion.default());
if ($(".category-topseller-teaser").length !== 0) import("./teaser/category_topseller_teaser").then(CategoryTopSellerTeaser => (new CategoryTopSellerTeaser.default()).init());
if ($(".brand-ribbon-teaser").length !== 0) import("./teaser/brand_ribbon_teaser").then(BrandRibbonTeaser => (new BrandRibbonTeaser.default()).init());
if ($(".js-category-landing-container").length !== 0) import("./teaser/category_thumbnail_teaser").then(CategoryThumbnailTeaser => (new CategoryThumbnailTeaser.default()));
if ($("#xc_application").length !== 0) import("./teaser/excentos_guided_selling_teaser").then(ExcentosGuidedSellingTeaser => (new ExcentosGuidedSellingTeaser.default()));
if ($(".js-page-teaser-emarsys-reco").length !== 0) import("teaser/emarsys_reco_teaser").then(EmarsysRecoTeaser => (new EmarsysRecoTeaser.default().initPageRecoTeaser()));


new General();
new TrackingHelper();
new TeaserValidator();

ViewPromotionTracker();
SelectPromotionTracker();

PubSub.publish("core.attach", document);

initialize();
