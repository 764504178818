import $ from "jquery";
import ProductListPaging from "components/product_list/product_list_paging";
import LocalStorageHelper from 'utils/local_storage_helper';

var NEXT_PAGE_ID = "#pagingButtonId";
var ADS_LINK_CLASS = "a.js-ads-link";
var PAGE_DATA = "page";
var POSITION_KEY = "productListPositionY";
var PAGE_KEY = "productPage";
var NEXT_PAGE_DATA_KEY = "nextPageData";

export default function ProductListingKeepPositionHelper() {
  this.localStorageHelper = new LocalStorageHelper();
  this.productListPaging = new ProductListPaging();
}

ProductListingKeepPositionHelper.prototype.init = function () {
  const position = this.localStorageHelper.getValue(POSITION_KEY);
  if (position != null) {
    $(document).scrollTop(position);
    this.localStorageHelper.removeValue(POSITION_KEY);
    setCookie("productListingPage", "", -1);
    const data = JSON.parse(this.localStorageHelper.getValue(NEXT_PAGE_DATA_KEY));
    const np = $(NEXT_PAGE_ID);
    np.data("page", data.page);
    np.attr("data-page", data.page);
    np.data("paging-url", data.pagingUrl);
    np.attr("data-paging-url", data.pagingUrl);
    np.data("paging-max-page", data.pagingMaxPage);
    np.attr("data-paging-max-page", data.pagingMaxPage);
    np.data("result-count", data.resultCount);
    np.attr("data-result-count", data.resultCount);
    np.data("objectsperpage", data.objectsPerPage);
    np.attr("data-objectsperpage", data.objectsPerPage);
    np.data("max-page", data.maxPage);
    np.attr("data-max-page", data.maxPage);
    this.localStorageHelper.removeValue(NEXT_PAGE_DATA_KEY);
  }

  addClickHandler(this.localStorageHelper);
  const _this = this;
  $(document).ajaxComplete(function () {
    addClickHandler(_this.localStorageHelper);
  })
};

ProductListingKeepPositionHelper.prototype.initClearHandler = function () {
  addClearClickHandler(this.localStorageHelper);
  const _this = this;
  $(document).ajaxComplete(function () {
    addClearClickHandler(_this.localStorageHelper);
  })
};

function addClickHandler(localStorageHelper) {
  const adsLinkHandler = function () {
    const value = $(document).scrollTop();
    localStorageHelper.setValue(POSITION_KEY, value);
    const page = localStorageHelper.getValue(PAGE_KEY);
    setCookie("productListingPage", page, 1);
    const np = $(NEXT_PAGE_ID);
    const data = {
      page: np.data("page"),
      pagingUrl: np.data("paging-url"),
      pagingMaxPage: np.data("paging-max-page"),
      resultCount: np.data("result-count"),
      objectsPerPage: np.data("objectsperpage"),
      maxPage: np.data("max-page")
    };
    localStorageHelper.setValue(NEXT_PAGE_DATA_KEY, JSON.stringify(data));
  };
  $(ADS_LINK_CLASS).off("click", adsLinkHandler).on("click", adsLinkHandler);

  const nextPageHandler = function () {
    const page = Number($(this).data(PAGE_DATA)) + 1;
    localStorageHelper.setValue(PAGE_KEY, page);
  };
  $(NEXT_PAGE_ID).off("click", nextPageHandler).on("click", nextPageHandler);
}

function addClearClickHandler(localStorageHelper) {
  const clearKeepPositionHandler = function () {
    localStorageHelper.removeValue(NEXT_PAGE_DATA_KEY);
    localStorageHelper.removeValue(PAGE_KEY);
    localStorageHelper.removeValue(NEXT_PAGE_DATA_KEY);
    setCookie("productListingPage", "", -1);
  };
  const selectors = $(".category-list");
  selectors.off("click", clearKeepPositionHandler).on("click", clearKeepPositionHandler);
}

function setCookie(name, value, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = "expires=" + d.toUTCString();
  document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

