import $ from "jquery";
import PubSub from 'pubsub-js'
import FoldController from "components/fold_controller";
import ToggleController from "components/toggle_controller";
import ResponsiveProductBoxController from "components/product_list/responsive_product_box";
import FloatingHeader from "components/floating_header";
import MobileHeader from "components/mobile_header";
import PromotionStripe from "components/promotion_stripe";
import StageTeaser from "teaser/stage_teaser";
import EquipmentTeaser from "teaser/equipment_teaser";
import ThemenTeaser from "teaser/themen_teaser";
import TripleProductTeaser from "teaser/triple_product_teaser";
import BrandCategoryTeaser from "teaser/brand_category_teaser";
import GoogleTracking from "utils/google_tracking";
import FactFinderController from "utils/factfinderController";
import BrowserDetect from "utils/browserdetect";
import ResponsiveHelper from "utils/responsive_helper";
import ScrollPosition from "utils/scroll_position";
import HeaderNavigationController from "utils/navigation_tracker";
import SearchSuggest from "components/search_suggest";
import TeaserViewTracker from "../tracking/google/360/teaser_view_tracker";
import TeaserClickTracker from "../tracking/google/360/teaser_click_tracker";
import TeaserTrackingTrigger from "../tracking/google/360/teaser_tracking_trigger";
import ProductClickTracker from "../tracking/google/360/product_click_tracker";
import ProductViewTracker from "../tracking/google/360/product_view_tracker";
import TaxSwitch from "components/tax_switch";
import "teaser/emarsys_reco_teaser";
import "utils/language_select";
import "utils/lazyload.js";
import 'components/header_carts_handler';
import 'bootstrap';
import 'utils/ajaxforms.js';
// header_carts_handler muss hier eingebunden werden, damit das Javascript für die Warenkörbe auf jeder Seite existiert

export default function General() {
  PubSub.subscribe("core.attach", $.proxy(this.attach, this));
  PubSub.subscribe("res", $.proxy(this.resxs, this));
  new FoldController().init();
  new ToggleController().init();
  new ScrollPosition();
  this.responsiveProductBoxController = new ResponsiveProductBoxController();
  this.responsiveProductBoxController.init();
  new GoogleTracking().init();
  new FactFinderController().init();
  this.browserDetect = new BrowserDetect();
  this.browserDetect.init();
  this.searchSuggest = new SearchSuggest();
  this.searchSuggest.init();
  this.TeaserViewTracking = new TeaserViewTracker();
  this.TeaserViewTracking.initIntersectionObserver();
  this.teaserclicktracking = new TeaserClickTracker();
  this.teaserclicktracking.init();
  new TeaserTrackingTrigger().init();
  this.equipmentTeaser = new EquipmentTeaser();
  this.stageTeaser = new StageTeaser();
  this.themenTeaser = new ThemenTeaser();
  this.tripleProductTeaser = new TripleProductTeaser();
  new BrandCategoryTeaser().init();
  this.responsiveHelper = new ResponsiveHelper();
  new HeaderNavigationController().init();
  this.productlistClickTracking = new ProductClickTracker();
  this.productlistClickTracking.init();
  new ProductViewTracker().init();
  this.floatingHeader = new FloatingHeader();
  this.mobileHeader = new MobileHeader();
  this.mobileHeader.init();
  this.promotionStripe = new PromotionStripe();
  this.promotionStripe.init();
  var _this = this;

  $(document).ajaxComplete(function (event, request, settings) {
    var sourceUrl = settings.url;
    var noTracking = sourceUrl.indexOf("noTracking") >= 0;

    if (!noTracking) {
      _this.responsiveHelper.republishResizeEvent();
      _this.productlistClickTracking.init();
      _this.teaserclicktracking.init();
    }
  });

  this.scrollTracking();
  TaxSwitch();
}

General.prototype = {
  attach: function (msg, element) {
    /*
     * IE Fix: KOSMAAGILE-2755
     *
     * IE crasht, wenn eine Validierung in einem Formular mit Radio-Buttons fehlschlägt.
     * Ursache sind die gleichen 'name' attribute.
     *
     */

    if (this.browserDetect.browser === "Explorer") {
      this.fixIeRadioButtonBug();

      $(document).ajaxComplete(this.fixIeRadioButtonBug);
    }

    $("li.language-selection > a").tooltip();

    if (!navigator.userAgent.match(/mobile/i)) {
      var tels = $("a[href^='tel:']");
      tels.each(function () {
        var content = $(this).html();
        $(this).parent().html(content);
      });
    }

    $(".text-tooltip")
        .popover({
          placement: "top",
          html: true,
          trigger: "hover",
        })
        .off("shown.bs.popover.text")
        .on("shown.bs.popover.text", function () {
          var popover = $(this).next();
          $("body > *").on("click.popclose", function (e) {
            $("body > *").off("click.popclose");
            popover.popover("hide");
          });
          //Move 5px up
          popover.css({
            top: parseInt($(this).next().css("top"), 10) - 5 + "px",
          });
        });

    //Add classes to accordions
    var addClasses = function (e) {
      $(this)
          .parents(".panel")
          .find(".panel-collapse")
          .each(function () {
            if ($(this).hasClass("in")) {
              $(this).prev().addClass("panel-in");
            } else {
              $(this).prev().removeClass("panel-in");
            }
          });
      if (e.type === "show") {
        $(this).prev().addClass("panel-in");
      }
    };
    $(".collapse")
        .off("show.bs.collapse.classes hidden.bs.collapse.classes")
        .on("show.bs.collapse.classes hidden.bs.collapse.classes", addClasses);
    $(".collapse.in").trigger("show.bs.collapse.classes");

    /*
     * Im unteren Teil der ADS soll im Accordion stets ein Tab geöffnet sein. Insbeonsondere soll z.B. bei
     * Links im oberen Teil der ADS auf den Leasing-Rechner dieser nicht wieder einklappen, wenn er vorher
     * ausgeklappt war.
     */
    $(".panel-heading a").on("click", function (e) {
      if ($($(this).attr("href")).hasClass("in")) {
        e.stopPropagation();
      }
    });

    $(document)
        .off("click", "a[data-accordion]")
        .on("click", "a[data-accordion]", function () {
          var target = $(this).data("accordion");
          $(target).each(function () {
            if ($(this).is(":visible")) {
              $(this).trigger("click");
            }
          });
        });

    //Hide alerts, not remove
    $(".alert .close")
        .off("click")
        .on("click", function (e) {
          e.preventDefault();
          $(this).parent().hide();
        });

    //Modals, several fixes
    $(".modal")
        .off("show.bs.modal")
        .on("show.bs.modal", function () {
          $("#page input:not(:disabled):not('.js-quantity-field'), #page select:not(:disabled):not('.js-quantity-field')")
              .addClass("twerked")
              .prop("disabled", true);

          var measureScrollBar = function () {
            var scrollDiv = document.createElement("div");
            scrollDiv.style.cssText =
                "width: 1px;height: 1px;overflow: scroll;position: absolute;top: -9999px;";
            document.body.appendChild(scrollDiv);
            var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
            document.body.removeChild(scrollDiv);
            return scrollbarWidth;
          };
          if (navigator.userAgent.match(/iPhone|iPad|iPod|Windows Phone/i)) {
            $(this).css({
              position: "absolute",
              marginTop: $(window).scrollTop() + "px",
              bottom: "auto",
            });
            $("html").addClass("modal-open");

            setTimeout(function () {
              $(".modal-backdrop").css({
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: $(document).height() + "px",
              });
            }, 0);
          } else {
            $("html").addClass("modal-open");
          }

          if (this.clientHeight <= window.innerHeight) {
            return;
          }
          var scrollbarWidth = measureScrollBar();
          if (scrollbarWidth) {
            $(document.body).css("padding-right", scrollbarWidth);
          }
        })
        .off("hidden.bs.modal")
        .on("hidden.bs.modal", function () {
          $("#page .twerked").removeClass("twerked").prop("disabled", false);
          $("html").removeClass("modal-open");
          $(document.body).css("padding-right", 0);
        });

    var modalClose = function () {
      $(this).closest(".modal").modal("hide");
    };
    $(".modal .modal-close-button").off("click", modalClose).on("click", modalClose);

    //bei Mouseover über ein Bild das dazugehörige Textfeld unterstreichen
    var hoverFields = ".image-link, a>img, a>.navi-badge, .js-trustbox-item-image, .themeworld-item-image";
    var wrapperSelectors = ".product-grid-item, .teaser-item, .cart-info-left, .navi-step, .trustbox-item, .themeworld-item";
    var targets = ".item-name, .text>span, .js-trustbox-item-link, .arrow-link-bold";

    const mouseEnterFunc = function () {
      $(this).closest(wrapperSelectors)
          .find(targets)
          .css("text-decoration", "underline");
    };
    const mouseLeaveFunc = function () {
      $(this).closest(wrapperSelectors)
          .find(targets)
          .css("text-decoration", "");
    };
    $(hoverFields).on("mouseenter", mouseEnterFunc)
        .on("mouseleave", mouseLeaveFunc);

    if (this.browserDetect.os === "iOS" || this.browserDetect.os === "Android") {
      $("body").addClass("touch");
    }

    //Printing fix for IE
    if (this.browserDetect.browser === "Explorer") {
      window.onafterprint = function () {
        $("body").mouseover(function () {
          location.reload();
        });
      };
    }

    // Newsletter Layer Fixed Position Width fix
    var newsletterLayerFixedPosition = $("#newsletter-layer-fixed-position");
    if (newsletterLayerFixedPosition &&
        !newsletterLayerFixedPosition.hasClass("hidden") &&
        !newsletterLayerFixedPosition.attr("style")) {
      var width = newsletterLayerFixedPosition.outerWidth();
      var roundedWidth = Math.ceil(width);
      if (this.browserDetect.mobile) {
        if (roundedWidth % 2 === 0) {
          newsletterLayerFixedPosition.css("width", roundedWidth + 12);
        } else {
          newsletterLayerFixedPosition.css("width", roundedWidth + 11);
        }
      } else {
        if (roundedWidth % 2 === 0) {
          newsletterLayerFixedPosition.css("width", roundedWidth + 2);
        } else {
          newsletterLayerFixedPosition.css("width", roundedWidth + 1);
        }
      }
    }

    $(".scoring-information").each(function () {
      var html = $(this).html();

      if ($(this).data("patched") !== "true") {
        var newHtml = html
            .split("<br>")
            .join("")
            .split("nobr")
            .join("li")
            .split("(")
            .join("<span class='scoring-description'>")
            .split(")")
            .join("</span>");

        var myRegexp = /((\+ Score = )\d+)/g;
        match = myRegexp.exec(html);
        while (match != null) {
          // matched text: match[0]
          // match start: match.index
          // capturing group n: match[n]
          var oldText = match[0];
          var number = Number(oldText.replace("+ Score = ", ""));
          var newNumber = number / 100000;
          var newText = "+ Score = " + newNumber;
          newHtml = newHtml.replace(oldText, newText);
          match = myRegexp.exec(html);
        }

        var html = $(this).html("<ul>" + newHtml + "</ul>");
        $(this).data("patched", "true");
      }
    });

    // begin category teaser
    var $accordions = $(".category-box-accordion");
    $accordions.each(function () {
      if (!$(this).hasClass("disabled")) {
        $(this)
            .off("click")
            .on("click", function () {
              var $box = $(this).closest(".category-box");
              var $content = $box.find(".category-box-content");
              if ($box.hasClass("active")) {
                $box.removeClass("active");
                $content.css("max-height", 0);
              } else {
                var $siblings = $box.siblings();
                $siblings.removeClass("active");
                $siblings.find(".category-box-content").css("max-height", 0);
                var contentHeight = 0;
                $content.children().each(function () {
                  contentHeight += $(this).outerHeight(true);
                });
                $content.css("max-height", contentHeight);
                $box.addClass("active");
              }
            });
      }
    });
    // end category teaser

    // Security! Secure external links with target="_blank" by adding "noopener" "noreferrer" to "rel" attribute
    const neededAttributes = ["noopener", "noreferrer"];
    $("a[target='_blank']").each(function () {
      const relAttributes = $(this).attr("rel");
      if (relAttributes) {
        const splittedAttributes = relAttributes.split(" ");
        $(neededAttributes).each(function (index, item) {
          if (splittedAttributes.indexOf(item) === -1) {
            splittedAttributes.push(item);
          }
        })
        $(this).attr("rel", splittedAttributes.join(" "));
      } else {
        $(this).attr("rel", neededAttributes.join(" "));
      }
    })
  },
  scrollTracking: function () {
    var _this = this;
    var scrollEvent = function () {
      _this.floatingHeader.scrollHandler();
    };
    $(document).off("scroll", scrollEvent).on("scroll", scrollEvent);
  },
  resxs: function (message, onoff) {
    if (onoff) {
      this.responsiveProductBoxController.responsive(message, onoff);
      this.addEllipsis();
      const mouseEnterFunc = function () {
        $(".carousel-control", this).addClass("hover");
      };
      const mouseLeaveFunc = function () {
        $(".carousel-control", this).removeClass("hover");
      };

      $(".action-stage > div").eq(1).on("mouseenter", mouseEnterFunc).on("mouseleave", mouseLeaveFunc)
    }
    this.equipmentTeaser.responsive(message, onoff);
    this.stageTeaser.responsive(message, onoff);
    this.themenTeaser.responsive(message, onoff);
    this.searchSuggest.responsive(message, onoff);
    this.promotionStripe.responsive(message, onoff);
    if ($(".dreiklang-teaser").length !== 0) import("teaser/dreiklang_teaser").then(DreiklangTeaser => (new DreiklangTeaser.default()).responsive(message, onoff));
  },
  openHash: function () {
    var regex = /(_[0-9]{0,})$/;
    if (document.location.hash && regex.test(document.location.hash)) {
      var matches = document.location.hash.match(regex);
      var _i = matches[0];

      $(".panel-heading")
          .not(".panel-in")
          .find("a[href=#collapse" + _i + "]")
          .trigger("click");
    }
  },
  addEllipsis: function () {
    var width = $(window).width();

    if (767 < width && width < 1024) {
      $(".product-grid-style .item-name a").each(function () {
        var itemName = $(this);
        var max = 65;
        if (itemName.html().length > max) {
          var newHtml = itemName.html().substring(0, max - 4) + " ...";
          itemName.html(newHtml);
        }
      });
    } else if (width >= 1024) {
      $(".product-grid-style .item-name a").each(function () {
        var itemName = $(this);
        var max = 90;
        if (itemName.html().length > max) {
          var newHtml = itemName.html().substring(0, max - 4) + " ...";
          itemName.html(newHtml);
        }
      });
    }
  },
  fixIeRadioButtonBug: function () {
    $("input[type='radio']").each(function (index, element) {
      var radio = $(element);
      if (radio.data("radio-group") === undefined) {
        var groupName = radio.attr("name");
        radio.data("radio-group", groupName);
        radio.attr("data-radio-group", groupName);
        radio.data("radio-group-index", index);
        radio.attr("data-radio-group-index", index);

        if (index !== 0) {
          radio.attr("name", groupName + "-" + index);
        }

        radio.on('click', function () {
          var thisGroupName = radio.data("radio-group");
          var thisName = radio.attr("name");
          var otherGroupElements = $(
              "input[data-radio-group='" + thisGroupName + "']"
          );
          otherGroupElements.each(function () {
            var otherElement = $(this);
            var otherElementId = otherElement.data("radio-group-index");
            if (thisName !== otherElement.attr("name")) {
              otherElement.prop("checked", false);
              otherElement.attr("name", thisGroupName + "-" + otherElementId);
            }
          });
          $(this).attr("name", thisGroupName);
        });
      }
    });
  },
};
