/**
 * helper to manually trigger teaser tracking events
 */
import $ from "jquery";
import TeaserViewTracker from './teaser_view_tracker';
import {trackElement} from "../ga4/view_promotion_tracker";

export default function TeaserTrackingTrigger() {
  this.TeaserViewTracking = new TeaserViewTracker();
}

TeaserTrackingTrigger.prototype = {
  init: function () {
    const elements = $(".js-teasertrackingtrigger");
    const _this = this;
    elements.find(".modal").each(function () {
      $(this).on("shown.bs.modal", function (e) {
        const teaser = $(this).closest(".js-teasertracking");
        _this.TeaserViewTracking.forceTrackingOfTeaser(teaser);
        trackElement(teaser[0]);
      });
    });
  }
}