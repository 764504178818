import {NAMESPACE} from "./events";

export const mapGA4Event = (eventTemplate, defaultAttributes, preferentialData = {}) => {
  const eventBody = eventTemplate["event_params"];
  const eventKeyParams = Object.keys(eventBody);
  for (const param of eventKeyParams) {
    eventBody[[param]] = getOrDefault(eventBody[param], preferentialData, defaultAttributes);
  }
}

export const mapGA4EventWithItem = (eventTemplate, defaultAttributes, preferentialData = {}) => {
  mapGA4Event(eventTemplate, defaultAttributes, preferentialData);
  const eventBody = eventTemplate["event_params"];
  eventBody["items"] = [mapGA4Items(defaultAttributes, preferentialData)];
}

export const mapGA4Items = (attributes, preferentialData = {}) => {
  return {
    item_id: getOrDefault("product-id", preferentialData, attributes),
    item_name: getOrDefault("product-name", preferentialData, attributes),
    konfigurationsID: getOrDefault("configuration-id", preferentialData, attributes),
    affiliation: getOrDefault("shop-brand", preferentialData, attributes),
    currency: getOrDefault("currency", preferentialData, attributes),
    discount: parseFloat(getOrDefault("discount-numeric", preferentialData, attributes)) || undefined,
    index: parseInt(getOrDefault("position-index", preferentialData, attributes)) || undefined,
    item_brand: getOrDefault("product-brand", preferentialData, attributes),
    item_category: getOrDefault("category-0", preferentialData, attributes),
    item_category2: getOrDefault("category-1", preferentialData, attributes),
    item_category3: getOrDefault("category-2", preferentialData, attributes),
    item_category4: getOrDefault("category-3", preferentialData, attributes),
    item_category5: getOrDefault("category-4", preferentialData, attributes),
    item_list_name: getOrDefault("item-list-name", preferentialData, attributes),
    item_list_id: getOrDefault("item-list-id", preferentialData, attributes),
    item_variant: getOrDefault("item-id", preferentialData, attributes),
    price: parseFloat(getOrDefault("price-numeric", preferentialData, attributes)) || undefined,
    quantity: parseInt(getOrDefault("quantity", preferentialData, attributes)) || undefined,
    creative_name: getOrDefault("creative-name", preferentialData, attributes),
    creative_slot: getOrDefault("creative-slot", preferentialData, attributes),
    promotion_id: getOrDefault("promotion-id", preferentialData, attributes),
    promotion_name: getOrDefault("promotion-name", preferentialData, attributes)
  };
}

const getOrDefault = (key, additionalData, defaultData) => {
  const nameSpaceKey = NAMESPACE + "-" + key;
  const attributeValue = additionalData[nameSpaceKey] ? additionalData[nameSpaceKey] : defaultData[nameSpaceKey];
  if (typeof attributeValue !== 'string') {
    return attributeValue;
  }
  return attributeValue && attributeValue.trim().length > 0 ? attributeValue : undefined;
}