/**
 * Kennt die Aufläsungen für die ganzen Breakpoints. Wirft Events, sobald ein Breakpoint erreicht ist und kann auch gefragt werden, welcher Breakpoint kleiner welchem ist.
 */
//noinspection JSHint
import $ from "jquery";
import PubSub from 'pubsub-js';
import enquire from 'enquire.js';

/**
 * In dieser Variablen wird die aktuelle Auflösung gespeichert
 */
var currentResolution = "";

/**
 * In diesem Array werden alle Auflösungen gespeichert. Die Reihenfolge ist dabei wichtig, d.h. eine kleinere Auflösung
 * sollte weiter links in dem Array stehen.
 */
var orderedResolutions = [];

function registerResolution(enquireString, resolution) {
  orderedResolutions.push(resolution);
  enquire.register(enquireString, {
    match: function () {
      PubSub.publish(resolution, true);
      currentResolution = resolution;
    },
    unmatch: function () {
      PubSub.publish(resolution, false);
    }
  });
}

export function initialize() {
  //Register resolutions. Reihenfolge ist wichtig, da damit das Array orderedResolutions aufgebaut wird
  orderedResolutions = [];
  registerResolution("screen and (max-width:767px)", "res.xs");
  registerResolution("screen and (min-width:768px) and (max-width:991px)", "res.sm");
  registerResolution("screen and (min-width:992px) and (max-width:1199px)", "res.md");
  registerResolution("screen and (min-width:1200px)", "res.lg");
}

export function isCurrentResolutionLowerOrEqual(resolution) {
  var currentResolutionPosition = $.inArray(currentResolution, orderedResolutions);
  var resolutionPosition = $.inArray(resolution, orderedResolutions);
  return currentResolutionPosition > -1 && currentResolutionPosition <= resolutionPosition;
}

