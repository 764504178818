import $ from   "jquery";
export default function FloatingHeader() {
  }

  FloatingHeader.prototype.scrollHandler = function () {
    var $floatHead = $(".js-header-sticky");
    if ($floatHead.length > 0) {
      var floatingHeaderStart = $(".js-nav-main").offset().top + $(".js-nav-main").height();
      var scrollPosition = $(window).scrollTop();
      if (scrollPosition > floatingHeaderStart) {
        $floatHead.addClass("header-nav--sticky-active");
      } else {
        $floatHead.removeClass("header-nav--sticky-active");
        if ($floatHead.css("top") == "0") {
          setTimeout(function () {
            if (scrollPosition > floatingHeaderStart) {
              $floatHead.addClass("header-nav--sticky-active");
            }
          }, 1);
        }
      }
    }
  };
