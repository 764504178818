import $ from "jquery";


var NO_QTY_DISCOUNT = "no_qty_discount";


export default function AdsQuantityChange() {
}

function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

function addChangeListener() {
  var currentUrl = window.location.href;

  $("input#quantity").on("change", function () {
    var quantity = $(this).val();
    var indexOfNoQtyDiscount = currentUrl.indexOf(NO_QTY_DISCOUNT);
    if (indexOfNoQtyDiscount > 0) {
      var firstPart = currentUrl.substring(0, indexOfNoQtyDiscount + NO_QTY_DISCOUNT.length);
      var lastPart = currentUrl.substring(indexOfNoQtyDiscount + NO_QTY_DISCOUNT.length);
      if (currentUrl.charAt(indexOfNoQtyDiscount + NO_QTY_DISCOUNT.length) === "=") {
        var oldValueLength = 1;
        for (i = 1; i < lastPart.length; i++) {
          if (isNumeric(currentUrl.charAt(indexOfNoQtyDiscount + NO_QTY_DISCOUNT.length + i))) {
            oldValueLength++;
          } else {
            break;
          }
        }
        lastPart = currentUrl.substring(indexOfNoQtyDiscount + NO_QTY_DISCOUNT.length + oldValueLength);
      }
      var ajaxUrl = firstPart + "=" + quantity + lastPart;

      $.get(ajaxUrl, function (data) {
        var resultHtml = $.parseHTML(data);
        var newFormHtml = $(resultHtml).find("form.js-ads-form").html();
        $("form.js-ads-form").html(newFormHtml);
        window.history.pushState("", document.title, ajaxUrl);
      });
    }
  });
}

AdsQuantityChange.prototype.init = function () {
  $(document).ready(addChangeListener).ajaxComplete(addChangeListener);
};

function createDoneFunction() {
  return function () {
    $(document).trigger("ads-article-content-replaced-by-ajax-result");
  };
}
