import 'core-js/es6'
import "regenerator-runtime/runtime.js"

window.fetch || import(/* webpackChunkName: "polyfills" */ 'whatwg-fetch')
typeof URL === "function" || import(/* webpackChunkName: "polyfills" */ 'url-polyfill');

function fromEntries(entries) {
  var res = {};
  for (var i = 0; i < entries.length; i++) res[entries[i][0]] = entries[i][1];
  return res;
}

if (!Object.fromEntries) Object.fromEntries = fromEntries;