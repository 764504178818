import $ from "jquery";

export const ADD_TO_CART = 'EECaddToCart';
export const REMOVE_FROM_CART = 'EECremoveFromCart';
export const PRODUCT_DETAIL_VIEW = 'EECproductDetailView';
export const PROMOTION_IMPRESSION = 'EECpromotionImpression';
export const PROMOTION_CLICK = 'EECpromotionClick';
export const PRODUCT_CLICK = 'EECproductClick';
export const PRODUCT_IMPRESSION = 'EECproductImpression';
export const ADD_TO_WISHLIST = 'EECaddToWishlist';
export const REMOVE_FROM_WISHLIST = 'EECremoveFromWishlist';
export const UNKNOWN = "unknown";

export const ADD = "add";
export const REMOVE = "remove";

export const getEventByOrigin = (eventType) => {
  if (eventType === ADD) {
    return ADD_TO_CART;
  } else if (REMOVE) {
    return isWishlistPage() ? REMOVE_FROM_WISHLIST : REMOVE_FROM_CART;
  } else {
    return UNKNOWN;
  }
}

function isWishlistPage() {
  return $(".wishlist-page").length !== 0;
}

export const Events = {
  [ADD_TO_CART]: {
    'type': ADD,
    'params': [
      'productid',
      'id',
      'title',
      'price',
      'brand',
      'category',
      'quantity',
      'currency',
      'dimension14',
      'dimension15',
      'accessory'
    ]
  },
  [ADD_TO_WISHLIST]: {
    'type': ADD,
    'params': [
      'productid',
      'id',
      'title',
      'price',
      'brand',
      'category',
      'quantity',
      'currency',
      'dimension14',
      'dimension15'
    ]
  },
  [REMOVE_FROM_CART]: {
    'type': REMOVE,
    'params': [
      'productid',
      'id',
      'title',
      'price',
      'brand',
      'category',
      'quantity',
      'currency',
      'dimension14',
      'dimension15'
    ]
  },
  [REMOVE_FROM_WISHLIST]: {
    'type': REMOVE,
    'params': [
      'productid',
      'id',
      'title',
      'price',
      'brand',
      'category',
      'quantity',
      'currency',
      'dimension14',
      'dimension15'
    ]
  },
  [PRODUCT_DETAIL_VIEW]: {
    'type': 'detail',
    'params': [
      'productid',
      'id',
      'title',
      'price',
      'brand',
      'category',
      'currency',
      'bcWareTitles',
      'accessory'
    ]
  },
  [PRODUCT_CLICK]: {
    'type': 'click',
    'params': [
      "productid",
      'id',
      "title",
      "price",
      "brand",
      "category",
      'currency',
      "position",
      'list',
      'accessory'
    ]
  }
}
