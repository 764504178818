import {getAllAttributes, getItemDataInParent, teaserWasTracked} from "../tracking_helper";
import {GA4_SELECT_PROMOTION, getSelectPromotionEventTemplate, NAMESPACE} from "./events";
import {mapGA4Event} from "./event_mapper";
import {publish} from "../event_publisher";

const addClickListener = (teaser) => {
  let lastClick = 0;

  const callback = (e) => {
    const currentTime = new Date().getTime();
    if (currentTime > (lastClick + 2000) && e.which != null) {
      trackElementClick(teaser);
      lastClick = currentTime;
    }
  };

  $(teaser).off("click." + NAMESPACE + ".promotion.select").on("click." + NAMESPACE + ".promotion.select", callback);
}

const trackElementClick = (targetElement) => {
  if (!targetElement || teaserWasTracked(targetElement, GA4_SELECT_PROMOTION)) {
    return;
  }

  const eventCopy = getSelectPromotionEventTemplate();

  mapGA4Event(eventCopy, getAllAttributes(targetElement));
  eventCopy["event_params"].items = getItemDataInParent(targetElement);
  publish(eventCopy);
}

export const SelectPromotionTracker = () => {
  document.querySelectorAll(".js-promotion-tracking, .js-promotion-swiper")
      .forEach(teaser => addClickListener(teaser));
}