import $ from "jquery";

const imageIntersectionObserverConfig = {
  rootMargin: '50px 0px'
};

const lazyLoad = (entries, _observer) => {
  for (const entry of entries) {
    if (entry.isIntersecting && entry.intersectionRatio > 0) {
      lazyLoadImage(entry.target);
    }
  }
};

const lazyLoadImage = (image) => {
  const src = image.getAttribute("data-src");
  const srcSet = image.getAttribute("data-srcset");
  if (srcSet) {
    image.setAttribute("srcset", srcSet);
    image.removeAttribute("data-srcset");
  }
  if (src) {
    image.setAttribute("src", src);
    image.removeAttribute("data-src");
  }
  imageIntersectionObserver.unobserve(image);
};

const imageIntersectionObserver = new IntersectionObserver(lazyLoad, imageIntersectionObserverConfig);

export const initLazyLoad = () => {
  const images = document.querySelectorAll("img[data-src]:not(.nav-image)");
  for (const img of images) {
    imageIntersectionObserver.observe(img);
  }
}

initLazyLoad();

const createLazyLoadTriggerFunction = (imageSelector, triggerElementSelector, eventName) => {
  return (_event) => {
    const triggerImages = document.querySelectorAll(imageSelector);
    for (const img of triggerImages) {
      lazyLoadImage(img);
    }
    $(triggerElementSelector).off(eventName);
  }
}

// Die Bilder in der Navigation sollen erst geladen werden, wenn die Navigation das erste Mal ausgeklappt wird
const lazyLoadNaviImages = createLazyLoadTriggerFunction(".nav-image", ".js-nav-assortment-category", "mouseenter.nav-img.lazy-load");
$(".js-nav-assortment-category").off("mouseenter.nav-img.lazy-load").on("mouseenter.nav-img.lazy-load", lazyLoadNaviImages);

const lazyLoadCartFlyoutImages = createLazyLoadTriggerFunction(".cart-flyout__image img", ".js-basket-count", "mouseenter.cart-flyout-img.lazy-load");
$(".js-basket-count").off("mouseenter.cart-flyout-img.lazy-load").on("mouseenter.cart-flyout-img.lazy-load", lazyLoadCartFlyoutImages);

// Workaround für die Swiper (d.h. aktuell kein Lazy-Loading für die Swiper)
// TODO: Entfernen, sobald ein neues Swiper-Plugin integriert wurde!
const swipeImages = document.querySelectorAll(".swipe-wrap img[data-src]:not(.swiper-lazy), .swipe-wrap source[data-srcset]");
for (const swipeImg of swipeImages) {
  lazyLoadImage(swipeImg);
}

export const fixAjaxSwiperImages = (newSwiperImages) => {
  for (const newSwipeImg of newSwiperImages) {
    imageIntersectionObserver.observe(newSwipeImg);
  }
}
