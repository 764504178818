import $ from "jquery";
import {
  addToCartTeaserHandler,
  addToWishlistTeaserHandler,
  removeTeaserOnClosedModal,
  setupAddToWishlistTeaser
} from "components/wishlist_teaser";
import header_carts_handler from "components/header_carts_handler";
import {installOnTrigger} from "utils/ajaxforms";
import {publish} from "../tracking/google/event_publisher";

const config = {
  selectors: {
    addToWishlist: ".js-add-to-wishlist",
    addToWishlistSalesbox: ".js-add-to-wishlist-salesbox",
    addToWishlistTeaserId: "#wishlist-teaser-",
    wishlistCountContainer: ".js-wishlist-count-container",
    wishlistCount: ".js-wishlist-count",
    quantityField: ".js-quantity-field",
    productItem: ".js-product-item",
    removeFromWishlist: ".js-remove-from-wishlist",
    updateWishlistItem: ".js-update-wishlist-item",
    moveToBasket: ".js-move-to-basket",
    wishlistTokenEl: "input[name='csrf_token']",
    wishlistHeader: ".js-wishlist-header-position",
    pageContainer: ".container"
  },
  modifiers: {
    isActive: "header-nav__service-item--full-wishlist",
  },
  endpoints: {
    wishlist: window.shopConfig.wishlistRestUrl,
    wishlistToCart: window.shopConfig.wishlistToCartRestUrl
  }
};

const updateWishlistCount = (count) => {
  $(config.selectors.wishlistCountContainer).addClass(config.modifiers.isActive);
  $(config.selectors.wishlistCount).text(count);
};

const updateWishlistTitle = (count) => {
  $(config.selectors.wishlistHeader).text(count);
}

const requestHeaders = {
  "Accept": "application/json",
  "Content-Type": "application/json"
};

const fetchWishlist = async (url, method, body) => {
  const response = await fetch(url, {
    method: method,
    headers: requestHeaders,
    body: JSON.stringify(body)
  });

  if (!response.ok) {
    const result = await response.json();
    throw Error(`${result.errorMessage}`);
  }

  return response.json();
};

const showWishlistError = (error) => {
  import("utils/toast_helper").then(ToastHelper => new ToastHelper.showError(error.message));
}

export const addToWishlist = (itemId, quantity, deleteFromBasket) => {
  return fetchWishlist(config.endpoints.wishlist, "POST", {itemId, quantity, deleteFromBasket});
};

export const removeFromWishlist = (itemId, quantity) => {
  return fetchWishlist(config.endpoints.wishlist, "DELETE", {itemId, quantity});
};

export const updateWishlistItem = async (itemId, quantity) => {
  return fetchWishlist(config.endpoints.wishlist, "PATCH", {itemId, quantity});
};

export const moveToBasket = async (itemId, quantity, csrf_token) => {
  return fetchWishlist(config.endpoints.wishlistToCart, "POST", {itemId, quantity, csrf_token});
};

const wishlistUpdateResultFunction = ($inputElement, result) => {
  result.trackingData && publish(result.trackingData);
  $inputElement.parents('.js-product-item').find('.js-wishlist-total-price').text(result.positions[0].priceData.total);
}

export const WishlistController = () => {
  $(document).off('change.wishlist.quantity.input').on('change.wishlist.quantity.input', '.js-wishlist-quantity', function (e) {
    let isWishlist = $(this).hasClass('js-wishlist-quantity');
    let quantity = $(this).val();
    if (quantity > 0 && isWishlist) {
      let itemId = $(this).data("itemId");
      updateWishlistItem(itemId, quantity)
          .then(result => wishlistUpdateResultFunction($(this), result))
          .catch(error => showWishlistError(error));
    }
  })

  $(config.selectors.addToWishlist).off("click.wishlist.rest.add").on("click.wishlist.rest.add", function () {

    const itemId = $(this).data("itemId");
    const quantity = $(this).data("quantity");
    const deleteFromBasket = ($(this).data("deleteFromBasket")) ? JSON.parse($(this).data("deleteFromBasket")) : false;

    addToWishlist(itemId, quantity, deleteFromBasket).then((res) => {
      res.trackingData && publish(res.trackingData);
      updateWishlistCount(res.total);
      if (deleteFromBasket) {
        installOnTrigger($(this));
        let cartCount = Number($(".js-service-count")[0].textContent.trim());
        header_carts_handler().shopCartHandler.input(cartCount, res.basketTotal);
        const wishlistTeaserId = config.selectors.addToWishlistTeaserId + $(this).data("productid");
        $(config.selectors.pageContainer).append($(wishlistTeaserId));
        removeTeaserOnClosedModal(wishlistTeaserId);
      }

      $(window).width() > 991 && setupAddToWishlistTeaser($(this));
    }).catch(error => showWishlistError(error));
  });

  $(config.selectors.addToWishlistSalesbox).off("click.wishlist.rest.add.salesbox").on("click.wishlist.rest.add.salesbox", function () {
    const itemId = $(this).data("itemId");
    const quantity = $(this).parents().find(config.selectors.quantityField).val();
    const deleteFromBasket = ($(this).data("deleteFromBasket")) ? JSON.parse($(this).data("deleteFromBasket")) : false;

    addToWishlist(itemId, quantity, deleteFromBasket).then((res) => {
      res.trackingData && publish(res.trackingData);
      updateWishlistCount(res.total);
      addToWishlistTeaserHandler($(this));
    }).catch(error => showWishlistError(error));
  });

  $(config.selectors.removeFromWishlist).off("click.wishlist.rest.remove").on("click.wishlist.rest.remove", function () {
    const itemId = $(this).data("itemId");
    const quantity = $(this).data("quantity");
    const productWrapper = $(this).parents(config.selectors.productItem);

    removeFromWishlist(itemId, quantity).then((res) => {
      if (res.total === 0) {
        location.reload();
      } else {
        updateWishlistCount(res.total);
        updateWishlistTitle(res.total);
        productWrapper.remove();
      }
    }).catch(error => showWishlistError(error));
  });

  $(config.selectors.moveToBasket).off("click.wishlist.rest.move.to.basket").on("click.wishlist.rest.move.to.basket", function () {
    const itemId = $(this).data("itemId");
    const quantity = $(this).attr("data-quantity");
    const csrf_token = $(config.selectors.wishlistTokenEl).val();

    moveToBasket(itemId, quantity, csrf_token).then((res) => {
      addToCartTeaserHandler($(this));
      header_carts_handler().shopCartHandler.input(null, res.basketTotal);
    }).catch(error => showWishlistError(error));
  });
}
