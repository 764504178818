import $ from "jquery";

export default function ScrollToTopHelper() {
}

ScrollToTopHelper.prototype.init = function () {
  // Animate the scroll to top
  $('.go-top').off("click").on("click", function (event) {
    event.preventDefault();
    $('html, body').animate({scrollTop: 0}, 300);
  });

  let lastScrollTop = window.pageYOffset || document.documentElement.scrollTop;

  var callback = function () {
    const offsetTop = window.pageYOffset || document.documentElement.scrollTop;

    if (offsetTop > 0 && offsetTop < lastScrollTop) {
      $('.go-top').fadeIn(200);
    } else {
      $('.go-top').fadeOut(200);
    }

    lastScrollTop = offsetTop <= 0 ? 0 : offsetTop;
  };

  // To top button
  // Show or hide the sticky footer button
  $(window).off("scroll.gotop", callback).on("scroll.gotop", callback);
};


