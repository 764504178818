import $ from 'jquery';
import {
  ADD,
  ADD_TO_CART,
  ADD_TO_WISHLIST,
  Events,
  getEventByOrigin,
  PRODUCT_CLICK,
  PRODUCT_DETAIL_VIEW,
  REMOVE,
  REMOVE_FROM_CART,
  REMOVE_FROM_WISHLIST
} from './events'
import * as TrackingEventPublisher from '../event_publisher'

export const TrackingListener = () => {

  $(document).off("click.360.tracking.product.click").on('click.360.tracking.product.click', '.js-second-hand-article', function (e) {
    mapEventAndPublish(PRODUCT_CLICK, $(e.target));
  })

  if ($('.art-detail-page').length !== 0) {
    const additionalParams = {
      "bcWareTitles": getBcWareTitles()
    }
    mapEventAndPublish(PRODUCT_DETAIL_VIEW, $('.js-add-to-cart'), additionalParams);
  }

  $(document).off('click.360.tracking.remove').on('click.360.tracking.remove', '.js-product-item-delete, .js-remove-from-wishlist', function (e) {
    const $targetElement = $(e.currentTarget).closest(".cart-product-interaction").siblings(".cart-product-amount").find(".js-quantity-field");
    const quantity = $targetElement ? $targetElement.val() : 1;
    mapEventAndPublish(getEventByOrigin(REMOVE), $(e.target), {quantity: quantity});
  })

  $(document).off('click.360.tracking.add.to.wishlist').on('click.360.tracking.add.to.wishlist', '.js-add-to-wishlist, .js-add-to-wishlist-salesbox', function (_e) {
    let input = $(this).parents().find('.form-trigger-on-change.js-quantity-field');
    mapEventAndPublish(ADD_TO_WISHLIST, input, {quantity: input.val() || 1});
    if ($(_e.target).data("delete-from-basket")) {
      mapEventAndPublish(getEventByOrigin(REMOVE), input, {quantity: input.val() || 1});
    }
  })

  $(document).off('click.360.tracking.add.to.cart').on('click.360.tracking.add.to.cart', '.add-to-cart', function (_e) {
    let input = $(this).closest('form').find('.js-quantity-field');
    if (input.length === 0) {
      input = $(this).closest('.cart-product').find('.js-quantity-field');
    }
    const quantity = input.val() || $(this).data('quantity') || 1;
    mapEventAndPublish(getEventByOrigin(ADD), $(this), {quantity: quantity});
  })

  $(document).off('change.360.tracking.quantity.input')
      .on('change.360.tracking.quantity.input', '.js-wishlist-quantity, .js-basket-quantity-field', function (e) {
        const isWishlist = $(this).hasClass('js-wishlist-quantity');
        const isBasket = $(this).hasClass('js-basket-quantity-field');

        const actualValue = $(this).val();
        if (actualValue && (isWishlist || isBasket)) {
          let eventType;
          let quantity;
          const previewValue = $(this)[0].dataset.quantity;
          if (actualValue < previewValue) {
            quantity = previewValue - actualValue;
            eventType = isWishlist ? REMOVE_FROM_WISHLIST : REMOVE_FROM_CART;
          } else {
            quantity = actualValue - previewValue;
            eventType = isWishlist ? ADD_TO_WISHLIST : ADD_TO_CART;
          }
          mapEventAndPublish(eventType, $(this), {quantity: quantity});
        }
      })

  $(document).off('click.360.tracking.reco.add.to.basket').on('click.360.tracking.reco.add.to.basket', '.js-reco-submit-btn', function (e) {
    mapEventAndPublish(getEventByOrigin(ADD), $(e.target), {quantity: 1});
  })

  function mapEventAndPublish(event, $element, additionalParams) {
    const productParams = {}
    const _additionalParams = additionalParams || {}
    const eventParams = Events[event].params
    eventParams.forEach((param) => productParams[param] = _additionalParams[param] === undefined ? $element.data(param) : _additionalParams[param]);

    if (eventParams.indexOf('quantity') > -1 && isValidInput($element)) {
      productParams.quantity = parseToInteger(_additionalParams.quantity === undefined ? $element.val() : _additionalParams.quantity);
      if (productParams.quantity !== -1) {
        TrackingEventPublisher.handleAndPublishTrackingEvent(productParams, event);
      }
    } else {
      TrackingEventPublisher.handleAndPublishTrackingEvent(productParams, event);
    }
  }

  function getBcWareTitles() {
    return $(".js-second-hand-article").map(function () {
      return $(this).data("title");
    }).get();
  }

  function isValidInput(input) {
    return input && !isNaN($(input).val());
  }

  function parseToInteger(input) {
    if (input < -2147483648 || input > 2147483647) {
      return -1;
    }

    let quantity = parseInt(input);
    if (isNaN(quantity)) {
      return -1
    }

    return Math.max(quantity, 0);
  }
}
