import $ from "jquery";
import "mmenu";
import "mmenu_polyfill";

export default function MobileHeader() {
}

function toggleHamburgerMenuIcon(status) {
  const hamburger = $(".js-activate-burger-menu");

  if (status === "open") {
    hamburger.addClass("menu--open");
  } else {
    hamburger.removeClass("menu--open");
  }

  toggleShowSearch();
}

function changeAnchorMode(status) {
  const hamburger = $(".js-activate-burger-menu");

  if (status === "open") {
    hamburger.attr("href", "#mobile-menu");
  } else {
    hamburger.attr("href", "#mm-0");
  }
}

function toggleShowSearch() {
  const searchFlyout = $('.search__flyout');
  const searchOverlay = $('.search__overlay');
  const searchFlyoutMmenuOpen = 'search__flyout--mmenu-opened';
  const searchOverlayMmenuOpen = 'search__overlay--mmenu-opened';

  if ($('body').hasClass('mm-wrapper_opened')) {
    searchFlyout.addClass(searchFlyoutMmenuOpen);
    searchOverlay.addClass(searchOverlayMmenuOpen);
  } else {
    searchFlyout.removeClass(searchFlyoutMmenuOpen);
    searchOverlay.removeClass(searchOverlayMmenuOpen);
  }

  $(".js-expand-navbar-searchbox").toggleClass("header-nav__inner--show-searchbar");
}

MobileHeader.prototype.init = function () {
  $(".js-toggle-navbar-searchbox").on("click", toggleShowSearch);

  //dont initialise if not needed / not on mobile
  if (window.innerWidth >= 991 || $("#mobile-menu").length === 0) return;

  new Mmenu(
    "#mobile-menu",
    {
      //options
      extensions: ["fullscreen"],
      offCanvas: {
        moveBackground: false,
      },
      backButton: {
        close: false,
      },
      hooks: {
        "open:before": function () {
          toggleHamburgerMenuIcon("open");
          changeAnchorMode("close");
        },
        "close:after": function () {
          toggleHamburgerMenuIcon("close");
          changeAnchorMode("open");
        },
      },
      navbar: false,
      navbars: [
        {
          position: "top",
          content: ["prev", "title", $(".mobile_menu__login")[0]],
        },
      ],
    },
    {
      //configutations
      offCanvas: {
        menu: {
          insertSelector: ".js-insert-mobile-nav",
        },
        page: {
          selector: "body > #page .container.special-padding",
        },
      },
    }
  );
};

