import {getViewItemEventTemplate} from "./events";
import {mapGA4EventWithItem} from "./event_mapper";
import {getAllAttributes, isInViewport} from "../tracking_helper";
import * as TrackingEventPublisher from "../event_publisher";


export const ViewItemListener = () => {
  const quickBuyLayer = document.querySelector(".standalone-sales-box__content-wrapper");
  const articleDetailsPage = document.querySelector("#art-detail-page");
  if ((quickBuyLayer && isInViewport(quickBuyLayer)) || (articleDetailsPage && isInViewport(articleDetailsPage))) {

    const target = document.querySelector('.js-add-to-cart') || document.querySelector('.price-request');
    if (target) {
      const eventCopy = getViewItemEventTemplate()
      mapGA4EventWithItem(eventCopy, getAllAttributes(target));
      TrackingEventPublisher.publish(eventCopy);
    }
  }
}