import LocalStorageHelper from 'utils/local_storage_helper';
import $ from "jquery";
import * as TrackingEventPublisher from '../event_publisher';
import {PRODUCT_CLICK} from './events';

export default function ProductClickTracker() {
}

ProductClickTracker.prototype.init = function () {
  this.localStorageHelper = new LocalStorageHelper();
  window.dataLayer = window.dataLayer || [];
  const lists = $(".js-productclick");
  lists.each(function () {

    // attempt to fix bug where "add to cart" button is not active on a layer
    import("components/product_list/responsive_product_box").then(ResponsiveProductBoxController => {
      new ResponsiveProductBoxController.default().init();
    });

    const list = $(this);
    let listName = list.data("list");
    const obj = window.dataLayer[0];
    if (obj !== undefined) {
      const breadcrumbs = obj.breadcrump;
      if (breadcrumbs !== undefined
          && (listName === "StageTeaser"
              || listName === "TripleProductTeaser"
              || listName === "Productlisting")) {
        listName = listName + breadcrumbs.replace(/\//g, "_");
      }
      if (listName.slice(-1, listName.length) === "_") {
        listName = listName.slice(0, -1);
      }
    }

    list.find(".js-eecproduct").each(function () {
      const product = $(this);
      let index = product.data("index");
      if (typeof index == "number") {
        index++; // increment to make tracking value start at 1 instead of 0
      }
      let _this = this;
      let clickHandler = function () {
        // hack to remove productlist-y-position before initiating psm page
        if ($(this).attr("href") !== undefined && $(this).attr("href").indexOf("pid=") > -1) {
          _this.localStorageHelper.removeValue("productListPositionY");
        }

        // actual intended clickHandler logic
        for (const dlElement of window.dataLayer) {
          if (dlElement.event != null && dlElement.event === PRODUCT_CLICK) {
            return;
          }
        }

        const params = {
          "title": product.data("productname"),
          "productId": product.data("productid"),
          "id": product.data("id"),
          "price": product.data("price"),
          "brand": product.data("brand"),
          "category": product.data("category"),
          "currency": product.data("currency"),
          "position": index,
          "list": listName,
          "accessory": product.data("accessory")
        }

        TrackingEventPublisher.handleAndPublishTrackingEvent(params, PRODUCT_CLICK);
      };

      product.find("a, button").each(function () {
        $(this).off("click.360.product.tracking", clickHandler).on("click.360.product.tracking", clickHandler);
      });
    });
  });
}
