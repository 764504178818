//noinspection JSHint

import $ from "jquery";

export default function ToggleController() {
}

ToggleController.prototype.init = function () {
  $(document).on('click', '.toggle-on-click', function () {
    var $trigger = $(this);
    var classToToggle = $trigger.data('toggle-class');
    var divToToggle = $trigger.data('toggle-div');
    $('#' + divToToggle + '').toggleClass(classToToggle);
  });
};
