import $ from "jquery";
import Swiper, {Lazy, Navigation, Thumbs} from 'swiper';
import 'utils/zoom.js';

Swiper.use([Navigation, Thumbs, Lazy]);

export default function AdsProductImagesGalleryController() {
}

AdsProductImagesGalleryController.prototype.init = function () {
  const navPrevEl = '.ads-gallery-swiper-element.swiper-button-prev';
  const navNextEl = '.ads-gallery-swiper-element.swiper-button-next';

  var hasThumbs = $('.ads-thumbs-swiper-container').length > 0;
  var thumbSwiper = {
    swiper: {
      el: '.ads-thumbs-swiper-container',
      watchOverflow: true,
      speed: 600,
      slidesPerView: 3,
      slidesPerGroup: 3,
      centeredSlides: true,
      centeredSlidesBounds: true,
      normalizeSlideIndex: true,
      watchSlidesVisibility: true,
      height: 200,
      direction: 'vertical'
    },
    slideThumbActiveClass: 'active',
    autoScrollOffset: 1
  };

  const adsSwiper = {
    watchOverflow: true,
    speed: 600,
    thumbs: hasThumbs ? thumbSwiper : undefined,
    watchSlidesVisibility: true,
    preloadImages: false,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1
    },
    navigation: {
      nextEl: navNextEl,
      prevEl: navPrevEl
    }
  };

  const teaserSwiper = {
    watchOverflow: true,
    speed: 600,
    preloadImages: false,
    watchSlidesVisibility: true,
    lazy: {
      loadPrevNext: true,
      loadPrevNextAmount: 1
    },
    navigation: {
      nextEl: navNextEl,
      prevEl: navPrevEl
    }
  }

  const $gallerySwipeContainer = $('.ads-gallery-swiper-container');
  if ($gallerySwipeContainer.length > 1) {
    $gallerySwipeContainer.last().addClass("teaser-gallery-swiper-container").removeClass("ads-gallery-swiper-container")
    new Swiper('.teaser-gallery-swiper-container', teaserSwiper);
    const $teaserSlideEl = $(".teaser-gallery-swiper-container").find(".swiper-slide");
    ($teaserSlideEl.length > 1 ? $(navNextEl).show() : $(navNextEl).hide());
    ($teaserSlideEl.length > 1 ? $(navPrevEl).show() : $(navPrevEl).hide());
  } else {
    new Swiper('.ads-gallery-swiper-container', adsSwiper);
    const $slideEl = $gallerySwipeContainer.find('.swiper-slide');
    ($slideEl.length > 1 ? $(navNextEl).show() : $(navNextEl).hide());
    ($slideEl.length > 1 ? $(navPrevEl).show() : $(navPrevEl).hide());
  }
};

AdsProductImagesGalleryController.prototype.initOnDomReady = function () {
  var _this = this;
  $(document).ready(function () {
    _this.init();
  });
  return this;
};

AdsProductImagesGalleryController.prototype.reinitAfterAjaxEvent = function (ajaxEventName) {
  var _this = this;
  $(document).on(ajaxEventName, function () {
    _this.init();
  });
  return this;
};

AdsProductImagesGalleryController.prototype.responsive = function (msg, on_off) {
  // noch gibt es hier nichts zu tun
};
