import $ from "jquery";
import PubSub from "PubSub";
import BrowserDetect from "utils/browserdetect";
import Validator from "../components/validation";

const msgKeys = window.shopConfig.jsMessages;
const shippingAddressCompanyPrefix = "CHECKOUT.REVIEW.ADDRESSFORM.COMPANY.NAME.LABEL."
const shippingAddressCompanyAdditionPrefix = "CHECKOUT.REVIEW.ADDRESSFORM.COMPANY.ADDITION.LABEL."

export default function Register() {
  PubSub.subscribe('core.attach', $.proxy(this.attach, this));
}

Register.prototype = {
  attach: function () {
    if ($('#vat-field').length !== 0) {
      $('.hasVatRadio').off('change').on("change", function () {
        var value = $(this).val() === 'true';
        $('#hasVAT-' + value).show();
        $('#hasVAT-' + !value).hide();
        $('#hasVAT2-' + value).show();
        $('#hasVAT2-' + !value).hide();
      });
    }

    function toggleShippingAddressLabels(suffix) {
      let $companyLabel = $('label[for="shippingCompanyName"]');
      if ($companyLabel[0]) {
        $companyLabel[0].textContent = msgKeys[shippingAddressCompanyPrefix + suffix]
      }
      let $companyAdditionLabel = $('label[for="shippingCompanyAdditions"]');
      if ($companyAdditionLabel[0]) {
        $companyAdditionLabel[0].textContent = msgKeys[shippingAddressCompanyAdditionPrefix + suffix]
      }
    }

    function reInitFormValidation() {
      $("form#createAccountModel").data('bootstrapValidator', null);
      new Validator().initOnDomReady("form#createAccountModel");
    }

    if ($('.company-data-for-private-customer').length !== 0) {
      $('#show-company-data-checkbox').on('click', function () {
        if ($(this).is(':checked')) {
          $('.company-data-for-private-customer').show();
        } else {
          $('.company-data-for-private-customer').hide();
          $('#vatField').attr("data-novalidate", "true");
        }
      });

      $('#show-company-data-true').on('click', function () {
        $('.company-data-for-private-customer').show();
        $('.js-phone-tooltip').removeClass('is-b2c');
        toggleShippingAddressLabels("B2B");
        reInitFormValidation();
      });

      $('#show-company-data-false').on('click', function () {
        $('.company-data-for-private-customer').hide();
        $('#vatField').attr("data-novalidate", "true");
        $('.js-phone-tooltip').addClass('is-b2c');
        toggleShippingAddressLabels("B2C");
        reInitFormValidation();
      });

      $('#createAccountModel').on("submit", function () {
        var browserDetect = new BrowserDetect();
        browserDetect.init();
        if (browserDetect.browser === "Explorer") {
          //fixes salutation radio group not getting populated after validation error
          var salutationRadios = $(".salutation-select input");
          for (var i = 0; i < salutationRadios.length; i++) {
            var radio = salutationRadios[i];
            if (radio.checked === true) {
              radio.click();
              break;
            }
          }

          //fixes customer select radio group not getting populated after validation error
          var custSelecRadios = $(".customer-type-select input");
          for (var j = 0; j < custSelecRadios.length; j++) {
            var radio2 = custSelecRadios[j];
            if (radio2.checked === true) {
              radio2.click();
              break;
            }
          }
        }


        var $accountData = $("#account-data");
        if ($accountData.length > 0 && !$accountData.hasClass('show')) {
          $(".js-register-password-field").val("");
          $(".js-password-verify").val("");
        }
      });
    }
  }
};
