/**
 *  Ist dafür zuständig die Warenkörbe im Header (Wunschzettel und Warenkorb) zu verwalten
 */
import $ from "jquery"
import {dataIsNotAjaxResult} from 'utils/ajax_handler_helper';

function CartUpdater(headerSelector, popoverSelector, floatingHeaderPopoverSelector) {

  var loadFlyout = function () {
  }; // wird bei Bedarf überschrieben
  var loadFlyout2 = function () {
  }; // wird bei Bedarf überschrieben
  var $headerBasketButton = null; // wird on DomReady gesetzt
  var $cart = null; // wird on DomReady gesetzt
  var $floatingHeaderCart = null; // wird on DomReady gesetzt

  $(document).ready(function () {
    $headerBasketButton = $(headerSelector);
    $cart = $(popoverSelector);
    $floatingHeaderCart = $(floatingHeaderPopoverSelector);
    var flyoutUrl = $cart.attr("data-flyout-ajax-url");
    if (typeof flyoutUrl !== "undefined") {
      loadFlyout = createLoadFlyoutFunction($cart, flyoutUrl);
    }
    loadFlyout();
    var flyoutUrl2 = $headerBasketButton.attr("data-flyout-ajax-url");
    if (typeof flyoutUrl2 !== "undefined") {
      loadFlyout2 = createLoadFlyoutFunction($headerBasketButton, flyoutUrl2);
    }
    loadFlyout2();
  });

  function createLoadFlyoutFunction($cart, flyoutUrl) {
    var findContainer = function () {
      return $cart.find(".js-basket-flyout");
    };
    return function () {
      $.ajax({
        cache: false,
        url: flyoutUrl,
        data: {},
        dataType: "html"
      }).done(function (data) {
        if (dataIsNotAjaxResult(data)) {
          // Kommt bspw. die Fehlerseite zurück und kein richtiges AJAX Ergebnis
          // so löschen wir lieber den Warenkorb Flyout. Er zeigt dann ja kein
          // gültiges Ergebnis mehr an
          findContainer().html("");
        } else {
          findContainer().html(data);
        }
      }).fail(function () {
        // Kommt bspw. die Fehlerseite zurück und kein richtiges AJAX Ergebnis
        // so löschen wir lieber den Warenkorb Flyout. Er zeigt dann ja kein
        // gültiges Ergebnis mehr an
        findContainer().html("");
      });
    };
  }

  function installShowBlackBackgroundWhenMouseOnBasketNaviHandler() {
    $('.menu-shop-cart.marked').on("mouseenter", function () {
      $(this).find('.basket-navi-teaser').css('height', $(this).find('.cart-flyout').css('height'))
    });

    $('.js-basket-count.header-nav__service-item--full-basket').mouseenter(function () {
      $(this).find('.basket-navi-teaser').css('height', $(this).find('.cart-flyout').css('height'))
    });
  }

  function markCart(count) {
    if ($cart !== null) {
      if (count > 0) {
        $cart.addClass("marked");
        $floatingHeaderCart.addClass("floating-header-cart-not-empty");
        if ($headerBasketButton) {
          $headerBasketButton.addClass("header-nav__service-item--full-basket");
        }
      } else {
        $cart.removeClass("marked");
        $floatingHeaderCart.removeClass("floating-header-cart-not-empty");
        if ($headerBasketButton) {
          $headerBasketButton.removeClass("header-nav__service-item--full-basket");
        }
      }
    }
  }

  function updateCount(count) {
    if ($cart !== null) {
      var $count = $cart.find(".js-service-count");
      $count.html(count);
      var $floatingHeaderCount = $floatingHeaderCart.find(".js-service-count");
      $floatingHeaderCount.html(count);
      if ($headerBasketButton) {
        $headerBasketButton.find(".js-service-count").html(count);
      }
    }
  }

  function input(data, count) {
    markCart(count);
    updateCount(count);
    loadFlyout();
    loadFlyout2();
  }

  return {
    input: input,
    installShowBlackBackgroundWhenMouseOnBasketNaviHandler: installShowBlackBackgroundWhenMouseOnBasketNaviHandler
  };
}


var shopCartHandler = new CartUpdater(".js-basket-count", ".trigger-onmouseover-blackout", ".js-cart-symbol");
shopCartHandler.installShowBlackBackgroundWhenMouseOnBasketNaviHandler();

var wishlistHandler = new CartUpdater(undefined, "#header-wishlist-element", ".js-wishlist-symbol");

/**
 * Das Wunschzettel Icon bekommt eine Sonderbehandlung. Ist der Wunschzettel leer, so soll es ausgegraut und mit einem speziellen Title dargestellt werden.
 * Ansonsten verlinkt es auf den Warenkorb. Diese Sonderbehandlung geschieht über folgende Javascript Funktion.
 */
$(document).ready(function () {
  var $wishlistElem = $("#header-wishlist-element");
  var $link = $wishlistElem.find("a");
  var positionsOnPageLoad = $link.attr('data-positions-on-page-load');
  var emptyTitle = $link.attr('data-empty-title');
  var href = $link.attr('data-href');

  var wishlistEmptyHandler = function (count) {
    if (count > 0) {
      // klickbares wishlist icon
      $link.attr('title', '');
      $link.attr('href', href);
    } else {
      // Icon soll nicht mehr klickbar, ausgegraut und mit speziellen Title dargestellt werden
      $link.attr('title', emptyTitle);
      $link.attr('href', "");
    }
  };

  // Methode wishlistEmptyHandler immer bei Aufruf der input Method automatisch mit aufrufen
  var oldInputFunction = wishlistHandler.input;
  wishlistHandler.input = function (data, count) {
    oldInputFunction.call(wishlistHandler, data, count);
    wishlistEmptyHandler(count);
  };

  // input Methode triggern damit das Wishlist Symbol entsprechend der Anzahl der Artikel im Warenkorb markiert wird.
  wishlistHandler.input(null, positionsOnPageLoad);
});

export default function HeaderCartsHandler() {
  return {
    shopCartHandler: shopCartHandler,
    wishlistHandler: wishlistHandler
  };
}
