export default function BrowserDetect() {
}

var fallback = {
  string: "Unknown", versionSubString: "Unknown", subString: "Unknown", identity: "Unknown"
}

var dataBrowser = [
  {string: navigator.userAgent, versionSubString: "Edge", subString: "Edge", identity: "Edge"},
  {string: navigator.userAgent, versionSubString: "Chrome", subString: "Chrome", identity: "Chrome"},
  {string: navigator.userAgent, versionSubString: "MSIE", subString: "MSIE", identity: "Explorer"},
  {string: navigator.userAgent, versionSubString: "Trident", subString: "Trident", identity: "Explorer"},
  {string: navigator.userAgent, versionSubString: "Firefox", subString: "Firefox", identity: "Firefox"},
  {string: navigator.userAgent, versionSubString: "Version", subString: "Safari", identity: "Safari"},
  {string: navigator.userAgent, versionSubString: "Opera", subString: "Opera", identity: "Opera"}
];

var dataOS = [
  {string: navigator.userAgent, subString: "Android", identity: "Android", mobile: true},
  {string: navigator.userAgent, subString: "webOS", identity: "webOS", mobile: true},
  {string: navigator.userAgent, subString: "iPhone", identity: "iOS", mobile: true},
  {string: navigator.userAgent, subString: "iPad", identity: "iOS", mobile: true},
  {string: navigator.userAgent, subString: "iPod", identity: "iOS", mobile: true},
  {string: navigator.userAgent, subString: "BlackBerry", identity: "BlackBerry", mobile: true},
  {string: navigator.userAgent, subString: "IEMobile", identity: "WindowsPhone", mobile: true},
  {string: navigator.userAgent, subString: "Windows Phone", identity: "WindowsPhone", mobile: true},
  {string: navigator.userAgent, subString: "Opera Mini", identity: "OperaMini", mobile: true},
  {string: navigator.userAgent, subString: "Win", identity: "Windows", mobile: false},
  {string: navigator.userAgent, subString: "Mac", identity: "MacOS", mobile: false},
  {string: navigator.userAgent, subString: "X11", identity: "UNIX", mobile: false},
  {string: navigator.userAgent, subString: "Linux", identity: "Linux", mobile: false}
];

BrowserDetect.prototype.init = function () {
  this.browser = this.searchString(dataBrowser).identity || "Other";
  var version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "Unknown";
  this.version = version;
  this.majorVersion = this.getMajorVersion(version);
  var osData = this.searchString(dataOS);
  this.os = osData.identity || "Other";
  this.mobile = osData.mobile || false;
};

BrowserDetect.prototype.searchString = function (data) {
  for (var i = 0; i < data.length; i++) {
    var dataString = data[i].string;

    if (dataString.indexOf(data[i].subString) !== -1) {
      this.versionSearchString = data[i].versionSubString;
      return data[i];
    }
  }
  return fallback;
};

BrowserDetect.prototype.searchVersion = function (dataString) {
  var index = dataString.indexOf(this.versionSearchString);
  if (index === -1) {
    return;
  }

  var rv = dataString.indexOf("rv:");
  if (this.versionSearchString === "Trident" && rv !== -1) {
    return parseFloat(dataString.substring(rv + 3));
  } else {
    return parseFloat(dataString.substring(index + this.versionSearchString.length + 1));
  }
};

BrowserDetect.prototype.getMajorVersion = function (version) {
  var indexOfDot = ("" + version).indexOf(".");
  if (indexOfDot !== -1) {
    return ("" + version).substring(0, indexOfDot);
  }
  return version;
};
