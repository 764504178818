/**
 * jQuery Plugin for swipejs, adding lazy load to images having the data-src attribute
 **/
import $ from "jquery";

export default function Lazy($el) {
  this.slider = $el.data("Swipe");
  this.$el = $el;
  this.init();
  this.$el.bind("swipe.kill", $.proxy(this.kill, this));
}

Lazy.prototype = {
  init: function () {
    if (!this.slider) return;

    this.numSlides = this.slider.getNumSlides();
    this.pos = this.slider.getPos();
    this.indicatorId = this.slider.indicatorId;

    this.originalCallback = this.slider.options.callback;
    this.slider.options.callback = $.proxy(this.callback, this);

  },
  kill: function () {
    this.$el.off("swipe.kill");
  },
  callback: function (pos, element, containerId) {
    // das Image sofort laden, damit es zu keiner optischen Verzögerung kommt
    this.loadImg(pos);

    if (this.originalCallback) {
      this.originalCallback.call(null, pos, element, containerId);
    }
    if (this.numSlides === 2) {
      this.loadImageAtPos(pos);
      this.loadImageAtPos(pos + 2);
    } else if (pos < this.numSlides) {
      this.loadImageAtPos(pos + 1);
    }
  },
  loadImg: function (pos) {
    var imgDiv = $(".swipe-wrap > div", this.$el).eq(pos);
    imgDiv.find("img[data-src]").each(function () {
      var image = this;
      $(image).attr("src", $(image).attr("data-src"));
      image.onload = function () {
        if (this.height && this.width) {
          $(image).parent().addClass("lazyload-loaded");
        }
      }
    });
  },

  loadImageAtPos: function (pos) {
    this.loadImg(pos);
  }
}

$.fn.lazy = function () {
  var matched = $(this);

  matched.each(function () {
    new Lazy($(this))
  });
  return matched;
}

