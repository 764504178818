import $ from 'jquery';

const playerId = 'youtube-player';
const containerClass = 'video-container';
const showPopup = 'show-popup';
const compensateScrollbar = 'compensate-scrollbar';

const DATA_MEDIA_URL = 'data-media-url';
const DATA_VIDEO_ID = 'data-video-id';
const DATA_MUTE = 'data-mute';
const DATA_AUTOPLAY = 'data-autoplay';

export default class YoutubePlayer {
  constructor() {
    this.apiUrl = window.shopConfig.youtubeFrameApi;
    $('.video-play-button').off('click').on('click', this.handlePlayClick.bind(this));
  }

  loadIframeApi() {
    let tag = document.createElement('script');
    tag.src = this.apiUrl;
    let firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
  }

  createPlayerHtml() {
    if (!document.getElementById(containerClass)) {
      let videoContainer = document.createElement('div');
      videoContainer.classList.add(containerClass);
      videoContainer.id = containerClass;

      let closeBtn = document.createElement('span');
      closeBtn.classList.add('close-btn');
      videoContainer.append(closeBtn);

      let playerDiv = document.createElement('div');
      playerDiv.id = playerId;
      videoContainer.appendChild(playerDiv);

      document.body.append(videoContainer);
    }
  }

  getYoutubeObject() {

    return new Promise((resolve) => {
      this.createPlayerHtml();

      if (window.YT) {
        return resolve(window.YT);
      }

      window.onYouTubeIframeAPIReady = () => {
        resolve(window.YT);
      };

      this.loadIframeApi();

    });
  }

  initPlayer() {
    return new Promise((resolve) => {
      this.togglePlayerPopup();
      if (window.player) return resolve();
      $('.video-container').on('click', this.handleContainerClick.bind(this));

      window.player = new YT.Player(playerId, {
        playerVars: {rel: 0},
        events: {
          'onReady': () => {
            resolve();
          }
        }
      });
    });
  }

  togglePlayerPopup() {
    document.getElementById(containerClass).classList.toggle(showPopup);
    document.body.classList.toggle(compensateScrollbar);
  }

  handleContainerClick(e) {
    e.preventDefault();
    e.stopPropagation();
    this.togglePlayerPopup();
    window.player.pauseVideo();
  }

  handle(videoId, mediaUrl, autoplay, mute) {
    let player = window.player;
    mute === 'true' && player.mute();
    if (player.getVideoData()['video_id'] === videoId) {
      player.playVideo();
    } else if (autoplay === 'true') {
      player.loadVideoByUrl(mediaUrl);
    } else {
      player.cueVideoByUrl(mediaUrl);
    }
  }

  handlePlayClick(e) {
    e.preventDefault();
    this.getYoutubeObject()
        .then(() => this.initPlayer())
        .then(() => {
          let mediaUrl = e.currentTarget.getAttribute(DATA_MEDIA_URL);
          let videoId = e.currentTarget.getAttribute(DATA_VIDEO_ID);
          let isAutoplay = e.currentTarget.getAttribute(DATA_AUTOPLAY);
          let isMuted = e.currentTarget.getAttribute(DATA_MUTE);
          this.handle(videoId, mediaUrl, isAutoplay, isMuted);
        })
  }

  playVideo(attributes) {
    this.getYoutubeObject()
        .then(() => this.initPlayer())
        .then(() => {
          let mediaUrl = attributes[DATA_MEDIA_URL];
          let videoId = attributes[DATA_VIDEO_ID];
          this.handle(videoId, mediaUrl, "true", "true");
        });
  }
}

